import React from 'react';
import {
  TextField,
  ReferenceField,
  SelectField,
  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput,
  FunctionField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import {StatusField} from '../../components/poc/StatusField';

import {deletePermission} from "./permissions";
import {AVAILABLE_BASIC_STATUSES, STATUS_ACTIVE} from '../../app/etc/consts';
import {SOURCES, AUTHORIZATION_METHODS, AVAILABLE_METHODS} from './consts';
import session from '../../app/session';
import config from "../../app/etc/config";
import {toOptions} from '../../lib/source';
import {formatClientName} from "../../components/poc/Grid/Fields";

const formatUri = (params) => {
  if (!params.uri || typeof params.uri !== 'string') {
    return '';
  }

  return params.uri.split('\n').map(s => <div>{s}</div>);
};

const EndpointFilter  = props => {
  return (
    <ListFilter {...props}>
      <TextInput source="id:in" label="ID"/>

      <SelectInput choices={AVAILABLE_BASIC_STATUSES} source="status"/>

      {session.hasAdminPermission() &&
        <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
          <SelectInput optionText={formatClientName}/>
        </ReferenceInput>
      }

      <TextInput source="name:like" label="Name"/>
      <TextInput source="uri:like" label="URI" />

      <SelectInput choices={toOptions(SOURCES, 'app.endpoint.labels.sources.')} source="source"/>

      <DateInput source="created_at"/>
      <DateInput source="updated_at"/>
    </ListFilter>
  )
};

export const EndpointGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<EndpointFilter/>}
      resource="endpoint"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <TextField source="name"/>
      <StatusField source="status"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <SelectField choices={toOptions(SOURCES, 'app.endpoint.labels.sources.')} source="source"/>
      <SelectField choices={toOptions(AUTHORIZATION_METHODS, 'app.endpoint.labels.authorization.methods.')} source="authorization.method"  label="Auth Method"  sortable={false}/>

      <FunctionField render={formatUri} label="app.endpoint.labels.uri" sortable={false}/>
      <SelectField choices={toOptions(AVAILABLE_METHODS, 'app.endpoint.labels.methods.')} source="method" sortable={false}/>

      <Actions edit={true} delete={deletePermission}/>
    </ListWithLayout>
  )
};
