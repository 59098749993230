import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import session from "../../../app/session";
import {ViewStyles} from "../../../theme/skin/poc/styles";

const renderUri = ({uri, method, response_time}) => {
  const classes = ViewStyles();

  let res = [];
  if (!!method) {
    res.push(<span>{method.toUpperCase()}</span>);
  }
  if (!!uri) {
    res.push(<textarea className={classes.cellExchangeBody} readOnly={true}>{uri}</textarea>);
  }
  if (!!response_time) {
    res.push(<span>Time: {response_time}</span>);
  }

  return (<>{res.map(s => <div>{s}</div>)}</>);
}

const renderRequest = ({request_body, request_headers}) => {
  const classes = ViewStyles();

  let res = [];
  res.push(<span>Body:</span>);
  res.push(<textarea className={classes.cellExchangeBody} readOnly={true}>{request_body}</textarea>);

  if (!!request_headers) {
    res.push(<span>Headers:</span>);
    res.push(<textarea className={classes.cellExchangeBody} readOnly={true}>{request_headers}</textarea>);
  }

  return (<>{res.map(s => <div>{s}</div>)}</>);
};

const renderResponse= ({response_status, response_body, exception_message, exception_trace}) => {
  const classes = ViewStyles();

  let res = [];
  if (!!response_status) {
    res.push(<span>Status: {response_status.toUpperCase()}</span>);
  }
  if (!!response_body) {
    res.push(<span>Body:</span>);
    res.push(<textarea className={classes.cellExchangeBody} readOnly={true}>{response_body}</textarea>);
  }

  if (!!exception_message) {
    res.push(<span>Exception Message:</span>);
    res.push(<textarea className={classes.cellExchangeBody} readOnly={true}>{exception_message}</textarea>);
  }

  if (!!exception_trace && session.hasAdminPermission()) {
    res.push(<span>Exception Trace:</span>);
    res.push(<textarea className={classes.cellExchangeBody} readOnly={true}>{exception_trace}</textarea>);
  }

  return (<>{res.map(s => <div>{s}</div>)}</>);
};

/**
 uri: joi.string().optional(),
 method: joi.string().optional(),
 response_time: joi.string().optional(),

 request_body: joi.string().optional(),
 request_headers: joi.string().optional(),

 response_body: joi.string().optional(),
 response_status: joi.string().optional(),

 exception_message: joi.string().optional(),
 exception_trace: joi.string().optional(),
 * */

export default function Exchanges({record}) {
  const classes = ViewStyles();
  const {exchanges} = record;
  if (!exchanges || !Array.isArray(exchanges) || !exchanges.length) {
    return null;
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.dataTable}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">URI</TableCell>
            <TableCell align="left" width="40%">Request</TableCell>
            <TableCell align="left" width="40%">Response</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exchanges.map((row) => (
            <TableRow>
              <TableCell align="left" style={{ verticalAlign: 'top' }}>{renderUri(row)}</TableCell>
              <TableCell align="left" style={{ verticalAlign: 'top' }}>{renderRequest(row)}</TableCell>
              <TableCell align="left" style={{ verticalAlign: 'top' }}>{renderResponse(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
