import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import { useTranslate } from 'ra-core';

//Core
import session from './app/session'

//Providers
import authProvider from './provider/auth';
import { pocDataProvider } from './provider/data';
import i18nProvider from './provider/i18n'

//Layouts
import Login from './components/poc/Login';

//View - Client
import { ClientGrid } from './view/client/grid';
import { ClientCreate, ClientEdit } from './view/client/view';

//View - User
import { UserGrid } from './view/user/grid';
import { UserCreate, UserEdit } from './view/user/view';

//View - Token
import { TokenGrid } from './view/token/grid';
import { TokenCreate, TokenEdit } from './view/token/view';

//View - Key
import { KeyGrid } from './view/key/grid';
import { KeyCreate, KeyView} from './view/key/view';

//View - Profile
import { ProfileGrid } from './view/profile/grid';
import { ProfileEdit, ProfileCreate } from './view/profile/view';

//View - Connection
import { ConnectionGrid } from './view/connection/grid';
import { ConnectionEdit, ConnectionCreate } from './view/connection/view';

//View - Buyer
import { BuyerGrid } from './view/buyer/grid';
import { BuyerEdit, BuyerCreate } from './view/buyer/view';

//View - Endpoint
import { EndpointGrid } from './view/endpoint/grid';
import { EndpointEdit, EndpointCreate } from './view/endpoint/view';

//View - Transaction
import { TransactionGrid } from './view/transaction/grid';
import { TransactionView } from './view/transaction/view';

//View - License
import { LicenseGrid } from './view/license/grid';
import { LicenseCreate } from './view/license/view';

//View - WhitelistReferer
import { WhitelistRefererGrid } from './view/whitelist-referer/grid';
import { WhitelistRefererCreate } from './view/whitelist-referer/view';

//View - Document
import { DocumentGrid } from './view/document/grid';
import { DocumentBrief } from './view/document/brief';

//View - ImportExportBatch
import { ImportExportBatchGrid } from './view/import-export/grid';

//View - ReportGrid
import {ReportClientGrid} from './view/report/grid/clients';

//View - Table
import { TableGrid } from './view/table/grid';
import { TableEdit, TableCreate } from './view/table/view';

//View - Template
import { TemplateGrid } from './view/template/grid';
import { TemplateEdit, TemplateCreate } from './view/template/view';

//View - Credential
import {CredentialCreate, CredentialEdit} from "./view/credential/view";
import {CredentialGrid} from "./view/credential/grid";

//Theme
import { Main as MainLayout } from './layouts';
import { ThemeProvider } from '@mui/material/styles';
import './assets/scss/index.scss';
import palette from './theme/skin/poc/palette';
import typography from './theme/skin/poc/typography';
import overrides from './theme/skin/poc/overrides';
import { createTheme } from '@mui/material/styles';



//-------------------------------------------------------------------------------------//
//import Register from './components/poc/Register';
import Version from './components/poc/Version';
import ForgotPassword from './components/poc/ForgotPassword';
import ForgotPasswordConfirm from './components/poc/ForgotPassword/components/ForgotPasswordConfirm';
import UserProfile from './view/user/profile';
import UserSwitcher from './view/user/switcher';
import CompanyProfile from './view/client/profile';
import Dashboard from './view/report/Dashboard';
//-------------------------------------------------------------------------------------//

const theme = createTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

const App = () => {
  const translate = useTranslate();
  return (
    <ThemeProvider theme={theme}>
      <Admin
        authProvider={authProvider}
        dataProvider={pocDataProvider}
        i18nProvider={i18nProvider}
        layout={MainLayout}
        loginPage={Login}
        dashboard={Dashboard}
        theme={theme}
        title="Punchout Cloud: Client Console"
        disableTelemetry
      >

        {() => [
          session.hasClientUserPermission()
            ? <Resource name="dashboard"/>
            : null,

          session.hasAdminPermission()
            ? <Resource
              create={ClientCreate}
              edit={ClientEdit}
              list={ClientGrid}
              name="client"
              options={{label: translate('app.client.label')}}
            />
            : null,

          session.hasAdminPermission()
            ? <Resource
              create={WhitelistRefererCreate}
              list={WhitelistRefererGrid}
              edit={false}
              name="referer"
              options={{label: translate('app.referer.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={false}
              list={ImportExportBatchGrid}
              edit={false}
              name="importexport_batch"
              options={{label: translate('app.importexport_batch.label')}}
            />
            : null,


          session.hasClientPermission()
            ? <Resource
              create={UserCreate}
              edit={UserEdit}
              list={UserGrid}
              name="user"
              options={{label: translate('app.user.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={TokenCreate}
              edit={TokenEdit}
              list={TokenGrid}
              name="token"
              options={{label: translate('app.token.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={KeyCreate}
              list={KeyGrid}
              show={KeyView}
              name="key"
              options={{label: translate('app.key.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={ProfileCreate}
              edit={ProfileEdit}
              list={ProfileGrid}
              name="profile"
              options={{label: translate('app.profile.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={TemplateCreate}
              edit={TemplateEdit}
              list={TemplateGrid}
              name="template"
              options={{label: translate('app.template.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              list={DocumentGrid}
              name="document"
              options={{label: translate('app.document.label')}}
              show={DocumentBrief}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={BuyerCreate}
              edit={BuyerEdit}
              list={BuyerGrid}
              name="buyer"
              options={{label: translate('app.buyer.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={CredentialCreate}
              edit={CredentialEdit}
              list={CredentialGrid}
              name="credential"
              options={{label: translate('app.credential.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={ConnectionCreate}
              edit={ConnectionEdit}
              list={ConnectionGrid}
              name="connection"
              options={{label: translate('app.connection.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={EndpointCreate}
              edit={EndpointEdit}
              list={EndpointGrid}
              name="endpoint"
              options={{label: translate('app.endpoint.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              list={TransactionGrid}
              name="transaction"
              options={{label: translate('app.transaction.label')}}
              show={TransactionView}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={LicenseCreate}
              list={LicenseGrid}
              name="license"
              options={{label: translate('app.license.label')}}
            />
            : null,

          session.hasAdminPermission()
            ? <Resource
              list={ReportClientGrid}
              name="report_clients"
              options={{label: translate('app.report_clients.label')}}
            />
            : null,

          session.hasClientUserPermission()
            ? <Resource
              create={TableCreate}
              edit={TableEdit}
              list={TableGrid}
              name="table"
              options={{label: translate('app.table.label')}}
            />
            : null,

          <Resource name="login"/>,
          <Resource name="version"/>,
          <Resource name="register"/>,
          <Resource name="forgotpassword"/>,
          <Resource name="forgotpasswordconfirm"/>
        ]}

        <CustomRoutes noLayout>
          <Route path="/forgotpassword" element={<ForgotPassword />} />,
          <Route path="/forgotpasswordconfirm" element={<ForgotPasswordConfirm />} />,
          <Route path="/version" element={<Version />} />
        </CustomRoutes>

        <CustomRoutes>
          {/*<Route path="/dashboard" element={Dashboard}/>,*/}
          <Route path="/users" element={<UserGrid />} />,
          <Route path="/clients" element={<ClientGrid />} />,
          <Route path="/endpoints" element={<EndpointGrid />} />,
          <Route path="/documents" element={<DocumentGrid />} />,
          <Route path="/referers" element={<WhitelistRefererGrid />} />,
          <Route path="/connections" element={<ConnectionGrid />} />,
          <Route path="/buyers" element={<BuyerGrid />} />,
          <Route path="/credentials" element={<CredentialGrid />} />,
          <Route path="/profiles" element={<ProfileGrid />} />,
          <Route path="/transactions" element={<TransactionGrid />} />,
          <Route path="/tokens" element={<TokenGrid />} />,
          <Route path="/keys" element={<KeyGrid />} />,
          <Route path="/licenses" element={<LicenseGrid />} />,
          <Route path="/tables" element={<TableGrid />} />,
          <Route path="/templates" element={<TemplateGrid />} />,
          <Route path="/import-export" element={<ImportExportBatchGrid />} />,
          <Route path="/user/profile" element={<UserProfile />} />,
          <Route path="/user/switch" element={<UserSwitcher />} />,
          <Route path="/company/profile" element={<CompanyProfile />} />,
          <Route path="/report/clients" element={<ReportClientGrid />} />,
        </CustomRoutes>

      </Admin>
    </ThemeProvider>
  )
}

export default App;
