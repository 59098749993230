import React from 'react';
import {
  TextField,
  ReferenceField,
  SelectField,
  BooleanField,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  BooleanInput, FunctionField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import {StatusField} from '../../components/poc/StatusField';

import {deletePermission, editPermission, viewPermission} from './permissions';
import {AVAILABLE_PROFILE_FORMATS, AVAILABLE_PROFILE_TYPES, AVAILABLE_PROFILE_CHANNELS, AVAILABLE_VERSIONS} from './consts';
import {AVAILABLE_BASIC_STATUSES} from '../../app/etc/consts';

import session from '../../app/session';
import config from "../../app/etc/config";
import {toOptions} from '../../lib/source';
import {formatClientName} from "../../components/poc/Grid/Fields";

const ProfileFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id:in" label="ID"/>
    <TextInput source="name:like" label="Name"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <SelectInput source="type"    choices={toOptions(AVAILABLE_PROFILE_TYPES, 'app.general.labels.')} />
    <SelectInput source="version" choices={toOptions(AVAILABLE_VERSIONS)} />
    <SelectInput source="format"  choices={toOptions(AVAILABLE_PROFILE_FORMATS)} />
    <SelectInput source="channel" choices={toOptions(AVAILABLE_PROFILE_CHANNELS, 'app.general.labels.')} />
    <SelectInput source="status"  choices={AVAILABLE_BASIC_STATUSES} />
    <BooleanInput source="is_shared"/>
    <DateInput source="created_at"/>
    <DateInput source="updated_at"/>
  </ListFilter>
);

export const ProfileGrid = (props) => {
  return (
    <ListWithLayout
      {...props}
      filters={<ProfileFilter/>}
      resource="profile"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <TextField source="name"/>
      <StatusField source="status"/>
      <TextField source="format"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <SelectField source="type"    choices={toOptions(AVAILABLE_PROFILE_TYPES, 'app.general.labels.')} />
      <SelectField source="channel" choices={toOptions(AVAILABLE_PROFILE_CHANNELS, 'app.general.labels.')} />
      <SelectField source="version" choices={toOptions(AVAILABLE_VERSIONS)} />

      <BooleanField source="is_shared"/>

      <Actions view={viewPermission} edit={editPermission} delete={deletePermission}/>
    </ListWithLayout>
  )
};
