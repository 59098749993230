export const CLIENT_PRIVATE_API = 'CLIENT_PRIVATE_API'; //Client Request to Cloud
export const CLIENT_PUBLIC_API  = 'CLIENT_PUBLIC_API';   //Client Request to Cloud
export const INTEGRATION_API = 'INTEGRATION_API';
export const OUTBOUND_API    = 'OUTBOUND_API';

export const AVAILABLE_CLIENT_TOKEN_TYPES = [
  {id: CLIENT_PRIVATE_API, name: CLIENT_PRIVATE_API},
  {id: CLIENT_PUBLIC_API,  name: CLIENT_PUBLIC_API},
  {id: INTEGRATION_API, name: INTEGRATION_API},
  {id: OUTBOUND_API,    name: OUTBOUND_API},
];

export const AVAILABLE_CLIENT_TOKEN_TYPES_FOR_CLIENT = [
  {id: CLIENT_PRIVATE_API, name: CLIENT_PRIVATE_API},
  {id: CLIENT_PUBLIC_API,  name: CLIENT_PUBLIC_API},
  {id: OUTBOUND_API, name: OUTBOUND_API},
];
