import React from 'react';
import hookIntoProps from 'hook-into-props'
import {useDataProvider, useTranslate, useNotify} from 'ra-core';
import { Authenticated } from 'react-admin';

import DocumentTypesSummaryGraphContainer from "./DocsSummary/DocsSummaryGraphContainer";
import session from "../../app/session";
import {DashboardStyles} from "../../theme/skin/poc/styles";
import clsx from "clsx";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    //
    this.css = props.styles;
    this.session = props.session;
  }

  render() {
    return (
      <Authenticated>
        <div className={clsx(this.css.root, this.css.dashboard)}>
          {this.renderCaption()}

          <DocumentTypesSummaryGraphContainer
            filterCaption="Filter Reports By: "
            showFilters={true}
            graphAmountsCaption="Amounts Summary"
            graphAmountsHint="Grouped summary of amounts per the selected period"
            graphNumbersCaption="Transactions Summary"
            graphNumbersHint="Grouped number of transactions per the selected period"
          />
        </div>
      </Authenticated>
    );
  }

  renderCaption() {
    let caption = this.session.getData().clientName + " Statistic";
    if (this.session.hasAdminPermission()) {
      caption = 'All Clients Statistic';
    }
    return  (<div className={this.css.dashboardCaption}><h1>{caption}</h1></div>);
  }
};


const useHooks = () => ({
  translate: useTranslate(),
  styles: DashboardStyles(),
  dataProvider: useDataProvider(),
  notify: useNotify(),
  session: session,
});

export default hookIntoProps(useHooks)(Dashboard)
