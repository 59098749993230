import {MONTH_NAMES_SHORT} from "../view/report/consts";

export function formatToYYYYMMDD(date) {
    let d = new Date(date);
    let day = d.getDate();//returns from 1 to 31;
    let month = d.getMonth() + 1;//returns from 0 to 11;

    if (day < 10) {
      day = `0${day}`
    }

    if (month < 10) {
      month = `0${month}`
    }

    return `${d.getFullYear()}-${month}-${day}`;
}

export function formatTommyyyy(date) {
  let d = new Date(date);
  let month = d.getMonth() + 1;//returns from 0 to 11;

  return `${MONTH_NAMES_SHORT[month]} ${d.getFullYear()}`;
}
