import React from "react";
import {ArrayInput, required, SimpleFormIterator, TextInput} from "react-admin";

export default ({ source, ex }) => {
  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.' + source)}</div>

      <ArrayInput source={source} label="" >
        <SimpleFormIterator>
          <TextInput source="code"  label="app.general.labels.code" validate={[required()]}/>
          <TextInput source="value" label="app.general.labels.value" validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>

    </>
  );
};
