import React from 'react';
import { FunctionField } from 'react-admin';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { getFormDataValue } from '../Form/Fields';

const ERROR    = ['error', 'errored', 'inactive'];
const FAILED   = ['failed', 'failure', 'fail', 'rejected'];
const COMPLETE = ['success', 'complete', 'ok', 'active'];

export const StatusFieldRender = (record, source) => {
  const val = getFormDataValue(record, source);
  if (typeof val === 'undefined' || val === null) {
    return null;
  }

  let color = '#C0C0C0';
  let icon = null;
  if (FAILED.indexOf(val) !== -1) {
    color = '#ff3636';
    icon = <ErrorIcon />;
  } else if (ERROR.indexOf(val) !== -1) {
    color = '#fc9803';
    icon = <ErrorIcon />;
  } else if (COMPLETE.indexOf(val) !== -1) {
    color = '#5373bd';
    icon = <DoneIcon />;
  }

  return (
    <Chip
      size="small"
      label={val}
      icon={icon}
      color='secondary'
      style={{backgroundColor: color}}
    />
  )
}

export const StatusField = ({source}) => {
  return (<FunctionField render={record => StatusFieldRender(record, source)}/>)
};

StatusField.defaultProps = { addLabel: true };
