import React, {useState} from 'react';
import {
  TextInput,
  SelectInput,
  DateInput,
  TextField,
  EmailField,
  BooleanField,
  Button,
  Confirm,
  useRedirect,
  useDataProvider,
  useRecordContext
} from 'react-admin';

import SwitchIcon from '@mui/icons-material/Accessibility';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout'
import {StatusField} from '../../components/poc/StatusField';

import Actions from '../../components/poc/Grid/Row/Actions';
import {AVAILABLE_CLIENT_STATUSES, AVAILABLE_CLIENT_TYPES} from './consts';
import {deletePermission, switchPermission} from './permissions';
import config from '../../app/etc/config';
import session from '../../app/session';
import {useNotify, useTranslate} from 'ra-core';
import {STATUS_ACTIVE} from "../../app/etc/consts";

const ConfirmSwitchButton = (props) => {
  const {record} = props;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    doSwitch(record);
  };

  const doSwitch = (record) => {
    setLoading(true);

    dataProvider
      .create('switch', {data: {client_id: record.id}})
      .then(response => {
        setLoading(false);

        session.switch(response.data);

        notify(translate('app.user.labels.switch_successful', {client_name: session.getClientName()}));
        redirect('/');//dashboard
      })
      .catch(error => {
        setLoading(false);

        // failure side effects go here
        const msg = !!error.message ? error.message : 'An Error Happened';
        notify(`${msg}`, 'warning');
      });
  };

  return (
    <>
      <Button
        label="Switch"
        onClick={() => handleClick(record)}
        fullWidth={true}
      ><SwitchIcon/></Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Switch On Behalf Of"
        content={`Are you sure you want to Switch On Behalf Of the "` + record.name + `" Client`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ClientFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id:in" label="ID"/>
    <SelectInput
      choices={AVAILABLE_CLIENT_STATUSES}
      source="status"
    />
    <SelectInput
      choices={AVAILABLE_CLIENT_TYPES}
      source="type"
    />
    <TextInput source="code"/>
    <TextInput source="name:like" label="Name"/>
    <TextInput source="email"/>
    <DateInput source="created_at"/>
    <DateInput source="updated_at"/>
  </ListFilter>
);

export const ClientGrid = props => {
  let customOptions = () => {
    const record = useRecordContext();

    if (!switchPermission(record) || (record.status !== STATUS_ACTIVE)) {
      return null;
    }

    return (<ConfirmSwitchButton record={record} />);
  };

  return (
    <ListWithLayout
      {...props}
      createButton={true}
      filters={<ClientFilter/>}
      resource="client"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
    >
      <TextField    source="id" sortable={false} />
      <TextField    source="type"/>
      <StatusField  source="status"/>
      <TextField    source="code"/>
      <TextField    source="name"/>
      <EmailField   source="email"/>
      <BooleanField source="is_system" sortable={false} />

      <TextField source="platform.source" label="Platform" sortable={false} emptyText={config.platform.default}/>

      <Actions edit={true} delete={deletePermission} customOptionsAfter={customOptions}/>
    </ListWithLayout>
  )
}
