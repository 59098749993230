import React from "react";
import {BooleanField, ReferenceInput, SelectInput} from "react-admin";
import session from "../../../../../app/session";
import config from "../../../../../app/etc/config";

export default ({ formData }) => {
  const type = 'OUTBOUND_API';

  const filter = session.hasAdminPermission()
    ? {type: type, client_id: formData.client_id}
    : {type: type}
  ;

  return (
    <>
      <ReferenceInput
        filter={filter}
        label="Outbound Token"
        reference="token"
        source="authorization.outbound.token_id"
        sort={ {field: 'created_at', order: 'DESC'} }
        perPage={config.pagination.associated}
        allowEmpty
        required
      >
        <SelectInput optionText="code" />
      </ReferenceInput>
    </>
  );
};
