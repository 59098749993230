import React from 'react';
import Alert from '@mui/lab/Alert';
import {EmptyStyles} from '../../../theme/skin/poc/styles';

const Index = (props) => {
  const classes = EmptyStyles();
  const msg = props.message && props.message.message ? props.message.message : props.message;
  const severity = props.severity ? props.severity : 'error';
  return (
    <>
      <div className={classes.message}>
        <Alert severity={severity}>{msg}</Alert>
      </div>
    </>
  );
};

export default Index;
