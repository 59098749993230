import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_ACTIVE_LABEL,
  STATUS_INACTIVE_LABEL
} from './../../app/etc/consts';

export const CLIENT_ID_ALL = '000000000000000000000000';
export const CLIENT_MOCK_ALL = {id: CLIENT_ID_ALL, name: 'ALL'};

export const STATUS_PENDING_LABEL  = 'Pending';
export const STATUS_REJECTED_LABEL = 'Rejected';

export const STATUS_PENDING  = 'pending';
export const STATUS_REJECTED = 'rejected';

export const STATUS_ONBOARDING  = 'onboarding';
export const STATUS_ONBOARDING_LABEL = 'Onboarding';

export const STATUS_SUBSCRIBING = 'subscribing';
export const STATUS_SUBSCRIBING_LABEL = 'Subscribing';

export const TYPE_INTERNAL    = 'internal';
export const TYPE_INTEGRATION = 'integration';

export const TYPE_INTERNAL_LABEL = 'Internal';
export const TYPE_INTEGRATION_LABEL = 'Integration';

export const ALLOW_CHOOSER_MULTIPLE = 'multiple';
export const ALLOW_CHOOSER_NEVER    = 'never';
export const ALLOW_CHOOSER_ALWAYS   = 'always';

export const ALLOW_CHOOSER_MULTIPLE_LABEL = 'Multiple Connections Match';
export const ALLOW_CHOOSER_NEVER_LABEL    = 'Never';
export const ALLOW_CHOOSER_ALWAYS_LABEL   = 'Always';

export const AVAILABLE_CLIENT_STATUSES = [
  { id: STATUS_ACTIVE,   name: STATUS_ACTIVE_LABEL },
  { id: STATUS_INACTIVE, name: STATUS_INACTIVE_LABEL },
  { id: STATUS_PENDING,  name: STATUS_PENDING_LABEL },
  { id: STATUS_REJECTED, name: STATUS_REJECTED_LABEL },
  { id: STATUS_ONBOARDING,  name: STATUS_ONBOARDING_LABEL},
  { id: STATUS_SUBSCRIBING, name: STATUS_SUBSCRIBING_LABEL},
];

export const AVAILABLE_CLIENT_TYPES = [
  { id: TYPE_INTERNAL,    name: TYPE_INTERNAL_LABEL },
  { id: TYPE_INTEGRATION, name: TYPE_INTEGRATION_LABEL },
];

export const AVAILABLE_CHOOSER_OPTIONS = [
  {id: ALLOW_CHOOSER_MULTIPLE, name: ALLOW_CHOOSER_MULTIPLE_LABEL},
  {id: ALLOW_CHOOSER_NEVER,    name: ALLOW_CHOOSER_NEVER_LABEL},
  {id: ALLOW_CHOOSER_ALWAYS,   name: ALLOW_CHOOSER_ALWAYS_LABEL},
];

export const DATA_FORMAT_CXML = 'cXML';
export const DATA_FORMAT_OCI  = 'OCI';

export const PUNCHOUT_FORMATS = [
  {id: DATA_FORMAT_CXML, name: DATA_FORMAT_CXML},
  {id: DATA_FORMAT_OCI,  name: DATA_FORMAT_OCI}
];

export const CONTACT_TYPES = [
  {id: 'default',   name: 'Default'},
  {id: 'sales',     name: 'Sales'},
  {id: 'it',        name: 'IT'},
  {id: 'marketing', name: 'Marketing'},
];
