export function toOptions(map, prefix = '') {
  if (Array.isArray(map)) {
    let result = [];
    for (const id in map) {
      result.push({id: map[id], name: prefix + map[id]});
    }
    return result;
  } else if (typeof map === 'object') {
    return Object.keys(map).map(id => {
      return {id: map[id], name: prefix + map[id]};
    });
  } else {
    return [];
  }
};

export function hasFieldValue(data, field) {
  return (
    (typeof data !== 'undefined')
    && Object.prototype.hasOwnProperty.call(data, field)
    && (typeof data[field] !== 'undefined')
    && (data[field] !== false) //hot-fix after upgrade
  );
};
