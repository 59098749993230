export default {
  total: { fontWeight: 'bold' },

  itemCell: {verticalAlign: 'top'},
  itemField: {},
  ssfContainer: { minWidth: '15em', maxWidth: '40em' },
  ssfCell: {borderWidth: '1px'},
  ssfCellCaption: {borderWidth: '1px', width: '40%'},
  relationCell: {borderWidth: '0px', margin: 0, padding: 0, textAlign: 'left'},
};
