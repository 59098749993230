import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Notification, useDataProvider, FormDataConsumer, TopToolbar, useRedirect, Form} from 'react-admin';
import {useTranslate, useNotify} from 'ra-core';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import {ViewStyles} from '../../theme/skin/poc/styles';
import session from '../../app/session'
import {ClientField} from "../../components/poc/Form/Fields";

const UserSwitcher = (props) => {
  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = ViewStyles();

  const ex = {
    props:     props,
    classes:   classes,
    translate: translate,
  };

  const onSubmit = (data) => {
    setLoading(true);

    dataProvider
      .create('switch', {data: data})
      .then(response => {
        setLoading(false);

        session.switch(response.data);

        notify(translate('app.user.labels.switch_successful', {client_name: session.getClientName()}));
        redirect('/');//dashboard
      })
      .catch(error => {
        setLoading(false);

        // failure side effects go here
        const msg = !!error.message ? error.message : 'An Error Happened';
        notify(`${msg}`, 'warning');
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.client_id) {
      errors.firstname = ['The Client is required'];
    }
    return errors
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        defaultValues={{client_id: session.getClientId()}}
      >
        <Card>
          <CardContent>
            <TopToolbar className={classes.actions}>
              <div className="actions-title">{translate('app.user.labels.switcher_title')}</div>
            </TopToolbar>

            <FormDataConsumer>
              {({formData}) =>
                <ClientField clientId={formData.client_id} ex={ex} filter={{status: 'active', 'code:nin': 'system'}}/>
              }
            </FormDataConsumer>
          </CardContent>

          <CardActions>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              Switch
            </Button>
          </CardActions>
        </Card>

        <Notification/>
      </Form>
    </div>
  );
};

UserSwitcher.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default UserSwitcher;
