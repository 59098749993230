import palette from '../palette';

export default {
  root: {
    '& button': {
      color: palette.primary.dark
    },
    '& button:hover': {
      color: palette.primary.highlight,
      backgroundColor: palette.white
    }
  }

};
