//handle urls like: `http://cloud.pexlocal.com:3000/#/connection/create?{%22type%22:%22purchase_order%22,%22format%22:%22cXML%22}`
//window.location.search - does now work in this case
export function getFormDataFromUrl() {
  let parts = window.location.href.split('?');
  if (parts.length < 2) {
    return {};
  }

  try {
    return JSON.parse(decodeURI(parts[1]));
  } catch (e) {
    return {};
  }
};
