import React, {useState} from 'react';
import {Notification, useRedirect} from 'react-admin';
import {useTranslate, useLogin, useNotify} from 'ra-core';
import {Form, Field} from 'react-final-form'

import {Button, Card, CardActions, CircularProgress} from '@mui/material';
import {PublicStyles} from '../../../theme/skin/poc/styles';
import {Link} from 'react-router-dom';
import {TextField, PasswordField, PublicFormIcon} from "../Form/Fields";


const Index = () => {
  const [loading, setLoading] = useState(false);

  const redirect = useRedirect();
  const translate = useTranslate();
  const classes = PublicStyles();
  const notify = useNotify();
  const login = useLogin();

  const onSubmit =  (auth) => {
    setLoading(true);
    login(auth)
      .then((data) => {
        if (data && data.message) {
          throw new Error(data.message);
        }
      })
      .catch(
        (error) => {
          redirect('/login');
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
                ? 'ra.auth.sign_in_error'
                : error.message,
            'error'
          );
        }
      );
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form
          autoComplete="off"
          id="LoginForm"
          onSubmit={handleSubmit}
        >
          <div className={classes.main}>
            <Card className={classes.card}>

              <div className={classes.avatar}>
                <PublicFormIcon />
              </div>

              <div className={classes.form}>
                <div>
                  <h2>{translate('app.auth.login_title')}</h2>
                </div>

                <Field autoFocus component={TextField} disabled={loading} label={translate('app.auth.email')} name="email" style={{ marginTop: '15px'}} />
                <Field component={PasswordField} disabled={loading} label={translate('ra.auth.password')} name="password" type="password" style={{ marginTop: '15px'}} />
              </div>

              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  disabled={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
              {/*<CardActions className={classes.actions}>*/}
              {/*  /!*<Link*!/*/}
              {/*  /!*  className={classes.link}*!/*/}
              {/*  /!*  disabled={loading}*!/*/}
              {/*  /!*  to="register"*!/*/}
              {/*  /!*>{translate('app.auth.register')}</Link>*!/*/}
              {/*  <Link*/}
              {/*    className={classes.link}*/}
              {/*    disabled={loading}*/}
              {/*    to="forgotpassword"*/}
              {/*  >{translate('app.auth.forgot_password')}</Link>*/}
              {/*</CardActions>*/}
            </Card>
            <Notification/>
          </div>
        </form>
      )}
      validate={validate}
    />
  );
};

export default Index;
