/**
 * @todo: split this large file to smaller chunks
 */
/** @todo: use more smart logic to convert from `cart_settings.default_supplier_id` to `{cart_settings: {default_supplier_id: ...}}` */
import {fetchUtils} from 'react-admin';

import {stringify} from 'query-string';
import set from 'set-value';

import config from '../app/etc/config';
import session from '../app/session';

import {PROFILE_FIELDS} from '../view/profile/consts';
import {TYPE_ORDER_RECEIPT_REQUEST, TYPE_SETUP_REQUEST} from "../app/etc/consts";
import {hasFieldValue} from "../lib/source";

var __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
        t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};

export function getResourcePath(resource) {
  // console.log('[ getResourcePath, resource ]', resource);
  if (resource.indexOf('/') >= 0 && resource.indexOf('importexport/') === -1 && resource.indexOf('importexport_') === -1) {
    return resource;
  }

  if (config.api.access.indexOf(resource) >= 0) {
    return 'access/' + resource;
  } else if (config.api.punchout.indexOf(resource) >= 0) {
    return 'punchout/' + resource;
  } else if (config.api.mapping.indexOf(resource) >= 0) {
    return 'mapping/' + resource;
  } else if (config.api.report.indexOf(resource) >= 0) {
    return 'report/' + resource.replace('report_', '');
  } else if (resource === 'referer') {
    return 'security/whitelist/referer';
  } else if (resource.indexOf('importexport/') !== -1) {
    return resource.replace('importexport/', 'import-export/');
  } else if (resource.indexOf('importexport_') !== -1) {
    return resource.replace('importexport_', 'import-export/');
  } else {
    return resource;
  }
};

function hasSharedAccess(resource) {
  return (resource === 'profile' || resource === 'template');
};

/** Misc functions */
function toQuery(params) {
  if (params && params.ids) {
    const {ids} = {...params};
    params = {'id:in': ids.join(','), 'limit': config.pagination.grid}
  }
  return params;
};

function toBooleanField(data, field) {
  return hasFieldValue(data, field) ?  1 : 0;
};

function toArrayField(data, field) {
  return (hasFieldValue(data, field) && Array.isArray(data[field]))  ? data[field] : [];
};

/** Main functions */
const prepareParamsBefore = function(resource, params) {
  let obj = {};

  if (typeof params.data === "object") {
      for (let [key, val] of Object.entries(params.data)) {
          setValue(obj, key, val);
      }
  }

  params.data = obj;

  switch (resource) {
    case 'client':
      params = prepareClientParams(params);
      break;
    case 'connection':
      params = prepareConnectionParams(params);
      break;
    case 'user':
      params = prepareUserParams(params);
      break;
    case 'profile':
      params = prepareProfileParams(params);
      break;
    case 'endpoint':
      params = prepareEndpointParams(params);
      break
    case 'key':
      params = prepareKeyParams(params);
      break;
    case 'template':
      params = prepareTemplateParams(params);
      break;
    default:
      params = prepareUpdateCreateParams(params);
      break;
  }

  return params;
};

const prepareClientParams = function (params) {
  delete params.data.code;

  // params.data.notification = typeof params.data.notification !== 'undefined' ? params.data.notification : {};
  // params.data.notification.po_email_enabled = toBooleanField(params.data.notification, 'po_email_enabled');

  if (typeof params.data.security === 'object') {
    params.data.security.encryption_enabled = toBooleanField(params.data.security, 'encryption_enabled');
    // hot-fix after upgrade
    if (!params.data.security.encryption_enabled) {
      delete params.data.security.encryption_key;
    }
  }

  return prepareUpdateCreateParams(params);
};

const prepareConnectionParams = function (params) {
  if (Object.prototype.hasOwnProperty.call(params.data, 'code')) {
    delete params.data.code;
  }
  if (Object.prototype.hasOwnProperty.call(params.data, 'attributes')) {
    delete params.data.attributes;
  }

  params.data.is_default = toBooleanField(params.data, 'is_default');
  params.data.apply_match_rule = toBooleanField(params.data, 'apply_match_rule');

  //--------------------------------------------------------------------//
  if (params.data.type === TYPE_SETUP_REQUEST) {
    params.data.customer = {
      allow_create: toBooleanField(params.data.customer, 'allow_create'),
      allow_update: toBooleanField(params.data.customer, 'allow_update'),
      allow_guest:  toBooleanField(params.data.customer, 'allow_guest'),
      allow_signed: toBooleanField(params.data.customer, 'allow_signed'),
    };

    delete params.data.po_settings;
  //--------------------------------------------------------------------//
  } else if (params.data.type === TYPE_ORDER_RECEIPT_REQUEST) {
    params.data.po_settings = {
      allow_upsert_order:   toBooleanField(params.data.po_settings, 'allow_upsert_order'),
      allow_change_order:   toBooleanField(params.data.po_settings, 'allow_change_order'),
      allow_change_replace: toBooleanField(params.data.po_settings, 'allow_change_replace'),
      allow_cancel_order: toBooleanField(params.data.po_settings, 'allow_cancel_order'),
      //
      items_mode_create_order:  params.data.po_settings.items_mode_create_order,
      items_mode_change_order:  params.data.po_settings.items_mode_change_order,
      items_mode_cancel_order:  params.data.po_settings.items_mode_cancel_order,
      //
      allowed_non_catalog_skus: toArrayField(params.data.po_settings, 'allowed_non_catalog_skus'),
    };

    params.data.notifications = {
      enabled_or_success: toBooleanField(params.data.notifications, 'enabled_or_success'),
      enabled_or_failure: toBooleanField(params.data.notifications, 'enabled_or_failure'),
      enabled_po_success: toBooleanField(params.data.notifications, 'enabled_po_success'),
      enabled_po_failure: toBooleanField(params.data.notifications, 'enabled_po_failure'),
    };

    delete params.data.chooser_target;
    delete params.data.customer;
    delete params.data.cart_settings;
    delete params.data.cart_totals;
  }
  //--------------------------------------------------------------------//

  return prepareUpdateCreateParams(params);
};

const prepareUserParams = function (params) {
  if (!session.hasAdminPermission()) {
    if (Object.prototype.hasOwnProperty.call(params.data, 'client_id')) {
      delete params.data.client_id;
    }
    if (Object.prototype.hasOwnProperty.call(params.data, 'permission')) {
      delete params.data.permission;
    }
  }

  if (Object.prototype.hasOwnProperty.call(params.data, 'password') && !params.data.password) {
    delete params.data.password;
  }
  if (Object.prototype.hasOwnProperty.call(params.data, 'password_confirmation') && !params.data.password_confirmation) {
    delete params.data.password_confirmation;
  }

  params = prepareUpdateCreateParams(params);
  return params;
};

const prepareProfileParams = function (params) {
  for (let field in params.data) {
    if (!PROFILE_FIELDS.includes(field)) {
      delete params.data[field];
    }
  }

  if (!session.hasAdminPermission()) {
    delete params.data.is_shared;
  } else {
    params.data.is_shared = toBooleanField(params.data, 'is_shared');
  }

  return prepareUpdateCreateParams(params);
};

const prepareTemplateParams = function (params) {
  if (!session.hasAdminPermission()) {
    delete params.data.is_shared;
  } else {
    params.data.is_shared = toBooleanField(params.data, 'is_shared');
  }

  return prepareUpdateCreateParams(params);
};

const prepareEndpointParams = function (params) {
  // if (typeof params.data.response !== "undefined" && typeof params.data.response.mapping !== "undefined") {
  //   params.data.response.mapping.is_req_ext_order_id = toBooleanField(params.data.response.mapping, 'is_req_ext_order_id');
  // }
  return prepareUpdateCreateParams(params);
};

const prepareKeyParams = function (params) {
  if (params.data.hasOwnProperty('expires_never') ) {
    params.data.expires_at = '';
    delete params.data.expires_never;
  }

  return prepareUpdateCreateParams(params);
};

const prepareUpdateCreateParams = function (params) {
  //remove items we do not want to ever update
  if (params.data) {
    if (params.data.id) {
      delete params.data.id;
    }
    if (params.data.created_at) {
      delete params.data.created_at;
    }
    if (params.data.updated_at) {
      delete params.data.updated_at;
    }
    if (!params.data.connection_id) {
      delete params.data.connection_id;
    }
    if (params.data['g-recaptcha-response']) {
      delete params.data['g-recaptcha-response'];
    }

    params.data = normalizeData(params.data);
  }

  return params;
};

const normalizeData = (data = {}) => {
  let result = {};

  for (let p in data) {
    if (isBooleanField(p)) {
      data[p] = !!data[p] ? 1 : 0;
    }
    setValue(result, p, data[p]);
  }

  return result;
};

function setValue(obj, path, value) {
  path = path.replaceAll('[', '.').replaceAll(']', '');

  set(obj, path, value);

  return obj;
}


function isBooleanField(path = '') {
  const p = path.split('.');
  const pLast = p.pop();

  return (config.common_boolean.indexOf(pLast) !== -1)
}

const filterPrepareBooleanValues = function (json, fields) {
  fields.forEach(field => {
    if (typeof json[field] !== 'undefined') {
      json[field] = !!json[field] ? 1 : 0;
    }
  });
  return json;
};

const viewPrepareBooleanValues = function (json, fields) {
  fields.forEach(field => {
    if (typeof json[field] !== 'undefined') {
      json[field] = !!json[field];
    }
  });
  return json;
};

const listingPrepareBooleanValues = function (rows, fields) {
  let i = 0;
  for (i; i < rows.length; i++) {
    rows[i] = viewPrepareBooleanValues(rows[i], fields);
  }
  return rows;
};

//---------------------------------------------------------------------------//
//hot-fix after upgrade to satisfy: validateResponseFormat)_
const wrapRecord = (data) => {
  // if (!!data && !data.id && !!data.code) {
  //   data.id = data.code;
  // }
  return data;
}
//---------------------------------------------------------------------------//
//hot-fix after upgrade to satisfy: validateResponseFormat)_
const wrapRecords = (data) => {
  return Array.isArray(data) ? data.map(wrapRecord) : data;
};
//---------------------------------------------------------------------------//

export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({

  getList: (resource, params) => {
    let query = {};
    const resourcePath = getResourcePath(resource);

    if (typeof params.filter === 'object') {
      params.filter = filterPrepareBooleanValues(params.filter, ['is_system', 'is_default', 'is_shared']);
    }

    if (resource !== 'report/statistic') {
      const {page, perPage} = params.pagination;
      const {field, order} = params.sort;

      query = __assign({}, fetchUtils.flattenObject(params.filter), {
        sort: field,
        dir: order ? order.toLowerCase() : 'asc',//api accepts only lower case
        page: page,
        limit: perPage
      });
    } else {
      query = params;
    }

    if (hasSharedAccess(resource)) {
      query['include_shared'] = 1;
    }

    const url = `${apiUrl}/${resourcePath}?${stringify(query)}`;

    return httpClient(url)
      .then(({headers, json}) => {
        if (!headers.has('x-total-count')) {
          throw new Error(
            'app.core.request.error.missing_header_x_total'
          );
        }

        switch (resource) {
          case 'client':
          case 'connection':
          case 'profile':
          case 'template':
            json = listingPrepareBooleanValues(json, ['is_system', 'is_default', 'is_shared']);
            break;
          default:
            break;
        }

        return {
          data: wrapRecords(json),
          total: parseInt(
            headers
              .get('x-total-count')
              .split('/')
              .pop(),
            10
          ),
        };
    })
    .catch((err) => {
      return {
        error: err,
        data: [{id: 'error', message: err.message ? err.message : err}],//work around passing an error to List component
        total: 0,
      };
    });
  },

  getOne: (resource, params) => {
    const resourcePath = getResourcePath(resource);
    let url = '';
    if (resource !== 'transactions') {
      url = `${apiUrl}/${resourcePath}/${params.id}`;
    } else {
      url = `${apiUrl}/${resourcePath}/${params.time}/${params.type}`
    }

    if (typeof params['content-type'] === 'undefined') {
      params['content-type'] = 'application/json';
    }

    const isJson = params['content-type'] === 'application/json';

    if (isJson) {
      return httpClient(url).then(({json}) => ({data: wrapRecord(json)}))
    } else {
      return httpClient(url).then(({body}) => ({data: body}))
    }
  },

  getMany: (resource, params) => {
    let query = toQuery(params);

    if (hasSharedAccess(resource)) {
      query['include_shared'] = 1;
    }

    const resourcePath = getResourcePath(resource);
    const url = `${apiUrl}/${resourcePath}?${stringify(query)}`;

    return httpClient(url)
      .then(({json}) => ({data: wrapRecords(json)}))
    ;
  },

  //@todo: re-implement it - does not work at all
  getManyReference: (resource, params) => {
    const resourcePath = getResourcePath(resource);
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;

    const filter = {...params.filter};

    if (params.target && params.target !== '_') {
      filter[params.target] = params.id;
    }

    var query = {
      page: page,
      limit: perPage
    };

    if (field) {
      query.sort = field;
    }
    if (order) {
      query.dir = order;
    }

    if (hasSharedAccess(resource)) {
      query['include_shared'] = 1;
    }

    query = __assign({}, fetchUtils.flattenObject(filter), query);

    const url = `${apiUrl}/${resourcePath}?${stringify(query)}`;

    return httpClient(url)
      .then(({headers, json}) => {
        if (!headers.has('x-total-count')) {
          throw new Error(
            'app.core.request.error.missing_header_x_total'
          );
        }

        switch (resource) {
          case 'client':
          case 'connection':
          case 'profile':
          case 'template':
            json = listingPrepareBooleanValues(json, ['is_system', 'is_default', 'is_shared']);
            break;
          default:
            break;
        }

        return {
          data: wrapRecords(json),
          total: parseInt(
            headers
              .get('x-total-count')
              .split('/')
              .pop(),
            10
          ),
        };
      })
      .catch((err) => {
        return {
          error: err,
          data: [{id: 'error', message: err.message ? err.message : err}],//work around passing an error to List component
          total: 0,
        };
      });
  },

  update: (resource, params) => {
    params = prepareParamsBefore(resource, params);
    let id = '';
    if (Object.prototype.hasOwnProperty.call(params, 'id')) {
      id = params.id;
    }

    const resourcePath = getResourcePath(resource);
    return httpClient(`${apiUrl}/${resourcePath}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)}).then(({json}) => ({data: wrapRecord(json)}))
  },

  create: (resource, params) => {
    params = prepareParamsBefore(resource, params);

    const resourcePath = getResourcePath(resource);
    return httpClient(`${apiUrl}/${resourcePath}`, {
      method: 'POST',
      body: JSON.stringify(params.data)}).then(({json}) => ({data: wrapRecord(json)}))
  },

  updateMany: (resource, params) => {
    let query = toQuery(params);
    let resourcePath = getResourcePath(resource);

    return httpClient(`${apiUrl}/${resourcePath}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: wrapRecords(json)}))
      // .catch((err) => {
      //   return {
      //     error: err,
      //     data: [{id: 'error', message: err.message ? err.message : err}],
      //     total: 0,
      //   };
      // })
  },

  delete: (resource, params) => {
    let id = null;
    if (resource === 'token') {
      id = params.previousData.code;
    } else {
      id = params.id;
    }

    const resourcePath = getResourcePath(resource);
    return httpClient(`${apiUrl}/${resourcePath}/${id}`, {method: 'DELETE'}).then(({json}) => ({data: wrapRecord(json)}))
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    const resourcePath = getResourcePath(resource);
    return httpClient(`${apiUrl}/${resourcePath}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data)}).then(({json}) => ({data: wrapRecords(json)}))
  }
});
