import React, {useState} from 'react';
import {useTranslate} from 'ra-core';
import {useDataProvider, useRefresh, useNotify} from 'react-admin';
import {Link} from 'react-router-dom';

export const simulateDownload = (record) => {
  // Creating the blob file and its url
  let blob = new Blob([JSON.stringify(record)], {type: 'application/json'});
  let url = window.URL.createObjectURL(blob);

  // Creating the hyperlink and auto click it to start the download
  let link = document.createElement('a');
  link.href = url;
  link.download = record.type + '-' + record.number + '.json';
  link.click();
};

export const DownloadButton = ({resource, record}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);

  const download = () => {
    setLoading(true);

    dataProvider
      .getOne(resource + '/download', {id: record.id})
      .then(({data}) => {
        simulateDownload(data);
        setLoading(false);
      })
      .catch(error => {
        notify(translate('app.' + resource + '.label') + ` download error: ${error.message}`, 'warning');
        setLoading(false);
      });
  }

  return <Link to="#" onClick={download.bind(this, record)}>{translate("app.general.labels.download_json")}</Link>;
};


