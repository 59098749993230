import session from "../../../app/session";
import {SOURCE_BIGCOMMERCE_CLOUD} from "../consts";

export default (values = {}) => {
  const errors = {};

  if (!values.name) {
    errors.name = ['The Name is required'];
  }
  if (!values.status) {
    errors.status = ['The Status is required'];
  }
  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }

  //Custom validation here
  if (!!values.source
    && (values.source === SOURCE_BIGCOMMERCE_CLOUD)
    && (!values.channel_id || values.channel_id === '0')
  ) {
    errors.channel_id = ['The Channel is required'];
  }

  return errors
};
