import React, {useState} from 'react';
import {Create, Edit, SimpleForm, FormDataConsumer} from 'react-admin';
import {useTranslate} from 'ra-core';

import session from '../../app/session';
import {duplicatePermission, deletePermission, editPermission} from './permissions';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {FormClientSection} from '../../components/poc/Form/Fields';

import validateView from './lib/validate';

import FormCommonSection from './components/common';
import FormSourceSection from './components/source';
import FormHeadersSection from './components/headers';
import FormAuthorizationSection from './components/authorization';
import FormResponseRulesSection from './components/response-rules';
import FormResponseMappingSection from './components/response-mapping';
import FormSimpleSection from './components/simple';
import {SIMPLE_SOURCES} from "./consts";
//import FormRoutesSection from './components/routes';

export const EndpointCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.endpoint.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

export const EndpointEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit{...props} className={classes.root} successMessage={translate('app.endpoint.updated')}>
      {EditForm(props, 'edit')}
    </Edit>
  )
};

export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar
        validate={validateView}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />}
    >
      <Actions
        className={classes.actions}
        listUrl="/endpoints"
        title={translate('app.endpoint.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
        duplicateConditions={mode === 'edit' ? duplicatePermission : false}
        saveConditions={mode === 'edit' ? editPermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            {session.hasAdminPermission() &&
              <FormClientSection ex={ex} formData={formData} />
            }
            <FormCommonSection ex={ex} formData={formData} />
            <FormSourceSection classes={ex.classes} translate={ex.translate} formData={formData} setError={setError} setLoading={setLoading} loading={loading}/>

            {SIMPLE_SOURCES.indexOf(formData.source) === -1 &&
              <>
                <FormAuthorizationSection ex={ex} formData={formData} />
                <FormResponseMappingSection source="response"   ex={ex} formData={formData} />
                <FormResponseRulesSection source="response"   ex={ex} formData={formData} />
                <FormHeadersSection  source="headers"    ex={ex} formData={formData} />
              </>
            }

            {SIMPLE_SOURCES.indexOf(formData.source) !== -1 &&
              <FormSimpleSection source="templates" ex={ex} formData={formData} />
            }
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
};
