/** @todo: add subscription and connections status info */
import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  required,
  email
} from 'react-admin';
import {useTranslate} from 'ra-core';
import moment from 'moment';
import Chip from '@mui/material/Chip';

import {getGatewayUrl, getConnectionsUrl} from './helper';
import {
  AVAILABLE_CLIENT_STATUSES,
  CONTACT_TYPES,
  TYPE_INTEGRATION,
  AVAILABLE_CHOOSER_OPTIONS,
} from './consts';
import {deletePermission} from './permissions';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {CHANNEL_API} from "../../app/etc/consts";
import {TYPE_ASN, TYPE_INVOICE, TYPE_TRANSFERRED_CART} from "../document/consts";
import {ProfileField} from "../../components/poc/Form/Fields";
import {DATA_FORMAT_CXML, DATA_FORMAT_OCI} from "../profile/consts";
//import PropTypes from "prop-types";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ['The Name is required'];
  }
  if (!values.email) {
    errors.email = ['The Email is required'];
  }
  if (!values.status) {
    errors.status = ['The Status is required'];
  }

  return errors
};

export const ClientCreate = (props) => {
  const ex = {
    mode:      'create',
    props:     props,
    classes:   ViewStyles(),
    translate: useTranslate(),
  };

  return (
    <Create {...props} className={ex.classes.root} successMessage={ex.translate('app.client.created')}>
      {EditForm(ex)}
    </Create>
  )
};

export const ClientEdit = (props) => {
  const ex = {
    mode:      'edit',
    props:     props,
    classes:   ViewStyles(),
    translate: useTranslate(),
  };

  return (
    <Edit {...props} className={ex.classes.root} successMessage={ex.translate('app.client.updated')}>
      {EditForm(ex)}
    </Edit>
  )
};

const EditForm = (ex) => {
  const props = ex.props;
  const translate = ex.translate;
  const classes = ex.classes;
  const mode = ex.mode;
  let client = null;
  if (!!props && !!props.id) {
    client = {id: props.id};
  }

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar
        validate={validate}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />}
    >
      <Actions
        className={classes.actions}
        listUrl="/clients"
        title={translate('app.client.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />

      {mode === 'edit' &&
        <FormDataConsumer>
          {({formData}) =>
            <FormSectionGenerated disabled={true} classes={classes} formData={formData}/>
          }
        </FormDataConsumer>
      }

      <FormSectionCommon disabled={false} classes={classes} includeStatus={true}/>

      <TextInput source="description" multiline/>

      <FormSectionOci disabled={false} classes={classes} />

      <FormDataConsumer>
        {({ formData }) => formData.type === TYPE_INTEGRATION &&
            <FormSectionPlatform disabled={true} classes={classes} />
        }
      </FormDataConsumer>

      <FormSectionContacts disabled={false} classes={classes} />
      <FormHeadersSection source="attributes"  classes={classes} disabled={false}  />

      <FormDataConsumer>
        {({ formData }) =>
          <FormSectionSecurity disabled={false} classes={classes} formData={formData} />
        }
      </FormDataConsumer>

      {mode === 'edit' &&
        <FormDataConsumer>
          {({formData}) =>
            <FormSectionMappingProfiles disabled={false} ex={ex} formData={formData} client={client} />
          }
        </FormDataConsumer>
      }

    </SimpleForm>
  )
};

export const FormSectionContacts = ({disabled, classes}) => {
  const translate = useTranslate();
  const source = 'contacts';

  return (
    <>
      <div className={classes.formSection} key={source}>{translate('app.client.labels.contacts')}</div>

      <ArrayInput source={source} label=''>
        <SimpleFormIterator>

          <SelectInput source="type" choices={CONTACT_TYPES}  disabled={disabled} label={translate('app.client.'+ source +'.labels.type')} validate={[required()]} />
          <TextInput source="name"    disabled={disabled}  label={translate('app.client.'+ source +'.labels.name')}  validate={[required()]} />
          <TextInput source="company" disabled={disabled}  label={translate('app.client.'+ source +'.labels.company')}  validate={[required()]} />
          <TextInput source="email"   disabled={disabled}  label={translate('app.client.'+ source +'.labels.email')}  validate={[required(), email()]} />
          <TextInput source="phone"  disabled={disabled}   label={translate('app.client.'+ source +'.labels.phone')}  validate={[required()]} />

        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export const FormSectionPlatform = ({disabled, classes}) => {
  const translate = useTranslate();
  const source = 'platform';

  return (
    <>
      <div className={classes.formSection} key={source}>{translate('app.client.labels.platform')}</div>

      <TextInput source="platform.source"     disabled={true}  label={translate('app.client.platform.labels.source')} />
      <TextInput source="platform.url"        disabled={true}  label={translate('app.client.platform.labels.url')}  helperText={translate('app.client.platform.notes.url')} />
      <TextInput source="platform.default_tier"  disabled={true}  label={translate('app.client.platform.labels.default_tier')} />
    </>
  );
};

export const FormSectionGenerated = ({classes, formData}) => {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.formSection} key="basic">{translate('app.general.labels.basic_info')}</div>

      <dl className={classes.formSimpleList}>
        <dt><label>{translate('app.client.labels.type')}:</label></dt>
        <dd><Chip label={`${formData.type.charAt(0).toUpperCase() + formData.type.slice(1)}`} /></dd>

        <dt><label>{translate('app.client.labels.code')}:</label></dt>
        <dd><Chip label={`${formData.code}`} /></dd>

        <dt><label>{translate('app.client.labels.updated_at')}:</label></dt>
        <dd><Chip label={`${moment(formData.updated_at).format('YYYY-MM-DDTHH:mm:ss')}`} /></dd>

        <dt><label>{translate('app.client.labels.gateway_url')}:</label></dt>
        <dd><Chip label={`${getGatewayUrl(formData)}`} /></dd>

        <dt><label>{translate('app.client.labels.connections')}:</label></dt>
        <dd><a href={`${getConnectionsUrl(formData)}`}>View</a></dd>

        {formData.subscription &&
          <>
            <dt><label>{translate('app.client.labels.subscription_name')}:</label></dt>
            <dd><Chip label={formData.subscription.name} /></dd>

            <dt><label>{translate('app.client.labels.subscription_connections_number')}:</label></dt>
            <dd><Chip label={formData.subscription.connections_number} /></dd>
          </>
        }
        {formData.info &&
        <>
          <dt><label>{translate('app.client.labels.info_connections_number')}:</label></dt>
          <dd><Chip label={formData.info.connections_number} /></dd>
        </>
        }
      </dl>
    </>
  );
};


export const FormSectionOci = ({disabled, classes}) => {
  const translate = useTranslate();
  const source = 'oci';

  return (
    <>
      <div className={classes.formSection} key={source}>{translate('app.client.labels.oci')}</div>

      <ArrayInput source="oci.username_key" label={translate('app.client.oci.labels.username_key')} >
        <SimpleFormIterator>
          <TextInput disabled={disabled} label="" />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="oci.password_key" label={translate('app.client.oci.labels.password_key')} >
        <SimpleFormIterator>
          <TextInput disabled={disabled} label="" />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};


export const FormSectionCommon = ({classes, includeStatus = false}) => {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.formSection}>{translate('app.general.labels.common_info')}</div>
      {includeStatus &&
      <SelectInput source="status" choices={AVAILABLE_CLIENT_STATUSES} validate={[required()]}/>
      }
      <TextInput source="name" validate={[required()]}/>
      <TextInput source="email" validate={[required(), email()]}/>
      <TextInput source="phone"/>

      <SelectInput source="allow_connection_chooser" choices={AVAILABLE_CHOOSER_OPTIONS} validate={[required()]}/>
    </>
  );
};


export const FormHeadersSection = ({ source, classes }) => {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.formSection}>{translate('app.general.labels.' + source)}</div>

      <ArrayInput source={source} label="" >
        <SimpleFormIterator>
          <TextInput source="code"  label="app.general.labels.code" validate={[required()]}/>
          <TextInput source="value" label="app.general.labels.value" validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};


export const FormSectionSecurity = ({classes, formData}) => {
  const translate = useTranslate();
  const source = 'security';

  const encryptionEnabled = !!formData && !!formData.security && !!formData.security.encryption_enabled;

  return (
    <>
      <div className={classes.formSection} key={source}>{translate('app.client.labels.security')}</div>

      <BooleanInput
        // parse={v => !!v ? 1 : 0}
        source="security.encryption_enabled"
        label="app.client.security.labels.encryption_enabled"
      />

      {encryptionEnabled &&
        <>
          <TextInput label="app.client.security.labels.encryption_key" source="security.encryption_key" required={true} />
          <div className={classes.fieldNotes}>{translate('app.client.security.labels.encryption_key_hint')}</div>
        </>
      }
    </>
  );
};

const FormSectionMappingProfiles = ({ formData, disabled, ex, client = null}) => {
  const translate = useTranslate();

  return (
    <>
      <div className={ex.classes.formSection}>{translate('app.client.labels.external_profiles_section')}</div>

      <ProfileField formData={formData} type={TYPE_TRANSFERRED_CART} channel={CHANNEL_API} format={DATA_FORMAT_OCI}
                    label={translate('app.profile.labels.transferred_cart_format_oci')}
                    source="profiles.external_oci_transferred_cart_id"
                    disabled={disabled} ex={ex} client={client}
      />

      <ProfileField formData={formData} type={TYPE_TRANSFERRED_CART} channel={CHANNEL_API} format={DATA_FORMAT_CXML}
                    label={translate('app.profile.labels.transferred_cart_format_cxml')}
                    source="profiles.external_cxml_transferred_cart_id"
                    disabled={disabled} ex={ex} client={client}
      />

      <ProfileField formData={formData} type={TYPE_INVOICE} channel={CHANNEL_API} format={DATA_FORMAT_CXML}
                    label={translate('app.profile.labels.invoice_format_cxml')}
                    source="profiles.external_cxml_invoice_id"
                    disabled={disabled} ex={ex} client={client}
      />

      <ProfileField formData={formData} type={TYPE_ASN} channel={CHANNEL_API} format={DATA_FORMAT_CXML}
                    label={translate('app.profile.labels.asn_format_cxml')}
                    source="profiles.external_cxml_asn_id"
                    disabled={disabled} ex={ex} client={client}
      />

    </>
  );
};

// ClientCreate.propTypes = {
//   resource: PropTypes.string,
//   record: PropTypes.object,
// };
//
// ClientEdit.propTypes = {
//   resource: PropTypes.string,
//   record: PropTypes.object,
// };
