import session from '../../app/session'
import {DATA_FORMAT_CXML, ACTION_RESPONSE, STATUS_SUCCESS} from './consts';
import {TYPE_TRANSFERRED_CART} from '../document/consts';

export const flipPermission = (row) => {
  return (!!row && !!row.id
    && row.type === TYPE_TRANSFERRED_CART
    && row.format === DATA_FORMAT_CXML
    && row.action === ACTION_RESPONSE
    && row.status === STATUS_SUCCESS
    && session.hasClientUserPermission()
  );
};
