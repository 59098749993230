import React from 'react';
import {
  TextField,
  EmailField,
  ReferenceField,
  SelectField,
  EditButton,
  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput, FunctionField
} from 'react-admin';
import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import {StatusField} from '../../components/poc/StatusField';

import {deletePermission} from "./permissions";

import {AVAILABLE_USER_PERMISSIONS, AVAILABLE_USER_PERMISSIONS_FOR_CLIENT} from '../user/consts';
import {AVAILABLE_BASIC_STATUSES} from '../../app/etc/consts';

import session from '../../app/session';
import config from "../../app/etc/config";
import {formatClientName} from "../../components/poc/Grid/Fields";

const UserFilter  = props => {
  const FILTER_USER_PERMISSIONS = session.hasAdminPermission() ? AVAILABLE_USER_PERMISSIONS : AVAILABLE_USER_PERMISSIONS_FOR_CLIENT;

  return (
    <ListFilter {...props}>
      <TextInput source="id"/>
      <TextInput source="firstname"/>
      <TextInput source="lastname"/>
      <TextInput source="email"/>

      <SelectInput
        choices={FILTER_USER_PERMISSIONS}
        source="permission"
      />

      {session.hasAdminPermission() &&
        <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
          <SelectInput optionText={formatClientName}/>
        </ReferenceInput>
      }

      <SelectInput
        choices={AVAILABLE_BASIC_STATUSES}
        source="status"
      />
      <DateInput source="created_at"/>
      <DateInput source="updated_at"/>
    </ListFilter>
  )
};

export const UserGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<UserFilter/>}
      resource="user"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <StatusField  source="status"/>
      <TextField source="firstname"/>
      <TextField source="lastname"/>
      <EmailField source="email"/>

      <SelectField
        choices={AVAILABLE_USER_PERMISSIONS}
        source="permission"
      />

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <Actions edit={true} delete={deletePermission}/>
    </ListWithLayout>
  )
}
