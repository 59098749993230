import {fetchUtils} from 'react-admin';
import config from '../app/etc/config';
import session from '../app/session';

export default class SimpleRequest
{
  constructor(conf = null) {
    this.config = !!conf ? conf : this.conf();
  }

  conf() {
    return {
      url: config.api.url,
      access_token: session.getAccessToken(),
      token_type:   session.getTokenType(),
    };
  }

  f(s) {
    return (s[s.length - 1] === '/') ? s.slice(0, s.length - 1) : s;
  }

  call(resource, onSuccess, onError = null, onRequest = null, options = {}) {
    if (!options.headers) {
      options.headers = this.h();
    }

    const url = [this.f(this.config.url), this.f(resource)].join('/');

    if (!!onRequest) {
      onRequest();
    }

    fetchUtils
      .fetchJson(url, options)
      .then(onSuccess)
      .catch(onError ? onError : this.onError);
  }

  onError(e) {
    console.error(e);
  }

  h() {
    return new Headers({
      Accept: 'application/json',
      Authorization: this.config.token_type + ' ' + this.config.access_token,
    });
  }
}
