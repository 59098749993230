import React, {Fragment} from 'react';
import {
  TextField,
  DateField,
  ReferenceField,
  SelectField,

  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput,

  NumberField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import {StatusField} from '../../components/poc/StatusField';
import Amount from './components/amount';
import {DocumentBrief} from './brief';

import session from '../../app/session';
import config from '../../app/etc/config';
import {AVAILABLE_TYPES, AVAILABLE_STATUSES} from './consts';
import {formatClientName} from '../../components/poc/Grid/Fields';
import {EnvField} from '../../components/poc/EnvField';
import {toOptions} from '../../lib/source';
import {DocumentGridActionButtons, isDocumentGridRowSelectable} from "./components/grid-bulk-actions";

const DocumentFilter  = props => {
  return (
    <ListFilter {...props}>
      <TextInput source="id:in" label="ID"/>

      {session.hasAdminPermission() &&
        <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
          <SelectInput optionText={formatClientName}/>
        </ReferenceInput>
      }

      <ReferenceInput label="Buyer" reference="buyer" source="buyer_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput source="name"/>
      </ReferenceInput>

      <SelectInput choices={AVAILABLE_TYPES} source="type"/>

      <TextInput source="number"/>
      <SelectInput choices={toOptions(AVAILABLE_STATUSES)} source="routing_status" label="Routing Status"/>
      <TextInput source="spaid" label="SPAID"/>

      <DateInput source="created_at"/>
    </ListFilter>
  )
};


export const DocumentGrid = props => {

  return (
    <ListWithLayout
      {...props}
      filters={<DocumentFilter/>}
      resource="document"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={false}
      rowClick="expand"
      expand={<DocumentBrief />}
      isRowSelectable={isDocumentGridRowSelectable}
      bulkActionButtons={session.hasAdminPermission() ? <DocumentGridActionButtons /> : null}
    >

      <TextField source="id"/>

      <StatusField source="routing.status" label="Routing Status"/>

      {session.hasAdminPermission() &&
      <ReferenceField
        label="Client"
        reference="client"
        sortable={false}
        source="client_id"
      >
        <TextField source="name"/>
      </ReferenceField>}

      <ReferenceField
        label="Buyer"
        reference="buyer"
        sortable={false}
        source="buyer_id"
      >
        <TextField source="name"/>
      </ReferenceField>

      <SelectField choices={AVAILABLE_TYPES} source="type"/>

      <TextField source="number"/>

      <Amount label="Grand Total" amountName="grand_total"/>
      <NumberField source="total_items"  />

      <EnvField source="env"/>

      <DateField source="created_at" showTime/>
    </ListWithLayout>
  )
};
