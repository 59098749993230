/**
 * @todo: AutocompleteInput instead of SelectInput
 */
import React, {Fragment, useState} from 'react';
import {
  Create,
  Edit,

  SimpleForm,

  TextField,
  TextInput,
  SelectInput,

  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,

  FormDataConsumer,
  useDataProvider,

  Loading,
  Error,

  required
} from 'react-admin';

import { useForm } from 'react-final-form';

import {useTranslate} from 'ra-core';

import {deletePermission} from "./permissions";

import {ViewStyles} from '../../theme/skin/poc/styles';

import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField} from '../../components/poc/Form/Fields';

import session from '../../app/session';
import config from '../../app/etc/config';
import {useFormContext} from "react-hook-form";

const validate = (values) => {
  const errors = {};

  if (!values.host) {
    errors.name = ['The Host is required'];
  }

  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }

  return errors
};

const defaultClient = () => {
  return {
    id: session.getClientId(),
    type: session.getClientType(),
  }
};

const handlers = (ex) => {
  const {state, provider} = ex;

  const loadClient = (id) => {
    state.setLoading(true);

    provider.getOne('client', {id: id})
      .then(({data}) => {
        state.setClient({
          id: data.id,
          type: data.type,
          code: data.code,
        });

        state.setError(false);
        state.setLoading(false);
      }).catch(error => {
      state.setError(error);
      state.setLoading(false);
    })
  };

  return {
    loadClient: loadClient,
  }
};

export const WhitelistRefererCreate = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(defaultClient());

  const state = {
    client:  client,   setClient: setClient,
    error:   error,    setError: setError,
    loading: loading,  setLoading: setLoading
  };

  const ex = {
    mode:      'create',
    props:     props,
    classes:   ViewStyles(),
    translate: useTranslate(),
    provider:  useDataProvider(),
    state:     state
  };

  return (
    <Create {...props} className={ex.classes.root} successMessage={ex.translate('app.referer.created')} redirect={false}>
      {EditForm(ex)}
    </Create>
  );
};

const EditForm = (ex) => {
  const { mode, props, classes, translate, state } = ex;
  const disabled = ex.state.loading;

  //https://github.com/marmelab/react-admin/issues/2572
  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar
        validate={validate}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />}
    >

      <Actions
        className={classes.actions}
        listUrl="/referers"
        title={translate('app.referer.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />

      <FormDataConsumer>
        {({formData}) => {
          if (!ex.state.client) {
            ex.state.setClient(formData.client);//Set initial client from connection
          }
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps =>
          <>
            <FormSectionClientSettings  {...formDataProps} disabled={disabled} ex={ex} client={ex.state.client} />
            <FormSectionConnectionSettings {...formDataProps} disabled={disabled} ex={ex} client={ex.state.client} />
            <FormSectionBasicSettings {...formDataProps} disabled={disabled} ex={ex} client={ex.state.client} />
          </>
        }
      </FormDataConsumer>

    </SimpleForm>
  );
};

const FormSectionClientSettings = ({ formData, ex, disabled }) => {
  //const form = useForm();
  const formContext = useFormContext();
  if (!session.hasAdminPermission()) {
    return null;
  }

  const onChange = e => {
    //https://github.com/marmelab/react-admin/blob/master/UPGRADE.md
    // form.change('connection_id', '');
    // @todo: fix client_id on change //
    formContext.setValue('connection_id', '');
    formContext.resetField('connection_id');
    formContext.setFocus('connection_id');

    //load data
    const clientId = (e.target && e.target.value) ? e.target.value : null;
    if (!!clientId) {
      handlers(ex).loadClient(clientId)
    }
  };

  return (<ClientField onChange={onChange} clientId={formData.client_id} disabled={disabled} ex={ex} />);
};

const FormSectionConnectionSettings = ({ formData, disabled }) => {

  const canShowField = !!formData.client_id;
  const filter = session.hasAdminPermission() ? {client_id: formData.client_id} : {};

  if (canShowField) {
    return (
      <ReferenceInput
        filter={filter}
        label="Connection"
        reference="connection"
        source="connection_id"
        disabled={disabled}
        sort={ {field: 'name', order: 'ASC'} }
        perPage={config.pagination.associated}
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )
  } else {
    return (
      <TextInput
        helperText={'Please select Client'}
        label="Connection"
        source="connection_id"
        disabled={true}
        required
      />
    )
  }
};

const FormSectionBasicSettings = ({ disabled }) => {
  return (
    <>
      <TextInput source="host" label="Host" validate={[required()]} disabled={disabled}/>
    </>
  );
};
