import React from 'react';
import {
  RefreshButton,
  ExportButton,
  CreateButton,
  TopToolbar
} from 'react-admin';
import QuickCreateButton from './components/QuickCreateButton';
import session from 'app/session';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'center',
    '& span.MuiButton-label': {
      color: theme.palette.primary.highlight
    }
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const Index = ({
  bulkActions,
  basePath,
  createButton,
  displayedFilters,
  filters,
  filterValues,
  permanentFilter,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  actions,
  exporter,
  maxResults,
  total,
  currentSort,
}) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.row}>
      <RefreshButton/>
      {exporter &&
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
      }
      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {createButton && (resource !== 'token' || (resource === 'token' && session.hasClientPermission())) &&
      <CreateButton
        basePath={basePath}
        label="Create"
      />}
      {createButton && resource === 'token' && !session.hasClientPermission() &&
      <QuickCreateButton
        basePath={basePath}
        label="Create"
        resource={resource}
      />}
      {actions && React.cloneElement(actions, {
        basePath,
        resource
      })}
    </TopToolbar>
  )
};

export default Index;
