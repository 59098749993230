import {HttpError} from 'react-admin';
import dataProvider from './dataProvider';
import config from '../app/etc/config'
import session from '../app/session'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  if (session.isLogged()) {
    const token = session.getAccessToken();
    options.headers.set('Authorization', token ? `Bearer ${token}` : '');
  }
  return fetchJson(url, options);
};
export const pocDataProvider = dataProvider(config.api.url, httpClient);

const fetchJson = async (url, options = {}) => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })
  );
  if (!requestHeaders.has('Content-Type') &&
    !(options && options.body && options.body instanceof FormData)) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }
  const response = await fetch(url, { ...options, headers: requestHeaders })
  const text = await response.text()
  const o = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    body: text,
  };
  let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deal
  }
  if (status < 200 || status >= 300) {
    return Promise.reject(new HttpError((json && json.message) || statusText, status, json));
  }
  return Promise.resolve({ status: status, headers: headers, body: body, json: json });
};
