import React from 'react';
import {Card, CardContent, Box} from '@mui/material';
import DocumentTypesSummaryGraphContainer from "../DocsSummary/DocsSummaryGraphContainer";
import { makeStyles } from '@mui/styles';

const BriefCaption = (client) => {
  const classes = useStyles();

  let caption = client.name + " The Last Year Statistic";
  return  (<div className={classes.briefCaption}><h2>{caption}</h2></div>);
}

export const ClientBrief = (props) => {
  const {record} = props;
  if (!record || !record.id) return null;

  return (
  <>
    <CardContent>
      {BriefCaption(record)}
      <DocumentTypesSummaryGraphContainer
        selectedClientId={record.id}
        showFilters={false}
        defaultSelectedTime="last_year"
        graphAmountsCaption="Amounts Summary"
        graphAmountsHint="Client Grouped summary of amounts per the last year"
        graphNumbersCaption="Transactions Summary"
        graphNumbersHint="Client Grouped number of transactions per the last year"
      />

    </CardContent>
  </>
  );
};

const useStyles = makeStyles({
  briefCaption: {
    textAlign: 'center',
    marginBottom: '30px',
    marginTop: '10px',
  }
});
