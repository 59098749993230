import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {TextField as BaseTextField, CircularProgress, Avatar} from '@mui/material';
import Recaptcha from 'react-recaptcha';
import {ReferenceInput, SelectInput, TextInput, AutocompleteInput, useGetOne, useRecordContext} from 'react-admin';
// import { useFormContext } from 'react-hook-form';
import config from "../../../app/etc/config";
import {PublicStyles} from '../../../theme/skin/poc/styles';
import session from "../../../app/session";
import {STATUS_ACTIVE} from "../../../app/etc/consts";
import {StatusFieldRender} from "../StatusField";

// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";

const formatName = (row) => {
  if (row.status != STATUS_ACTIVE) {
    return `${row.name}  (${row.status})`;
  } else {
    return `${row.name}`;
  }
};

export const TextField = ({
                            meta: {touched, error} = {touched: false, error: undefined},
                            input: {...inputProps},
                            ...props
                          }) => (
  <BaseTextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

export const PasswordField = ({
                                meta: {touched, error} = {touched: false, error: undefined},
                                input: {...inputProps},
                                ...props
                              }) => (
  <BaseTextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    type="password"
  />
);

export const PublicFormIcon = () => {
  const classes = PublicStyles();

  return (
    <Avatar className={classes.icon}>
      <a href="#"><img alt="Punchout Cloud" src={config.logo.public_img} className={classes.iconImg}/></a>
    </Avatar>
  );
};

export const CaptchaField = ({verified, setVerified}) => {
  //const [verified, setVerified] = useState(!config.google.recaptcha.enabled);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const classes = PublicStyles();

  const onloadCallback = () => {
    setRecaptchaLoaded(true);
  };

  const verifyCallback = () => {
    setVerified(true);
  };

  if (!config.google || !config.google.recaptcha || !config.google.recaptcha.enabled) {
    return null;
  }

  return (
    <>
      <div className={classes.recaptcha}>
        {!recaptchaLoaded && (
          <CircularProgress
            size={25}
            thickness={2}
          />
        )}
        <Recaptcha
          elementID="recaptcha-div"
          onloadCallback={onloadCallback}
          render="explicit"
          sitekey={config.google.recaptcha.site_key}
          verifyCallback={verifyCallback}
        />
      </div>
      <div id="recaptcha-div" />
    </>
  )
};

//@See: POC-2829 - fix client_id from autocomplete
export const ClientFieldTransform = (formContext, data = {}) => {
  if (typeof data === 'object'
    && typeof formContext !== 'undefined'
    && typeof data.client_id === 'string'
    && data.client_id != formContext.getValues('client_id')
  ) {
    data.client_id = formContext.getValues('client_id');
  }

  return data;
}

//@See: POC-2829 - fix client_id from autocomplete
export const ClientField = ({clientId, disabled, beforeElementContainer, afterElementContainer, ex, filter = {}}) => {
  // const [value, setValue] = React.useState(clientId);
  // const [inputValue, setInputValue] = React.useState("");
  // const formContext = useFormContext();

  const filterToQuery = (searchText) => ({ 'name:like': `${searchText}`, ...filter });
  const OptionRenderer = (record) => {
    return <span>{StatusFieldRender(record, 'status')}   <span>{record.name}</span></span>;
  }
  const inputText = choice => `${choice.name}`;

  return (
    <>
      {!!beforeElementContainer ? beforeElementContainer : null}

      <ReferenceInput
        source="client_id"
        label="Client"
        reference="client"
        disabled={disabled}
        // perPage={config.pagination.associated}
        sort={{field: 'name', order: 'ASC'}}
        required
        filter={filter}
      >
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText={OptionRenderer}
          inputText={inputText}
          // optionText="name"
          // optionValue="id"
          // source="client_name"
          // value={value}
          // inputValue={inputValue}

            // onChange={(event, newValue) => {
            //   // console.log("### -> onChange -> newValue: ", newValue);
            //   // setValue(newValue);
            //   formContext.setValue('client_id', newValue && newValue.id ? newValue.id : newValue);
            // }}
            // onInputChange={(event, newInputValue) => {
            //   // console.log("### -> onInputChange -> newInputValue: ", newInputValue);
            //   setInputValue(newInputValue);
            // }}

        />
      </ReferenceInput>

      {clientId &&
        <Link
          className={ex.classes.link_view}
          disabled={disabled}
          to={{ pathname: `/client/${clientId}` }}
        >{ex.translate('app.client.labels.link_view')}</Link>
      }
      {afterElementContainer}
    </>
  );
};

/**
 *      <Autocomplete
 *         value={value}
 *         onChange={(event, newValue) => {
 *           // console.log("# -> onChange -> newValue: ", newValue);
 *           setValue(newValue);
 *         }}
 *         inputValue={inputValue}
 *         onInputChange={(event, newInputValue) => {
 *           // console.log("# -> onInputChange -> newInputValue: ", newInputValue);
 *           setInputValue(newInputValue);
 *         }}
 *         options={options}
 *         sx={{ width: 300 }}
 *         renderInput={(params) => <BaseTextField {...params} />}
 *       />
 *
 *       <AutocompleteInput
 *         source="client_id"
 *         choices={options}
 *         // filterToQuery={filterToQuery}
 *         // optionText={OptionRenderer}
 *         // inputText={inputText}
 *         // optionText="name"
 *         // optionValue="id"
 *         // source="client_name"
 *         // value={value}
 *         // onChange={(event, newValue) => {
 *         //   setValue(newValue);
 *         // }}
 *       />
 * */

export const getFormDataValue = (formData, source) => {
  if (typeof source !== 'string') {
    return null;
  }

  if (source.indexOf('.') !== -1) {
    let keys = source.split('.');
    let key = null;
    let obj = formData;

    while (key = keys.shift()) {
      if (typeof obj !== 'undefined' && typeof obj[key] !== 'undefined') {
        obj = obj[key];
      }
    }

    return typeof obj !== 'undefined' && typeof obj !== 'object' ? obj : null;
  } else {
    return typeof formData[source] !== 'undefined' ? formData[source] : null;
  }
};

export const ProfileField = ({formData, type, label, source, channel, disabled, required, ex, format = null, client = null}) => {
  let profileId = getFormDataValue(formData, source);
  let clientId = formData.client_id;
  if (!clientId && !!client && !!client.id) {
    clientId = client.id;
  }

  const selectedFormat = format || formData.format;

  const msgProfile = session.hasAdminPermission()
    ? 'Please select connection client, type and format'
    : 'Please select connection type and format'
  ;
  const canShowField = session.hasAdminPermission()
    ? (selectedFormat && formData.type && clientId)
    : (selectedFormat && formData.type)
  ;
  const filter = session.hasAdminPermission()
    ? {format: selectedFormat, type: type, channel: channel, include_shared: 1, client_id: clientId}
    : {format: selectedFormat, type: type, channel: channel, include_shared: 1}
  ;

  const formatProfileName = (row) => {
    if (row.status != STATUS_ACTIVE) {
      return `${row.name}  (${row.version} / ${row.status})`;
    } else {
      return `${row.name} (${row.version})`;
    }
  };

  if (canShowField) {
    return (
      <>
        <ReferenceInput
          filter={filter}
          label={label}
          reference="profile"
          source={source}
          disabled={disabled}
          perPage={config.pagination.associated}
          required={required}
          allowEmpty
        >
          <SelectInput optionText={formatProfileName} required={required}/>
        </ReferenceInput>

        {profileId &&
          <Link
            className={ex.classes.link_view}
            disabled={disabled}
            to={{ pathname: `/profile/${profileId}` }}
          >{ex.translate('app.profile.labels.link_view')}</Link>
        }
      </>
    );
  } else {
    return (
      <>
        <TextInput
          helperText={msgProfile}
          label={label}
          source={source}
          disabled={true}
          required={required}
        />
      </>
    );
  }
};

//<AutocompleteInput optionText={<FullNameField/>} inputText={(record) => record.name} matchSuggestion={matchSuggestion} />
export const EndpointField = ({formData, disabled, ex, source, label = null, filter = {}, required = true, allowEmpty = true}) => {
  let endpointId = getFormDataValue(formData, source);

  const _label = label || ex.translate('app.connection.labels.endpoint');
  const _filter = session.hasAdminPermission() ? {...filter, client_id: formData.client_id} : filter;
  const msg = session.hasAdminPermission() ? 'Please, select connection client' : '';
  const canShowField = session.hasAdminPermission() ? !!formData.client_id : true;

  if (canShowField) {
    return (
      <>
        <ReferenceInput
          filter={_filter}
          label={_label}
          reference="endpoint"
          source={source}
          disabled={disabled}
          sort={ {field: 'created_at', order: 'DESC'} }
          perPage={config.pagination.associated}
          required={required}
          allowEmpty={allowEmpty}
        >
          <SelectInput optionText={formatName} required={required}/>
        </ReferenceInput>

        {endpointId &&
          <Link
            className={ex.classes.link_view}
            disabled={disabled}
            to={{ pathname: `/endpoint/${endpointId}` }}
          >{ex.translate('app.endpoint.labels.link_view')}</Link>
        }
      </>
    );
  } else {
    return (
      <TextInput
        helperText={msg}
        label={_label}
        source={source}
        disabled={true}
        required={required}
      />
    );
  }
};

export const BuyerField = ({formData, disabled, ex}) => {
  const filter = session.hasAdminPermission() ? {client_id: formData.client_id} : {};
  const msg = session.hasAdminPermission() ? 'Please, select connection client' : '';
  const canShowField = session.hasAdminPermission() ? !!formData.client_id : true;

  if (canShowField) {
    return (
      <>
        <ReferenceInput
          filter={filter}
          label={ex.translate('app.connection.labels.buyer')}
          reference="buyer"
          source="buyer_id"
          disabled={disabled}
          sort={ {field: 'name'} }
          perPage={config.pagination.associated}
          required
        >
          <SelectInput optionText={formatName} required/>
        </ReferenceInput>
      </>
    );
  } else {
    return (
      <TextInput
        helperText={msg}
        label={ex.translate('app.connection.labels.buyer')}
        source="buyer_id"
        disabled={true}
        required
      />
    );
  }
};

export const CredentialField = ({
  formData,
  disabled,
  ex,
  source,
  onChange,
  label = '',
  required = true,
  allowEmpty = false,
  credentialTypes = []
}) => {

  let filter = session.hasAdminPermission() ? {client_id: formData.client_id} : {};
  let msg = session.hasAdminPermission() ? 'Please, select connection client' : '';

  if (!!credentialTypes && Array.isArray(credentialTypes)) {
    filter['type:in'] = credentialTypes.join(',');
  }

  const canShowField = session.hasAdminPermission() ? !!formData.client_id : true;
  const credentialId = getFormDataValue(formData, source);

  const toOptionText = (opt) => {
    return (<>{opt.name}&nbsp;&nbsp;<small>({ex.translate(`app.credential.labels.types.${opt.type}`)})</small></>);
  };

  if (canShowField) {
    return (
      <>
        <ReferenceInput
          filter={filter}
          label={label || ex.translate('app.connection.labels.credential')}
          reference="credential"
          source={source}
          disabled={disabled}
          onChange={onChange}
          sort={ {field: 'name'} }
          perPage={config.pagination.associated}
          required={required}
          allowEmpty={allowEmpty}
        >
          <SelectInput optionText={toOptionText} required={required} />
        </ReferenceInput>

        {credentialId &&
          <Link
            className={ex.classes.link_view}
            disabled={disabled}
            to={{ pathname: `/credential/${credentialId}` }}
          >{ex.translate('app.credential.labels.link_view')}</Link>
        }
      </>
    );
  } else {
    return (
      <TextInput
        helperText={msg}
        label={ex.translate('app.connection.labels.credential')}
        source={source}
        disabled={true}
        required={required}
      />
    );
  }
};

export const FormClientSection = ({ex, formData}) => {
  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.relations')}</div>
      <ClientField clientId={formData.client_id} ex={ex} />
    </>
  );
};

export const TemplateField = ({formData, disabled, ex, source}) => {
  let templateId = getFormDataValue(formData, source);

  const msg = session.hasAdminPermission() ? 'Please, select a client' : '';
  const canShowField = session.hasAdminPermission() ? !!formData.client_id : true;
  const filter = session.hasAdminPermission() ? {include_shared: 1, client_id: formData.client_id} : {include_shared: 1};

  if (canShowField) {
    return (
      <>
        <ReferenceInput
          filter={filter}
          label={ex.translate('app.template.labels.template')}
          reference="template"
          source={source}
          disabled={disabled}
          sort={ {field: 'name'} }
          perPage={config.pagination.associated}
          required
        >
          <SelectInput optionText={formatName}/>
        </ReferenceInput>

        {templateId &&
          <Link
            className={ex.classes.link_view}
            disabled={disabled}
            to={{ pathname: `/template/${templateId}` }}
          >{ex.translate('app.template.labels.link_view')}</Link>
        }
      </>
    );
  } else {
    return (
      <TextInput
        helperText={msg}
        label={ex.translate('app.template.labels.template')}
        source={source}
        disabled={true}
        required
      />
    );
  }
};

export const ConnectionField = ({formData, type, disabled, ex, source, required = true, allowEmpty= false, label = ''}) => {
  let connectionId = getFormDataValue(formData, source);

  const msg = session.hasAdminPermission() ? 'Please, select a client' : '';
  const canShowField = session.hasAdminPermission() ? !!formData.client_id : true;
  const filter = session.hasAdminPermission() ? {type: type, client_id: formData.client_id} : {type: type};

  if (canShowField) {
    return (
      <>
        <ReferenceInput
          filter={filter}
          label={ex.translate(label || 'app.connection.labels.connection')}
          reference="connection"
          sort={ {field: 'name'} }
          perPage={config.pagination.associated}
          source={source}
          disabled={disabled}
          required={required}
          allowEmpty={allowEmpty}
        >
          <SelectInput optionText={formatName} />
        </ReferenceInput>

        {connectionId &&
          <Link
            className={ex.classes.link_view}
            disabled={disabled}
            to={{ pathname: `/connection/${connectionId}` }}
          >{ex.translate('app.connection.labels.link_view')}</Link>
        }
      </>
    );
  } else {
    return (
      <TextInput
        helperText={msg}
        label={ex.translate(label || 'app.connection.labels.connection')}
        source={source}
        disabled={true}
        required={required}
      />
    );
  }
};
