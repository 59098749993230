import React from 'react';
import Chip from '@mui/material/Chip';
import {ENV_PRODUCTION, ENV_STAGING, ENV_TEST, ENV_UAT} from "../../../view/core/consts";
import {FunctionField} from 'react-admin';

export const EnvFieldRender = (record, source) => {
  if (typeof record[source] === 'undefined' || record[source] === null) {
    return null;
  }

  const val = record[source];
  let color = '#ffe18a';
  let icon = null;
  if (val === ENV_PRODUCTION) {
    color = '#129100';
  } else if (val === ENV_STAGING || val === ENV_UAT || val === ENV_TEST) {
    color = '#fc9803';
  }

  return (
    <Chip
      size="small"
      label={val}
      icon={icon}
      color='secondary'
      style={{backgroundColor: color}}
    />
  )
};

export const EnvField = ({source}) => {
  return (<FunctionField render={record => EnvFieldRender(record, source)}/>)
};

EnvField.defaultProps = { addLabel: true };
