import React from 'react';
import {
  TextField,
  DateField,
  ReferenceField,
  SelectField,

  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput, FunctionField
} from 'react-admin';

import session from '../../app/session';
import config from '../../app/etc/config';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import {
  AVAILABLE_TRANSACTION_FORMATS,
  AVAILABLE_TRANSACTION_STATUSES,
  AVAILABLE_TRANSACTION_ACTIONS,
  AVAILABLE_TRANSACTION_CHANNELS,
  AVAILABLE_TRANSACTION_TYPES
} from './consts';

import Actions from '../../components/poc/Grid/Row/Actions';
import {StatusField} from '../../components/poc/StatusField';
import {formatClientName} from "../../components/poc/Grid/Fields";
import {toOptions} from '../../lib/source';

const TransactionFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id:in" label="ID"/>
    <TextInput source="session_id"/>
    <SelectInput
      choices={toOptions(AVAILABLE_TRANSACTION_STATUSES, 'app.general.labels.')}
      source="status"
    />
    <SelectInput
      choices={toOptions(AVAILABLE_TRANSACTION_FORMATS, 'app.general.labels.')}
      source="format"
    />
    <SelectInput
      choices={toOptions(AVAILABLE_TRANSACTION_TYPES, 'app.general.labels.')}
      source="type"
    />
    <SelectInput
      choices={toOptions(AVAILABLE_TRANSACTION_CHANNELS, 'app.general.labels.')}
      source="channel"
    />
    <SelectInput
      choices={toOptions(AVAILABLE_TRANSACTION_ACTIONS, 'app.general.labels.')}
      source="action"
    />
    <SelectField
      choices={toOptions(AVAILABLE_TRANSACTION_FORMATS, 'app.general.labels.')}
      source="format"
    />

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <ReferenceInput label="Connection" reference="connection" source="connection_id">
      <SelectInput source="name"/>
    </ReferenceInput>

    <DateInput source="created_at"/>
  </ListFilter>
);

export const TransactionGrid = props => {
  return (
    <ListWithLayout
      {...props}
      bulkActionButtons={false}
      filters={<TransactionFilter/>}
      resource="transaction"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={false}
    >
      <TextField source="session_id"/>
      <StatusField source="status"/>
      <SelectField
        choices={toOptions(AVAILABLE_TRANSACTION_FORMATS)}
        source="format"
      />
      <SelectField
        choices={toOptions(AVAILABLE_TRANSACTION_TYPES, 'app.general.labels.')}
        source="type"
      />
      <SelectField
        choices={toOptions(AVAILABLE_TRANSACTION_CHANNELS, 'app.general.labels.')}
        source="channel"
      />
      <SelectField
        choices={toOptions(AVAILABLE_TRANSACTION_ACTIONS, 'app.general.labels.')}
        source="action"
      />

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <ReferenceField label="Connection" reference="connection" source="connection_id" sortable={false}>
        <TextField source="name"/>
      </ReferenceField>

      {session.hasAdminPermission() &&
        <TextField source="analyze_status" label="Analyze" emptyText="N/A"/>
      }

      <DateField source="created_at" showTime/>

      <Actions view={true}/>
    </ListWithLayout>
  )
}
