import {FormControl, MenuItem, Select, FormLabel} from '@mui/material';

import React from 'react';
import {
  SELECTOR_TIME_CURRENT_MONTH,
  SELECTOR_TIME_CURRENT_YEAR,
  SELECTOR_TIME_LAST_30_DAYS,
  SELECTOR_TIME_LAST_7_DAYS,
  SELECTOR_TIME_LAST_YEAR
} from "../consts";

export default class DocsSummaryFiltersAbstract extends React.Component {
  constructor(props) {
    super(props);
    //
    this._ = props.translate;
    this.css = props.styles;
    //
    this.setTime = props.setTime;
    this.getTime = props.getTime;

    this.timeOptions = [
      SELECTOR_TIME_CURRENT_MONTH,
      SELECTOR_TIME_CURRENT_YEAR,
      SELECTOR_TIME_LAST_7_DAYS,
      SELECTOR_TIME_LAST_30_DAYS,
      SELECTOR_TIME_LAST_YEAR,
    ];
  }

  render() {
    return (
      <>
        <FormControl className={this.css.formControl}>
          {this.props.caption && <FormLabel component="div" className={this.css.reportFilterLabel}>{this.props.caption}</FormLabel>}
          <Select displayEmpty className={this.css.reportFilterSelect} name="time" onChange={this.setTime} value={this.getTime()}>
            {this.timeOptions.map(t => <MenuItem value={t}>{this._('app.report.labels.' + t)}</MenuItem>)}
          </Select>
        </FormControl>
      </>
    );
  }
};
