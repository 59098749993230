import React from 'react';
import { Filter } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    height: '64px',
    minHeight: '64px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    '& span.MuiIconButton-label': {
      color: theme.palette.primary.highlight
    }
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const Index = props => {
  const {...rest} = props;

  const classes = useStyles();

  return (
    <Filter
      {...rest}
      className={classes.form}
    />
  );
};

export default Index;
