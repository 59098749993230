import session from '../../app/session'

export const deletePermission = (row) => {
  return (
    (!!row && !!row.is_system)
    || !row
    || !row.id
    || (row.id === session.getClientId())
  ) ? false : session.hasAdminPermission();
};

export const switchPermission = (row) => {
  return (
    (!!row && !!row.is_system)
    || !row
    || !row.id
    || (row.id === session.getClientId())
  ) ? false : session.hasAdminPermission();
};
