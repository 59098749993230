import React from 'react';
import {
  TextField,
  ReferenceField,
  TextInput,
  SelectInput,
  ReferenceInput, FunctionField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import {StatusField} from '../../components/poc/StatusField';

import {deletePermission} from "./permissions";
import {AVAILABLE_BASIC_STATUSES} from '../../app/etc/consts';
import session from '../../app/session';
import config from "../../app/etc/config";
import {formatClientName} from "../../components/poc/Grid/Fields";

const BuyerFilter  = props => {
  return (
    <ListFilter {...props}>
      <TextInput source="id:in" label="ID"/>
      <TextInput source="code:in" label="GUID Code"/>
      <TextInput source="name:like" label="Name"/>

      <SelectInput choices={AVAILABLE_BASIC_STATUSES} source="status"/>

      {session.hasAdminPermission() &&
        <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
          <SelectInput optionText={formatClientName}/>
        </ReferenceInput>
      }

    </ListFilter>
  )
};

export const BuyerGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<BuyerFilter/>}
      resource="buyer"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="code" label="GUID Code"/>

      <StatusField source="status"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <Actions edit={true} delete={deletePermission}/>
    </ListWithLayout>
  )
};
