import React from 'react';
import {Create, Edit, SimpleForm, SelectInput, FormDataConsumer, TextInput} from 'react-admin';
import {useTranslate} from 'ra-core';

import {AVAILABLE_CLIENT_TOKEN_TYPES, AVAILABLE_CLIENT_TOKEN_TYPES_FOR_CLIENT} from './consts';
import session from '../../app/session';

import {deletePermission} from "./permissions";

import {ViewStyles} from '../../theme/skin/poc/styles';
import Toolbar from '../../components/poc/Toolbar';
import Actions from '../../components/poc/Actions';
import {ClientField} from '../../components/poc/Form/Fields';
// import {Update} from '@mui/icons-material';

const validate = (values) => {
  const errors = {};

  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }

  if (session.hasAdminPermission() && !values.type) {
    errors.type = ['The Type is required'];
  }

  return errors
};

export const TokenCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();
  return (
    <Create {...props} className={classes.root} successMessage={translate('app.token.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

export const TokenEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  // hot-fix
  if (!session.hasAdminPermission()) {
    throw new Error('Can\'t render token edit page');
  }

  return (
    <Edit {...props} className={classes.root} successMessage={translate('app.token.updated')}>
      {EditForm(props, 'edit')}
    </Edit>
  )
};

export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar validate={validate} deleteConditions={mode === 'edit' ? deletePermission : false}/>}
    >
      <Actions
        className={classes.actions}
        listUrl="/tokens"
        title={translate('app.token.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            {mode !== 'create' &&
              <TextInput source="code" disabled={true} label={ex.translate('app.general.labels.code')} />
            }

            {session.hasAdminPermission() &&
            <ClientField clientId={formData.client_id} ex={ex} />
            }

            {session.hasClientPermission() &&
            <SelectInput source="type" choices={session.hasAdminPermission() ? AVAILABLE_CLIENT_TOKEN_TYPES : AVAILABLE_CLIENT_TOKEN_TYPES_FOR_CLIENT} key="type" label="Type"/>
            }
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
}
