import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDialogContentText from './MuiDialogContentText';
import MuiDialogActions from './MuiDialogActions';
import MuiFilledInput from './MuiFilledInput';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiDialogTitle,
  MuiDialogContentText,
  MuiDialogActions,
  MuiFilledInput
};
