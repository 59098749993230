import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {useLogout, MenuItemLink, useRedirect} from 'react-admin';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import session from '../../../../app/session';
import {TopBarStyles} from '../../../../theme/skin/poc/styles';
import config from "../../../../app/etc/config";
import Session from './Session';
import {useNotify, useTranslate} from "ra-core";
//import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = TopBarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const logout = useLogout();
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();

  if (!session.hasClientUserPermission()) {
    logout();
    return null;
  }

  const handleLogout = () => logout();

  const handleSwitchBack = () => {
    session.switchBack();

    notify(translate('app.user.labels.switch_back_successful', {client_name: session.getClientName()}));
    redirect('/');//dashboard
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'profile-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      onClose={handleMenuClose}
      open={isMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {!session.isActingOnBehalfOf() &&
      <MenuItemLink
        className={classes.root}
        onClick={handleMenuClose}
        primaryText="User Profile"
        to="/user/profile"
      >User Profile</MenuItemLink>
      }

      {session.hasClientPermission() &&
      <MenuItemLink
        className={classes.root}
        onClick={handleMenuClose}
        primaryText="Company Profile"
        to="/company/profile"
      >Company Profile</MenuItemLink>
      }

      {session.hasAdminPermission() && !session.isActingOnBehalfOf() &&
        <MenuItemLink
          className={classes.root}
          onClick={handleMenuClose}
          primaryText="Switch On Behalf Of"
          to="/user/switch"
        >Switch On Behalf Of</MenuItemLink>
      }
      {session.isActingOnBehalfOf() &&
        <MenuItemLink
          className={classes.root}
          onClick={handleSwitchBack}
          primaryText="Switch Back"
          to="/user/switch/back"
        >Switch Back</MenuItemLink>
      }

      <MenuItemLink
        className={classes.root}
        onClick={handleLogout}
        primaryText="Logout"
        to="/"
      >Logout</MenuItemLink>
    </Menu>
  );

  const renderToolBar = (
    <Toolbar>
      <Hidden lgUp>
        <IconButton color="inherit" onClick={onSidebarOpen}><MenuIcon /></IconButton>
      </Hidden>
      <Hidden lgUp>
        <RouterLink className={classes.topBarLogoContainer} to="/">
          <img alt="Logo" className={classes.topBarLogo} src={config.logo.private_img}/>
        </RouterLink>
      </Hidden>
      <Hidden mdDown>
        <RouterLink to="/">
          <img alt="Logo" className={classes.topBarLogo} src={config.logo.private_img}/>
        </RouterLink>
      </Hidden>

      <Hidden mdDown>
        <div className={classes.flexGrow} />
      </Hidden>

      <Session />

      {/*<IconButton color="inherit" title="Notifications">*/}
      {/*  <Badge badgeContent={notifications.length} color="primary" variant="dot">*/}
      {/*    <NotificationsIcon className={classes.topBarIcon} />*/}
      {/*  </Badge>*/}
      {/*</IconButton>*/}

      <IconButton
        aria-controls={menuId}
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
        edge="end"
        onClick={handleProfileMenuOpen}
      >
        <AccountCircle className={classes.topBarIcon} />
      </IconButton>
    </Toolbar>
  );

  return (
    <div>
      <AppBar {...rest} className={clsx(classes.topBarBackground, className)}>
        {renderToolBar}
      </AppBar>
      {renderMenu}
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
