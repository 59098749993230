export const SELECTOR_TIME_CURRENT_MONTH = 'current_month';
export const SELECTOR_TIME_CURRENT_YEAR  = 'current_year';
export const SELECTOR_TIME_LAST_7_DAYS   = 'last_7_days';
export const SELECTOR_TIME_LAST_30_DAYS  = 'last_30_days';
export const SELECTOR_TIME_LAST_YEAR     = 'last_year';

export const TYPE_TIME_DAY = 'day';
export const TYPE_TIME_WEEK = 'week';
export const TYPE_TIME_MONTH = 'month';
export const TYPE_TIME_YEAR = 'year';

export const MODE_AMOUNTS = 'amounts';
export const MODE_TRANSACTIONS = 'transactions';

export const MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const COLORS = {
  all:              'rgba(255, 51, 51, 0.2)',
  transferred_cart: 'rgba(0, 204, 0, 0.2)',
  purchase_order:   'rgba(0, 128, 255, 0.2)',
  invoice:          'rgba(102, 0, 204, 0.2)',
  asn:              'rgba(204, 204, 0, 0.2)',
};
