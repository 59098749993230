import React, { Fragment } from 'react';
import {
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  SelectField, FunctionField
} from 'react-admin';
import { Card, CardContent} from '@mui/material';

import RetryBatchesButton from '../../components/poc/RetryButton';
import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import {StatusField} from '../../components/poc/StatusField';
import {ActionField} from '../../components/poc/ActionField';
import {deletePermission} from './permissions';

import {
  AVAILABLE_BATCH_STATUSES,
  AVAILABLE_BATCH_TYPES,
  AVAILABLE_BATCH_ACTIONS,
  BATCH_STATUS_FAILED,
  BATCH_STATUS_PROCESSING,
  BATCH_STATUS_ERROR
} from './consts';

import session from '../../app/session';
import config from '../../app/etc/config';
import {toOptions} from '../../lib/source';
import {BatchBrief} from './brief';
import {useTranslate} from "ra-core";
import {GridStyles} from "../../theme/skin/poc/styles";
import {formatClientName} from "../../components/poc/Grid/Fields";
import {EnvField} from "../../components/poc/EnvField";

let isRowSelectable = (record) => (record.status === BATCH_STATUS_FAILED || record.status === BATCH_STATUS_PROCESSING);
if (session.hasAdminPermission()) {
  isRowSelectable = (record) => (record.status === BATCH_STATUS_FAILED || record.status === BATCH_STATUS_PROCESSING  || record.status === BATCH_STATUS_ERROR);
}

const HeaderContent = () => {
  const translate = useTranslate();
  const classes = GridStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.note}>
        <div>Note:</div>
        <div>{translate('app.importexport_batch.notes.retry_put_queue_fallback')}</div>
        {session.hasAdminPermission() &&
          <div>{translate('app.importexport_batch.notes.retry_put_queue_onetime')}</div>
        }
      </CardContent>
    </Card>
  );
}

const ImportExportBatchFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id:in" label="ID"/>

    <SelectInput choices={toOptions(AVAILABLE_BATCH_ACTIONS, 'app.importexport_batch.labels.action.')} source="action"/>
    <SelectInput choices={toOptions(AVAILABLE_BATCH_TYPES, 'app.importexport_batch.labels.type.')}  source="type"/>
    <SelectInput choices={toOptions(AVAILABLE_BATCH_STATUSES)} source="status"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <TextInput source="session_id"/>

    <ReferenceInput label="Endpoint" reference="endpoint" source="endpoint_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
      <TextField source="name"/>
    </ReferenceInput>

    <DateInput source="updated_at:min" label="Updated At Min"/>
    <DateInput source="updated_at:max" label="Updated At Max"/>
  </ListFilter>
);

const ImportExportBatchGridActionButtons = props => (
  <>
    <RetryBatchesButton label="app.general.labels.retry_put_queue_fallback" {...props} route="importexport/retry"/>

    {session.hasAdminPermission() &&
      <RetryBatchesButton label="app.general.labels.retry_put_queue_onetime" {...props} route="importexport/retry/onetime"/>
    }
  </>
);

export const ImportExportBatchGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<ImportExportBatchFilter/>}
      resource="importexport_batch"
      perPage={config.pagination.grid}
      sort={{field: 'updated_at', order: 'DESC'}}
      createButton={false}
      rowClick="expand"
      expand={<BatchBrief />}
      bulkActionButtons={<ImportExportBatchGridActionButtons />}
      isRowSelectable={isRowSelectable}
      headerContent={<HeaderContent />}
    >
      <ActionField source="action" label="Action"/>
      <SelectField choices={toOptions(AVAILABLE_BATCH_TYPES, 'app.importexport_batch.labels.type.')} source="type"/>
      <StatusField source="status" label="Status"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <TextField source="session_id" sortable={false}/>
      <EnvField source="env"/>

      <ReferenceField label="Endpoint" reference="endpoint" source="endpoint_id" sortable={false}>
        <TextField source="name"/>
      </ReferenceField>

      <DateField source="updated_at" label="Updated At" showTime={true}/>

      <Actions delete={deletePermission} />
    </ListWithLayout>
  )
};
