import React from 'react';
import {NumberField} from 'react-admin';
import {useTranslate} from 'ra-core';
import {Table, TableBody, TableCell, TableRow, Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import styles from "../styles";

const useStyles = makeStyles(styles);

const SsfElement = (props) => {
  const {type, name, index, value} = props;
  const classes = useStyles();
  const translate = useTranslate();

  if (typeof value === 'undefined') {
    return null;
  }

  let field = null;
  if (type === 'attributes' || type === 'options') {
    let val = Array.isArray(value.value) ? value.value.join(', ') : value.value.toString();
    return (
      <TableRow>
        <TableCell className={classes.ssfCellCaption}>{translate(value.code)}:</TableCell>
        <TableCell className={classes.ssfCell}>{val}</TableCell>
      </TableRow>
    );
  }

  if (type === 'amounts') {
    const options = value && value.currency ? {style: 'currency', currency: value.currency} : {};

    field = <NumberField source="amount" record={value} options={options}/>;
  }  else if (type === 'dates') {
    field = new Date(value).toLocaleDateString();
  } else if (type === 'flags') {
    field = !!value ? 'Yes' : 'No';
  } else {
    field = typeof value !== 'undefined' ? value.toString() : '';
  }

  return (
    <TableRow>
      <TableCell className={classes.ssfCellCaption}>{translate(name)}:</TableCell>
      <TableCell className={classes.ssfCell}>{field}</TableCell>
    </TableRow>
  );
};

export const Ssf = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {record, type, container, header} = props;

  if (!record[type]) {
    return null;
  }

  const data = record[type];

  let rows = [];
  if (Array.isArray(data)) {
    rows = data.map((v, i) => <SsfElement type={type} index={i} value={v} />);
  } else {
    rows = Object.keys(data).map(name => <SsfElement type={type} name={name} value={data[name]} />);
  }

  if (!rows.length) {
    return null;
  }

  let res = (
    <>
      {header &&
      <Typography variant="h5">
        {translate('app.document.labels.' + type)}
      </Typography>
      }
      <Table className={classes.ssfContainer} size="small" padding="none">
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  );

  if (container) {
    res = <Box>{res}</Box>
  }

  return res;
};



