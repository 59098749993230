import React from 'react';
import {
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput, FunctionField
} from 'react-admin';
import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import {deletePermission, editPermission} from './permissions';
import {AVAILABLE_CLIENT_TOKEN_TYPES} from './consts';

import session from '../../app/session';
import config from "../../app/etc/config";
import {formatClientName} from "../../components/poc/Grid/Fields";

const TokenFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="code:in" label="Code"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <SelectInput
      choices={AVAILABLE_CLIENT_TOKEN_TYPES}
      source="type"
    />
    <DateInput source="created_at"/>
    <DateInput source="expires_at"/>
  </ListFilter>
);

export const TokenGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<TokenFilter/>}
      resource="token"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <TextField source="code"/>
      <TextField source="type"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <DateField source="expires_at"/>

      <Actions delete={deletePermission} edit={editPermission}/>
    </ListWithLayout>
  )
}
