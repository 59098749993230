import {DashboardStyles} from "../../../theme/skin/poc/styles";

import React from 'react';
import hookIntoProps from 'hook-into-props'
import {useDataProvider, useTranslate, useNotify} from 'ra-core';
import DocsSummaryFiltersAbstract from './DocsSummaryFiltersAbstract';

import {
  SELECTOR_TIME_CURRENT_MONTH,
  SELECTOR_TIME_CURRENT_YEAR,
  SELECTOR_TIME_LAST_30_DAYS,
  SELECTOR_TIME_LAST_7_DAYS,
  SELECTOR_TIME_LAST_YEAR
} from "../consts";

class DocsSummaryGraphFilters extends DocsSummaryFiltersAbstract {
  constructor(props) {
    super(props);
    //
    this.timeOptions = [
      SELECTOR_TIME_LAST_7_DAYS,
      SELECTOR_TIME_LAST_30_DAYS,
      SELECTOR_TIME_LAST_YEAR,
      SELECTOR_TIME_CURRENT_MONTH,
      SELECTOR_TIME_CURRENT_YEAR,
    ];
  }
}

/**                                                              *
 *  ------------------------------------------------------------ *
 *                                                               */
const useHooks = () => ({
  translate: useTranslate(),
  styles: DashboardStyles(),
  dataProvider: useDataProvider(),
  notify: useNotify(),
});

export default hookIntoProps(useHooks)(DocsSummaryGraphFilters)
