import React from 'react';
import {
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  NumberField,
  SelectInput,
  DateInput,
  ReferenceInput, FunctionField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';

import {GridStyles} from '../../theme/skin/poc/styles';
import {deletePermission} from "./permissions";

import session from '../../app/session';
import config from "../../app/etc/config";
import {formatClientName} from "../../components/poc/Grid/Fields";

const LicenseFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="domain:like" label="Domain"/>
    <TextInput source="connections_number:min" label="Min Connections Number"/>
    <TextInput source="connections_number:max" label="Max Connections Number"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <DateInput source="expires_at" label="Expires At"/>
    <DateInput source="created_at" label="Created At"/>
  </ListFilter>
);

export const LicenseGrid = props => {
  const classes = GridStyles();

  let bulkActionButtons = null;
  if (!session.hasAdminPermission()) {
    bulkActionButtons = {bulkActionButtons:false};
  }

  let createButton = {createButton:false};
  if (session.hasAdminPermission()) {
    createButton = {createButton:true};
  }
  return (
    <ListWithLayout
      {...props}
      {...bulkActionButtons}
      {...createButton}
      filters={<LicenseFilter/>}
      resource="license"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
    >
      <TextField source="code" cellClassName={classes.licenseCodeCell}/>
      <TextField source="domain"/>
      <NumberField source="connections_number" cellClassName={classes.licenseConnectionsNumberCell} headerClassName={classes.licenseConnectionsNumberCell} label="Connections Number"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <DateField source="expires_at" label="Expires At"/>
      <DateField source="created_at" label="Created At"/>

      <Actions delete={deletePermission}/>
    </ListWithLayout>
  )
}
