import React from "react";
import {SOURCE_EMAIL} from "../consts";
import {TemplateField} from "../../../components/poc/Form/Fields";
/**
 * it's a draft
 * */
export const skipHandling = (source) => {
  return (!source || source !== SOURCE_EMAIL);
};

export default ({ formData, ex }) => {
  const {source} = formData;

  if (skipHandling(source)) {
    return <></>;
  }

  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.endpoint.labels.additional_info')}</div>

      <TemplateField formData={formData} ex={ex} source="templates.default_id"/>
    </>
  );
};
