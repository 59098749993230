import React from 'react';
import {useTranslate} from 'ra-core';
import {TextInput, PasswordInput} from 'react-admin';

export default () => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        source="authorization.connector.web_url"
        label="app.general.labels.web_url"
        helperText={translate('app.endpoint.notes.web_url')}
      />
      <PasswordInput
        source="authorization.connector.jwt_secret"
        label="app.general.labels.jwt_secret"
        helperText={translate('app.endpoint.notes.jwt_secret')}
      />
    </>
  );
};
