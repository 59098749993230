import React from 'react';
import {STATUS_ACTIVE} from '../../../app/etc/consts';

export const formatClientName = (row) => {
  if (row.status != STATUS_ACTIVE) {
    return `${row.name}  (${row.status})`;
  } else {
    return `${row.name}`;
  }
};
