import React, {useState} from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  SelectInput,
  useRecordContext,
  useResourceContext
} from 'react-admin';
import {useTranslate} from 'ra-core';

import session from '../../app/session';
import {deletePermission, editPermission} from './permissions';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField, ConnectionField} from '../../components/poc/Form/Fields';
import {AVAILABLE_BASIC_STATUSES, TYPE_ASN_REQUEST, TYPE_INVOICE_REQUEST, TYPE_ORDER_CONFIRMATION_REQUEST} from '../../app/etc/consts';

const validate = (values) => {
  const errors = {};

  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.status) {
    errors.status = ['Status is required'];
  }

  return errors
};

export const BuyerCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.buyer.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

export const BuyerEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit{...props} className={classes.root} successMessage={translate('app.buyer.updated')}>
      {EditForm(props, 'edit')}
    </Edit>
  )
};

export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();
  const [loading, setLoading] = useState(false);

  const disabled = !!loading;

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar
        validate={validate}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />}
    >
      <Actions
        className={classes.actions}
        listUrl="/buyers"
        title={translate('app.buyer.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
        saveConditions={mode === 'edit' ? editPermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            <FormSectionClientSettings formData={formData} disabled={disabled} ex={ex} mode={mode} />
            <FormSectionCommonSettings formData={formData} disabled={disabled} ex={ex} mode={mode} />
            <FormSectionInvoiceSettings formData={formData} disabled={disabled} ex={ex} mode={mode} />
            <FormSectionAsnSettings formData={formData} disabled={disabled} ex={ex} mode={mode} />
            <FormSectionOrderConfirmationSettings formData={formData} disabled={disabled} ex={ex} mode={mode} />
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
};


const FormSectionClientSettings = ({ formData, ex, disabled }) => {
  if (!session.hasAdminPermission()) {
    return null;
  }

  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.relations')}</div>
      <ClientField clientId={formData.client_id} ex={ex} disabled={disabled}/>
    </>
  );
};

const FormSectionCommonSettings = ({ formData, ex, disabled, mode }) => {
  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.common_info')}</div>
      <SelectInput source="status" disabled={disabled} choices={AVAILABLE_BASIC_STATUSES}  label={ex.translate('app.general.labels.status')} required />

      {mode !== 'create' &&
        <TextInput source="code" disabled={true} label={ex.translate('app.general.labels.guid')} />
      }

      <TextInput source="name" disabled={disabled} label={ex.translate('app.general.labels.name')} required />
      <TextInput source="description" disabled={disabled} label={ex.translate('app.general.labels.description')} multiline/>
    </>
  );
};

const FormSectionInvoiceSettings = ({ formData, ex, disabled }) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const helpText = translate('app.buyer.hints.default_invoice_test_connection');

  return (
    <>
      <div className={classes.formSection}>{ex.translate('app.buyer.labels.invoice_settings_info')}</div>
      <ConnectionField formData={formData} type={TYPE_INVOICE_REQUEST} ex={ex} disabled={disabled} required={false} allowEmpty={true} source="connections.invoice_prod_connection_id" label="app.buyer.labels.default_invoice_prod_connection"/>
      <ConnectionField formData={formData} type={TYPE_INVOICE_REQUEST} ex={ex} disabled={disabled} required={false} allowEmpty={true} source="connections.invoice_test_connection_id" label="app.buyer.labels.default_invoice_test_connection"/>
      <div className={classes.helperText}>{helpText}</div>
    </>
  );
};

const FormSectionAsnSettings = ({ formData, ex, disabled }) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const helpText = translate('app.buyer.hints.default_asn_test_connection');

  return (
    <>
      <div className={classes.formSection}>{ex.translate('app.buyer.labels.asn_settings_info')}</div>
      <ConnectionField formData={formData} type={TYPE_ASN_REQUEST} ex={ex} disabled={disabled} required={false} allowEmpty={true} source="connections.asn_prod_connection_id" label="app.buyer.labels.default_asn_prod_connection"/>
      <ConnectionField formData={formData} type={TYPE_ASN_REQUEST} ex={ex} disabled={disabled} required={false} allowEmpty={true} source="connections.asn_test_connection_id" label="app.buyer.labels.default_asn_test_connection"/>
      <div className={classes.helperText}>{helpText}</div>
    </>
  );
};

const FormSectionOrderConfirmationSettings = ({ formData, ex, disabled }) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const helpText = translate('app.buyer.hints.default_order_confirmation_test_connection');

  return (
    <>
      <div className={classes.formSection}>{ex.translate('app.buyer.labels.order_confirmation_settings_info')}</div>
      <ConnectionField formData={formData} type={TYPE_ORDER_CONFIRMATION_REQUEST} ex={ex} disabled={disabled} required={false} allowEmpty={true} source="connections.order_confirmation_prod_connection_id" label="app.buyer.labels.default_order_confirmation_prod_connection"/>
      <ConnectionField formData={formData} type={TYPE_ORDER_CONFIRMATION_REQUEST} ex={ex} disabled={disabled} required={false} allowEmpty={true} source="connections.order_confirmation_test_connection_id" label="app.buyer.labels.default_order_confirmation_test_connection"/>
      <div className={classes.helperText}>{helpText}</div>
    </>
  );
};
