import { makeStyles } from '@mui/styles';
import {COLORS as REPORT_COLORS} from "../../../view/report/consts";

export const TopBarStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.dark
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  topBarBackground: {
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'inherit',
    backgroundImage: 'url(/images/theme/nav-background.png)',
    boxShadow: 'none'
  },
  topBarLogoContainer: {
    flexGrow: 1,
    textAlign: 'center'
  },
  topBarLogo: {
    height: '64px'
  },
  topBarIcon: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.highlight,
    }
  },
  topBarSwitcherContainer: {
  },
  topBarSwitcherSession: {
  },
  topBarSwitcherSessionClient: {
    fontWeight: 'bold'
  },
  topBarSwitcherSessionUser: {
    fontStyle: 'italic'
  },
  topBarSwitcherSessionRole: {
  },
}));

export const GridStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'none'
  },
  content: {
    padding: 0
  },

  header: {
    '& span': {
      fontSize: '20pt',
      color: theme.palette.black
    }
  },

  list: {
    margin: '0 24px 24px 24px',
    '& > div > div.MuiPaper-root': {
      overflow: 'auto',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    }
  },
  row: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  grid: {
    marginTop: '5px',
    '& thead tr th': {
      paddingBottom: '9px'
    },
    '& tbody tr td:hover a:hover': {
      backgroundColor: 'transparent'
    },
    '& tbody tr td:hover a span': {
      color: theme.palette.primary.highlight
    },
    '& .RaBulkActionsToolbar-toolbar': {
      top: '48px',
    }
  },
  licenseCodeCell: {
    width: '30%',
    wordBreak: 'break-word'
  },
  licenseConnectionsNumberCell: {
    textAlign: 'left'
  },
  actionItem: {
    width: '100%',
  },
  note: {
    fontStyle: 'italic'
  }
}));

export const EmptyStyles = makeStyles(theme => ({
  message: {
    textAlign: 'center',
    opacity: 0.5,
    margin: '0 1em',
  },
  icon: {
    width: '9em',
    height: '9em',
  },
  toolbar: {
    textAlign: 'center',
    marginTop: '2em',
  }
}));

export const ViewStyles = makeStyles(theme => ({
  root: {
    '& .makeStyles-fieldWithLabel-35': {
      '& pre': {
        overflowY: 'scroll'
      },
    },
    width: '100%',
    '& > div': {
      margin: '0'
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.highlight
    },
    '& .MuiFormControl-root': {
      width: '100%',
      '& > ul > li:not(:first-child)': {
        marginTop: '10px'
      }
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '0'
    },
    '& .MuiFilledInput-root, & .MuiTextField-root, & .MuiAutocomplete-root, & .RaSimpleFormIterator-form': {
      width: '60%', // @todo: hot-fix: show only 1 field per row
      '@media (max-width: 768px)': {
        width: '100%'
      },
      backgroundColor: theme.palette.white,
      '&:before, &:after': {
        display: 'none'
      }
    },
    '& .MuiTextField-root.MuiFormControl-fullWidth': {
      width: '100%',
    },
    '& .MuiFilledInput-multiline': {
      padding: '0'
    },
    ' & .MuiInputBase-fullWidth': {
      width: '100% !important'
    },
    '& .grouped-input-title': {
      fontSize: '13pt',
      textTransform: 'uppercase',
      color: theme.palette.black,
      margin: '15px 0'
    },
    '& section .grouped-input-title': {
      display: 'none'
    },
    '& .MuiInputLabel-root': {
      position: 'static',
      transform: 'none !important',
      marginBottom: '10px'
    },
    '& .MuiFilledInput-input': {
      outline: `1px solid ${theme.palette.primary.lighter}`,
      padding: '10px 12px',
      transition: 'all .1s'
    },
    '& .Mui-focused': {
      '& .MuiFilledInput-input': {
        outlineWidth: '3px',
      },
      '& .MuiSelect-select': {
        backgroundColor: theme.palette.white,
      }
    },
    '& .MuiInputBase-root legend span': {
      display: 'none',
    },
    '& .RaSimpleFormIterator-list .RaSimpleFormIterator-line': {
      borderBottom: 'none',
    },
    '& .RaSimpleFormIterator-form .MuiTextField-root': {
      width: '100%',
    },

    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },

    '& .SpreadsheetGridContainer .SpreadsheetGrid .SpreadsheetGrid__cell': {
      // minWidth: '120px',
      // height: '28px',
    },

    // '& .SpreadsheetGridContainer .SpreadsheetGrid .SpreadsheetGrid__cell span, & .SpreadsheetGridContainer .SpreadsheetGrid .SpreadsheetGrid__cell input': {
    //   height: '28px',
    //   width: '100%',
    //   padding: '4px',
    // }
  },

  headerTopToolbar: {
    textAlign: "left",
    width: "100%"
  },
  actions: {
    width: '100% !important',
    justifyContent: 'space-between',
    '& .actions-title': {
      marginRight: 'auto',
      textTransform: 'uppercase',
      color: theme.palette.black,
      fontSize: '14pt'
    }
  },
  groupedInput: {
    margin: '200px'
  },
  disabledInput: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.white
    },
    '& label, & label span, & .MuiInputBase-root input': {
      color: theme.palette.black
    }
  },

  form: {
    '& > div > div.ra-input > div.MuiFormControl-root > label.Mui-focused': {
      color: theme.palette.primary.highlight
    }
  },

  fieldWithLabel: {
    margin: '10px 0',
    '& > label, & div > label': {
      fontSize: '14pt',
      textTransform: 'uppercase',
      color: theme.palette.black,
      marginBottom: '5px !important'
    },
    '& > div > span, & > div > div > span': {
      fontSize: '10pt',
      color: theme.palette.black
    },
    '& > div > a > span, & > div > div > a > span': {
      fontSize: '10pt'
    },
    '& > div > a > span:hover, & > div > div > a > span:hover': {
      color: theme.palette.primary.highlight
    },
    '& > div': {
      padding: '0'
    }
  },
  label: {
    fontSize: '10pt',
    textTransform: 'uppercase',
    color: theme.palette.black,
    '& > label': {
      display: 'none'
    }
  },
  arrayInput: {
    marginTop: 0
  },
  link: {
    color: theme.palette.primary.dark,
    fontSize: '10pt',
    '&:hover': {
      color: theme.palette.primary.highlight
    }
  },
  link_view: {
    color: theme.palette.primary.dark,
    fontSize: '12pt',
    '&:hover': {
      color: theme.palette.primary.highlight
    },
    textDecoration: 'underline',
    marginBottom: '15px',
    marginTop: '0',
    display: 'block'
  },
  link_grid: {
    color: theme.palette.primary.blue,
    '&:hover': {
      color: theme.palette.primary.highlight,
      textDecoration: 'underline',
    },
  },
  helperText: {
    color: theme.palette.primary.dark,
    fontSize: '12pt'
  },
  topPadding: {
    paddingTop: '10px'
  },

  formSection: {
    paddingTop: '20px',
    paddingBottom: '10px',
    fontWeight: 700,
    fontSize: '14pt',
    color: 'gray',
  },

  dataTable: {
    marginBottom: '25px',
  },

  noteSection: {
    color: 'silver'
  },

  noteText: {
    color: '#546e7a'
  },

  cellNote: {
    fontSize: '10pt',
  },

  cellExchangeBody: {
    fontSize: '9pt',
    width: '100%',
    minHeight: '200px',
  },

  fieldNotes: {
    paddingBottom: '20px',
    fontSize: '10pt',
    color: '#c4c4c4',
  },
  formSimpleList: {
    '& dt': {
      position: 'static',
      marginBottom: '10px',
      marginTop: '18px'
    },
    '& dd': {
      position: 'static',
      marginBottom: '10px'
    },
    '& .marginTop0': {
      marginTop: '0'
    }
  },

  formIconButton: {
    borderRadius: 0,
    '& span': {
      display: 'inline-block',
      verticalAlign: 'bottom'
    }
  },

  transactionMessageSmall: {
    width: '100%',
    minHeight: '100px',
    fontSize: '9pt'
  },
  transactionMessageMedium: {
    width: '100%',
    minHeight: '400px',
    fontSize: '9pt'
  },
  transactionMessage: {
    width: '100%',
    minHeight: '700px',
  }
}));

export const ActionStyles = makeStyles(theme => ({
  title: {
    marginRight: 'auto',
    textTransform: 'uppercase',
    color: theme.palette.black,
    fontSize: '14pt'
  },
  button: {
    '&:hover': {
      color: theme.palette.primary.highlight,
      backgroundColor: theme.palette.white
    }
  }
}));

export const ToolbarStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.white,
    paddingLeft: '16px',
    '& button:hover': {
      backgroundColor: theme.palette.primary.highlight
    }
  }
}));

export const PublicStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    //background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 400,
    maxWidth: 600,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.primary.dark,
    height: '90px',
    width: '90px',
  },
  iconImg: {
    height: '80px',
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
    width: '100%'
  },
  actions: {
    padding: '0 1em 1em 1em',
    justifyContent: 'space-between'
  },
  link: {
    color: theme.palette.primary.dark,
    fontSize: '10pt',
    '&:hover': {
      color: theme.palette.primary.highlight
    }
  },
  recaptcha: {
    textAlign: 'center'
  }
}));

export const DashboardStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  positiveDifferenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  positiveDifferenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  graphLabel: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  graphHint: {
    width: '100%',
    color: 'silver',
    fontSize: '10px',
    textAlign: 'center',
    paddingTop: '3px',
    paddingBottom: '5px',
  },
  graphFilterContainer: {
    width: '100%',
    marginBottom: '20px',
  },
  graphBarContainer: {
    width: '100%',
    marginBottom: '40px',
  },

  graphLinearContainer: {
    marginBottom: '40px',
    width: '100%',
    border: '1px solid gray',
    padding: '10px',
    paddingTop: '20px',
  },
  chartContainer: {
    height: 500,
    width: '100%',
    //border: '1px solid red',
  },

  actions: {
    justifyContent: 'flex-end'
  },
  formControl: {
    flexDirection: 'row',
    '& .MuiInput-input': {
      paddingLeft: '12px'
    },
    '& .MuiInput-underline:hover .MuiSelect-icon': {
      color: theme.palette.primary.highlight
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: theme.palette.primary.highlight + ' !important'
    },
    '& .MuiInput-underline:hover:after': {
      borderColor: theme.palette.primary.highlight
    }
  },
  reportFilterSelect: {
    marginLeft: '30px',
  },
  reportFilterLabel: {
    lineHeight: '30px',
    fontSize: '18px',
    fontWeight: 'bold',
  },

  barIndicator: {
    width: '35px',
    height: '10px',
    background: '#fff',
    float: 'left',
    display: 'block',
    marginRight: '10px',
  },
  graphBarItemUnknown: {
  },
  graphBarItemAll: {
    '& .indicator' : {
      background: REPORT_COLORS.all,
      boxShadow: '3px 3px 3px 3px ' + REPORT_COLORS.all,
    }
  },
  graphBarItemTransferredCart: {
    '& .indicator' : {
      background: REPORT_COLORS.transferred_cart,
      boxShadow: '3px 3px 3px 3px ' + REPORT_COLORS.transferred_cart,
    }
  },
  graphBarItemPurchaseOrder: {
    '& .indicator' : {
      background: REPORT_COLORS.purchase_order,
      boxShadow: '3px 3px 3px 3px ' + REPORT_COLORS.purchase_order,
    }
  },
  graphBarItemInvoice: {
    '& .indicator' : {
      background: REPORT_COLORS.invoice,
      boxShadow: '3px 3px 3px 3px ' + REPORT_COLORS.invoice,
    }
  },
  graphBarItemAsn: {
    '& .indicator' : {
      background: REPORT_COLORS.asn,
      boxShadow: '3px 3px 3px 3px ' + REPORT_COLORS.asn,
    }
  },
  dashboard: {
    padding: '10px',
  },
  dashboardCaption: {
    textAlign: 'center',
    marginBottom: '10px',
  },
}));
