import React from 'react';
import {Toolbar, useRecordContext} from 'react-admin';
import {ToolbarStyles} from '../../../theme/skin/poc/styles';
import SaveButton from './components/SaveButton';
import {useFormContext} from 'react-hook-form';
import {ClientFieldTransform} from "../Form/Fields";

export default (props) => {
  const record = useRecordContext();
  const formContext = useFormContext();

  let show = true;
  if (typeof props.editConditions === 'function') {
    show = props.editConditions(record);
  }

  //@See: POC-2829 - fix client_id from autocomplete
  const transform = (data = {}) => {
    if (typeof props.transform === 'function') {
      data = props.transform(data);
    }
    return ClientFieldTransform(formContext, data);
  }

  const classes = ToolbarStyles();
  return (
    <Toolbar className={classes.toolbar}>
      {show &&
      <SaveButton {...props} transform={transform} />}
    </Toolbar>
  )
};
