import React from 'react';
import {
  TextField,
  ReferenceField,
  SelectField,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  BooleanInput,
  CreateButton, FunctionField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';
import MenuDropDown from '../../components/poc/Menu/DropDown';
import {StatusField} from '../../components/poc/StatusField';
import {deletePermission} from './permissions';

import {AVAILABLE_CONNECTION_ENVS, AVAILABLE_CONNECTION_FORMATS, AVAILABLE_CONNECTION_TYPES} from './consts';
import {
  AVAILABLE_BASIC_STATUSES,
  TYPE_SETUP_REQUEST,
  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_INVOICE_REQUEST,
  TYPE_ASN_REQUEST,
  TYPE_ORDER_CONFIRMATION_REQUEST,
} from '../../app/etc/consts';

import session from '../../app/session';
import config from '../../app/etc/config';
import {toOptions} from '../../lib/source';
import {formatClientName} from '../../components/poc/Grid/Fields';
import {EnvField} from "../../components/poc/EnvField";

const ConnectionFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id:in" label="ID"/>
    <TextInput source="name:like" label="Name"/>
    <TextInput source="code"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <ReferenceInput
      label="Buyer"
      reference="buyer"
      source="buyer_id"
      perPage={config.pagination.associated}
      sort={{field: 'name', order: 'ASC'}}
    >
      <SelectInput source="name"/>
    </ReferenceInput>

    <SelectInput
      choices={toOptions(AVAILABLE_CONNECTION_TYPES, 'app.general.labels.')}
      source="type"
    />
    <SelectInput
      choices={toOptions(AVAILABLE_CONNECTION_FORMATS, 'app.general.labels.')}
      source="format"
    />
    <SelectInput
      choices={AVAILABLE_BASIC_STATUSES}
      source="status"
    />

    <SelectInput
      choices={toOptions(AVAILABLE_CONNECTION_ENVS, 'app.connection.labels.envs.')}
      source="env"
    />

    <BooleanInput source="is_default"/>
    <DateInput source="created_at"/>
    <DateInput source="updated_at"/>
  </ListFilter>
);

export const ConnectionGrid = (props) => {
  const basePath = '/connection';

  const defautls = {format: 'cXML', status: 'active'};
  const paramsTC = {
    pathname: `${basePath}/create`,
    search: `?${JSON.stringify({
      type: TYPE_SETUP_REQUEST, 
      chooser_target: 'default', 
      customer: {allow_guest: 1},
      cart_settings: {encoding: 'base64', default_currency: 'USD'},
      ...defautls
    })}`,
  };

  const paramsPO = {
    pathname: `${basePath}/create`,
    search: `?${JSON.stringify({
      type: TYPE_ORDER_RECEIPT_REQUEST,
      ...defautls
    })}`,
  };

  const paramsINV = {
    pathname: `${basePath}/create`,
    search: `?${JSON.stringify({
      type: TYPE_INVOICE_REQUEST,
      ...defautls
    })}`,
  };

  const paramsASN = {
    pathname: `${basePath}/create`,
    search: `?${JSON.stringify({
      type: TYPE_ASN_REQUEST,
      ...defautls
    })}`,
  };

  const paramsOrderConfirmation = {
    pathname: `${basePath}/create`,
    search: `?${JSON.stringify({
      type: TYPE_ORDER_CONFIRMATION_REQUEST,
      ...defautls
    })}`,
  };

  return (
    <ListWithLayout
      {...props}
      filters={<ConnectionFilter/>}
      resource="connection"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      actions={<MenuDropDown {...props} label="app.connection.create" options={[
        <CreateButton to={paramsTC} label="app.connection.labels.transferred_cart"/>,
        <CreateButton to={paramsPO} label="app.connection.labels.purchase_order"/>,
        <CreateButton to={paramsINV} label="app.connection.labels.invoice"/>,
        <CreateButton to={paramsASN} label="app.connection.labels.asn"/>,
        <CreateButton to={paramsOrderConfirmation} label="app.connection.labels.order_confirmation"/>,
      ]}
      />}
    >
      <TextField source="id"/>
      <TextField source="name"/>
      <StatusField source="status"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <ReferenceField label="Buyer" reference="buyer" sortable={false} source="buyer_id">
        <TextField source="name"/>
      </ReferenceField>

      <SelectField
        choices={toOptions(AVAILABLE_CONNECTION_TYPES, 'app.connection.labels.')}
        source="type"
      />
      <SelectField
        choices={toOptions(AVAILABLE_CONNECTION_FORMATS)}
        source="format"
      />

      <EnvField source="env"/>

      <ReferenceField label="Accept Credentials" reference="credential" source="auth.request_credential_id" sortable={false}>
        <TextField source="name"/>
      </ReferenceField>

      <ReferenceField label="Export Credentials" reference="credential" source="auth.document_credential_id" sortable={false}>
        <TextField source="name"/>
      </ReferenceField>

      <Actions edit={true} delete={deletePermission}/>
    </ListWithLayout>
  )
}
