import React from 'react';
import config from '../../../app/etc/config';
import {PublicStyles} from "../../../theme/skin/poc/styles";
import {useTranslate} from "ra-core";
import { Card } from '@mui/material';
import { PublicFormIcon } from "../Form/Fields";

const Index = () => {
  const translate = useTranslate();
  const classes = PublicStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <PublicFormIcon />
        </div>

        <div className={classes.form}>
          <div>
            <h2>{translate('app.version', {version: config.version})}</h2>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Index;
