import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer
} from 'react-admin';
import { useTranslate } from 'ra-core';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField} from '../../components/poc/Form/Fields';

import {AVAILABLE_BASIC_STATUSES} from '../../app/etc/consts';

import {duplicatePermission, deletePermission, editPermission} from './permissions';
import session from '../../app/session';


const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.client_id && session.hasAdminPermission()) {
    errors.client_id = ['Client is required'];
  }
  return errors
};

export const TemplateCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.template.created')}>
      {TemplateForm(props, 'create')}
    </Create>
  )
};

export const TemplateEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit {...props} className={classes.root} successMessage={translate('app.template.updated')}>
      {TemplateForm(props, 'edit')}
    </Edit>
  )
};

export const TemplateForm = (props, mode = 'create') => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const helpText = translate('app.template.labels.notes.help_text');

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
  <SimpleForm
    id="viewForm"
    toolbar={<Toolbar validate={validate} deleteConditions={mode === 'edit' ? deletePermission : false} editConditions={editPermission}/>}
  >
    <Actions
      className={classes.actions}
      listUrl="/templates"
      title={translate('app.template.' + mode)}
      deleteConditions={mode === 'edit' ? deletePermission : false}
      duplicateConditions={mode === 'edit' ? duplicatePermission : false}
    />

    <FormDataConsumer>
      {({formData}) =>
        <>

          <TextInput source="name" required />

          {mode !== 'create' &&
          <TextInput source="code" disabled={true} />
          }

          {session.hasAdminPermission() &&
          <ClientField clientId={formData.client_id} ex={ex} />
          }

          <SelectInput source="status"  choices={AVAILABLE_BASIC_STATUSES} required />

          {session.hasAdminPermission() &&
          <BooleanInput parse={v => !!v ? 1 : 0} source="is_shared"/>
          }

          <TextInput source="subject" />
          <TextInput source="body" key="body" multiline name="body" fullWidth required />
          <div className={classes.helperText}>{helpText}</div>
        </>
      }
    </FormDataConsumer>
  </SimpleForm>
  )
};
