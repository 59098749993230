// https://marmelab.com/react-admin/Datagrid.html#bulkactionbuttons
import React, {Fragment, useState} from 'react';
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDataProvider,
  Confirm,
} from 'react-admin';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useTranslate} from 'ra-core';

import session from '../../../app/session';
import {Typography} from '@mui/material';
import {EXPORTABLE_TYPES} from '../consts';

const MassRoutingStatusUpdateContent = ({selectedIds, routing_status}) => {
  const cnt = selectedIds && selectedIds.length ? selectedIds.length : 0;
  const resourceName = cnt > 1 ? 'documents' : 'document';

  return (
    <>
      <Typography color="textSecondary" variant="h2" gutterBottom>
        Change Routing Status to <b>{routing_status}</b> for the selected {cnt} {resourceName} ?
      </Typography>
    </>
  );
};

const MassRoutingStatusUpdateButton = ({resource, selectedIds, label, routing_status, route = null}) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);


  const resourcePath = route ? route : resource + '/routing-status';
  const updateMany = () => {

    setLoading(true);
    dataProvider
      .updateMany(resourcePath, {ids: selectedIds, data: {routing_status: routing_status}})
      .then(() => {
        notify(translate('app.' + resource + '.label') + ' updated');
        refresh();
        unselectAll(resource);
        setLoading(false);
      })
      .catch(error => {
        // failure side effects go here
        const msg = error.message ? error.message : 'An Error Happened';

        notify(translate('app.' + resource + '.label') + ` update error: ${msg}`, 'warning');
        setLoading(false);
      })
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    updateMany();
  };

  return (
    <>
      <Button disabled={loading} onClick={handleClick} label={label} >
        <ArrowRightIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Change Routing Status"
        content={<MassRoutingStatusUpdateContent selectedIds={selectedIds} resource={resource} routing_status={routing_status} />}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};


export const DocumentGridActionButtons = (props) => (
  <Fragment>
    <MassRoutingStatusUpdateButton label="Mark COMPLETE" routing_status="complete" {...props} />
    <MassRoutingStatusUpdateButton label="Mark FAILED" routing_status="failed"  {...props} />
  </Fragment>
);

export const isDocumentGridRowSelectable = (row) => {
  return (session.hasAdminPermission() && (EXPORTABLE_TYPES.indexOf(row.type) !== -1));
};
