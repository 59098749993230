/**
 * https://rclayton.silvrback.com/custom-errors-in-node-js
 * */
export class ApplicationError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
        //Alert.captureStackTrace(this, this.constructor);
    }
}

export class LocalizedError extends ApplicationError {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}

export class AuthorizationError extends LocalizedError {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}
