import React from "react";
import {SelectInput} from "react-admin";
import {useTranslate} from "ra-core";

import {toOptions} from "../../../lib/source";
import {AUTHORIZATION_METHODS_REGISTRY} from "../consts";

import FormAuthorizationMethodsSection from "./authorization/methods";

export default ({ex, formData}) => {
  const translate = useTranslate();
  const {source} = formData;

  let opts = [];
  if (!!source) {
    opts = toOptions(AUTHORIZATION_METHODS_REGISTRY[source], 'app.endpoint.labels.authorization.methods.');
  }

  return (
    <>
      <div className={ex.classes.formSection}>{translate('app.general.labels.authorization_and_extra')}</div>

      <SelectInput source="authorization.method" choices={opts} label="app.endpoint.labels.authorization.method" required allowEmpty />

      <FormAuthorizationMethodsSection ex={ex} formData={formData} />
    </>
  );
};

