import * as React from "react";
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDataProvider,
} from 'react-admin';
import {AddToQueue} from '@mui/icons-material';
import {useTranslate} from "ra-core";
import {useState} from "react";

const RetryBatchesButton = ({resource, selectedIds, label, route = null}) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const resourcePath = !!route ? route : resource + '/retry';
  const updateMany = () => {
    dataProvider
      .updateMany(resourcePath, {ids: selectedIds})
      .then(() => {
        notify(translate('app.' + resource + '.label') + ' updated');
        refresh();
        unselectAll(resource);
        setLoading(false);
      })
      .catch(error => {
        notify(translate('app.' + resource + '.label') + ` retry error: ${error.message}`, 'warning');
        setLoading(false);
      })
  };

  return (
    <Button disabled={loading} onClick={updateMany} label={label} >
      <AddToQueue />
    </Button>
  );
};

export default RetryBatchesButton;
