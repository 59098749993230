import React from 'react';

/**
 * @See: https://github.com/reactchartjs/react-chartjs-2/tree/master/example/src/charts
 * */
export default class SummaryAbstract extends React.Component {
  constructor(props) {
    super(props);
    //
    this._ = props.translate;
    this.css = props.styles;
    //
    this.getRecords = props.getRecords;
    this.getTime = props.getTime;
  }

  getDefaultData() {
    return [];
  }

  render() {
    return (<>{this.summarize().map(s => this.item(s))}</>);
  }

  item(row) {
    return null;
  }

  summarize() {
    return this.getRecords().reduce(
      (accumulator, row) => this.summarizeRow(accumulator, row), this.getDefaultData()
    );
  }

  summarizeRow(accumulator, row) {
    return accumulator;
  }
}
