export default {
  app: {
    version: 'Version: %{version}',
    auth: {
      email: 'Email',
      forgot_password: 'Forgot Password',
      login_title: 'Sign In',
      register: 'Register Account',
      register_button: 'Register Me',
      registration_successful: 'Registration Successful',
      back_to_login: 'Back to Sign In',
      back_to_reset: 'Back to Forgot Password',
      send_confirmation_code: 'Get Confirmation Code',

      register_title: 'Register Account',
      reset_password_request_title: 'Reset Password Request',
      reset_password_confirm_title: 'Confirm Password Change',

      confirmation_code: 'Received Code',
      reset_password_confirm_code: 'Confirm Code',

      captcha_error: 'reCAPTCHA verification unsuccessful',
      password_match: 'Password Confirmation doesn\'t match the Password',
      email_invalid: 'Invalid Email Address'
    },

    general: {
      labels: {
        relations: 'Relations',
        authorization: 'Authorization',
        authorization_and_extra: 'Authorization & Extra',

        request_info: 'Request Info',
        processing_info: 'Processing Information',
        common_info: 'Common Information',
        basic_info:  'Basic Information',
        sheet_info: 'Data Sheet',

        name: 'Name',
        description: 'Description',
        status: 'Status',
        type: "Type",

        username: 'Username',
        password: 'Password',
        key:   'Key',
        path:  'Path',
        code:  'Code',
        guid: 'GUID Code',
        value: 'Value',
        secured: 'Secured',
        headers: 'Headers',
        attributes: 'Attributes',
        variables: 'Variables',

        success: 'Success',
        failure: 'Failure',

        request: 'Request',
        response: 'Response',
        redirect: 'Redirect',

        setup_request: 'Punchout Setup Request',
        order_receipt_request: 'Order Receipt Request',

        transferred_cart: 'Transferred Cart',
        purchase_order: 'Purchase Order',
        invoice: 'Invoice',
        invoice_request: 'Invoice Request',
        credit_memo: 'Credit Memo',
        asn: 'ASN',
        asn_request: 'ASN Request',
        order_confirmation: 'Order Confirmation',
        order_confirmation_request: 'Order Confirmation Request',
        other: 'Other',

        client_registration: 'Client Registration',
        client_unregistration: 'Client Un-Registration',
        client_notification: 'Client Notification',

        api: 'API',
        punchout: 'Punchout',
        integration: 'Integration',

        retry: 'Retry',
        retry_put_queue_fallback: 'Retry with Fallback',
        retry_put_queue_onetime: 'Onetime Retry',
        web_url: 'Store Web URL',
        jwt_secret: 'JWT Secret',
        OCI: "OCI",
        cXML: "cXML",

        delete_content: "Are you sure you want to delete the %{name} item?",
        download_json: 'Download JSON',
      }
    },

    client: {
      label: 'Clients',
      create: 'Create Client',
      created: 'Client Created',
      edit: 'Edit Client',
      updated: 'Client Updated',
      labels: {
        status: 'Status',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        oci: 'OCI Credentials',
        //notification: 'Notifications',
        platform: 'Platform Information',
        contacts: 'Contacts',
        profile: "Company Profile",
        profile_updated: "Company Profile has been updated",
        gateway_url: "Gateway URL",
        connections: "Connections",
        updated_at: "Updated At",
        created_at: "Created At",
        code: "Code",
        type: "Type",
        outbound_token_note: "Empty field will use the first registered Outbound Token.",
        link_view: "View Client",
        subscription_name: "Subscription",
        subscription_connections_number: "Currently Paid number of connections is",
        info_connections_number: "Actual number of connections is",
        security: 'Security',
        external_profiles_section: 'External Mapping Profiles'
      },
      oci: {
        labels: {
          username_key: 'Username Key(s)',
          password_key: 'Password Key(s)',
        }
      },
      platform: {
        labels: {
          source: 'Source',
          url: 'URL',
          default_tier: 'Default Tier',
          default_url: 'Default URL',
          internal_id: 'Internal ID',
          default_internal_id: 'Default Internal ID',
          external_id: 'External ID',
          default_external_id: 'Default External ID',
          old_secret: 'Add Secret Key',
          new_secret: 'Replace Secret Key',
        },
        notes: {
          url: 'Store Static URL. Used for API calls.',
        }
      },
      contacts: {
        labels: {
          type: 'Type',
          name: 'Name',
          company: 'Company',
          email: 'Email',
          phone: 'Phone'
        }
      },
      security: {
        labels: {
          encryption_enabled: 'Enable Encrypted Mapping Requests and Responses',
          encryption_key: 'Encryption Key',
          encryption_key_hint: 'Encryption Key is used to Encrypt/Decrypt Mapping Requests and Responses. Length is 32 symbols.',
        }
      }
    },

    user: {
      label: 'Users',
      create: 'Create User',
      created: 'User Created',
      edit: 'Edit User',
      updated: 'User Updated',
      labels: {
        profile: "User Profile",
        profile_updated: "User Profile has been updated",
        password_info: 'Password Information',
        switcher_title: "Switch on behalf of a client",
        switch_successful: "Switch to %{client_name} client happened successfully",
        switch_back_successful: "Switch Back to %{client_name} client happened successfully",
      }
    },

    document: {
      label: 'Documents',
      create: 'Create Document',
      created: 'Document Created',
      edit: 'Edit Document',
      updated: 'Document Updated',
      labels: {
        type: {
          transferred_cart: 'Transferred Cart',
          purchase_order: 'Purchase Order',
          other: 'Other',
          //....
        },
        relation: {
          client: 'Client',
          buyer: 'Buyer',
          connection: 'Connection',
          profile: 'Profile',
          endpoint: 'Endpoint',
          transaction: 'Transaction',
          transferred_cart: 'Transferred Cart',
          purchase_order: 'Purchase Order',
        },
        item: {
          internal_id: 'Internal ID',
          supplier_part_id: 'Supplier Part ID',
          supplier_part_aux_id: 'Supplier Part Aux ID',
          buyer_part_id: 'Buyer Part ID',
          manufacturer_part_id: 'Manufacturer Part ID',
          manufacturer_name: 'Manufacturer Name',
          currency: 'Currency',
          line_type: 'Line Type',
          part_type: 'Part Type',
          weight: 'Weight',
          uom: 'UOM',
        },
        line_items: 'Line Items',
        reference: 'Reference',
        misc: 'Misc',
        quantity: 'Quantity',
        amounts: 'Amounts',
        classifications: 'Classifications',
        options: 'Options',
        attributes: 'Attributes',
      },
    },

    credential: {
      label: 'Credentials',
      create: 'Create Credentials',
      edit: 'Edit Credentials',
      created: 'Credentials Created',
      labels: {
        link_view: 'View Credentials',
        types: {
          cxml_buyer_id: 'cXML -> Auth Request By Buyer ID & Secret',
          cxml_sender_id: 'cXML -> Auth Request By Sender ID & Secret',
          cxml_common: 'cXML -> Common Export',
          oci_username: 'OCI -> Auth Request By Username & Password',
        },
        creds: {
          cxml_common: {
            password: 'cXML Shared Secret',
          },
          cxml_buyer_id: {
            username: 'cXML Buyer ID',
            password: 'cXML Shared Secret',
          },
          cxml_sender_id: {
            username: 'cXML Sender ID',
            password: 'cXML Shared Secret',
          },
          oci_username: {
            username: 'OCI Username',
            password: 'OCI Password',
          }
        },

        headers: {
          from: 'cXML Header From',
          to: 'cXML Header To',
          sender: 'cXML Header Sender',
        }
      },
      headers: {
        cxml_common: 'cXML Common Credentials',
        cxml_domain: 'cXML Domain',
        cxml_identity: 'cXML Identity',
      }
    },

    token: {
      label: 'Access Tokens',
      create: 'Create Token',
      edit: 'Edit Token',
      created: 'Token Created'
    },

    key: {
      view:  'Access Key Details',
      label: 'Access Keys',
      create: 'Create Key',
      created: 'Key Created'
    },

    referer: {
      label: 'Whitelisted Referers',
      create: 'Whitelist Referer',
      created: 'The Referer has been whitelisted',
    },

    importexport_batch: {
      label: 'Import/Export',
      labels: {
        type: {
          'other': 'Other',
          'transferred_cart': 'Transferred Cart',

          'order_receipt_request':        'Order Receipt -> Create',
          'order_receipt_request.create': 'Order Receipt -> Create',
          'order_receipt_request.cancel': 'Order Receipt -> Cancel',
          'order_receipt_request.update': 'Order Receipt -> Update',

          'purchase_order':         'Purchase Order -> Create',
          'purchase_order.create':  'Purchase Order -> Create',
          'purchase_order.cancel':  'Purchase Order -> Cancel',
          'purchase_order.update':  'Purchase Order -> Update',
          'purchase_order.replace': 'Purchase Order -> Replace',

          'invoice.create': 'Invoice -> Create',
          'asn.create':     'ASN -> Create',
          'order_confirmation.create':     'Order Confirmation -> Create',
          'other.create':   'Other -> Create',
          //....
        },
        action: {
          import: 'Import',
          export: 'Export',
        }
      },
      notes: {
        retry_put_queue_fallback: 'Retry with Fallback supports the following statuses: processing and failed.',
        retry_put_queue_onetime: 'Onetime Retry supports the following statuses: processing, failed and error.',
      }
    },

    profile: {
      label: 'Mapping Profiles',
      create: 'Create Profile',
      created: 'Profile Created',
      edit: 'Edit Profile',
      updated: 'Profile Updated',
      labels: {
        link_view: "View Profile",

        request_profile: "Request Profile",
        change_request_profile: "Change Request Profile",
        cancel_request_profile: "Cancel Request Profile",

        document_profile: "Document Profile",
        change_document_profile: "Change Document Profile",
        cancel_document_profile: "Cancel Document Profile",
        replace_cancel_document_profile: "Cancel Previous Document Profile",

        select_type_hint: "Please, select profile type",
        version: "Mapping Version",
        version_hint: "V2 - supports multiple stages. Only for internal gateway mapping purposes.",

        transferred_cart_format_oci: 'Transferred Cart -> OCI',
        transferred_cart_format_cxml: 'Transferred Cart -> cXML',
        invoice_format_cxml: 'Invoice -> cXML',
        asn_format_cxml: 'Advance Ship Notice -> cXML',
      }
    },

    connection: {
      label: 'Connections',
      create: 'Create Connection',
      created: 'Connection Created',
      edit: 'Edit Connection',
      updated: 'Connection Updated',
      labels: {
        auth: 'Authentication Information',
        platform: 'Platform Information',
        customer: 'Customer Information',
        cart_settings: 'Cart Settings',
        po_settings: 'Purchase Order Settings',
        inv_settings: 'Invoice Settings',
        asn_settings: 'ASN Settings',
        order_confirmation_settings: 'Order Confirmation Settings',

        cart_totals: 'Cart Totals',
        link_view: 'View Connection',
        name: 'Name',
        type: 'Type',
        format: 'Format',
        status: 'Status',
        match_rule: 'Matching Rule',

        credential: 'Credentials',
        request_credential: 'Request Accept Credentials',
        document_credential: 'Document Export Credentials',

        chooser_target: 'Connection Chooser Link Target',

        endpoint: 'Data Endpoint',
        buyer: 'Buyer',
        misc: 'Miscellaneous',
        select_type_hint: 'Please, select connection type',
        web_landing_page: 'Langing Page',

        setup_request: 'Buyer Punchout Setup Request -> Buyer Transferred Cart',
        order_receipt_request: 'Buyer Order Receipt Request -> Supplier Purchase Order',
        invoice_request: 'Supplier Invoice Request -> Buyer Invoice',
        asn_request: 'Supplier ASN Request -> Buyer ASN',
        order_confirmation_request: 'Supplier Order Confirmation Request -> Buyer Order Confirmation',

        transferred_cart: 'Transferred Cart',
        purchase_order: 'Purchase Order',
        invoice: 'Invoice',
        credit_memo: 'Credit Memo',
        asn: 'ASN',
        order_confirmation: 'Order Confirmation',
        other: 'Other',

        test_button: 'Run Test %{name}',

        setup_request_section: 'Setup Request',
        invoice_request_section: 'Invoice Request',
        asn_request_section:     'ASN Request',
        order_confirmation_request_section: 'Order Confirmation Request',

        create_order_section:  'Create Order',
        change_order_section:  'Change Order',
        cancel_order_section:  'Cancel Order',

        po_items_mode: 'PO Items Mode',
        inv_items_mode: 'Invoice Items Mode',
        asn_items_mode: 'ASN Items Mode',
        order_confirmation_items_mode: 'Order Confirmation Items Mode',

        po_items_modes: {
          default: 'Default: Catalog Only & Adhoc Only & Mixed',
          catalog: 'Catalog Only',
          adhoc: 'Adhoc Only',
          catalog_and_adhoc: 'Catalog Only & Adhoc Only',
        },

        inv_items_modes: {
          default: 'Default: PO based & Non-PO based & Mixed',
          po: 'PO based Only',
          non_po: 'Non-PO based Only',
          po_and_non_po: 'PO based & Non-PO based',
        },

        asn_items_modes: {
          default: 'Default: PO based & Non-PO based & Mixed',
          po: 'PO based Only',
          non_po: 'Non-PO based Only',
          po_and_non_po: 'PO based & Non-PO based',
        },

        order_confirmation_items_modes: {
          default: 'Default: PO based & Non-PO based & Mixed',
          po: 'PO based Only',
          non_po: 'Non-PO based Only',
          po_and_non_po: 'PO based & Non-PO based',
        },

        endpoints: {
          authorization: 'Authorization Supplier Endpoint',
          //
          create_order: 'Create Order Supplier Endpoint',
          create_order_default: 'Create Order Default Supplier Endpoint',
          create_order_catalog: 'Create Order Catalog Only Supplier Endpoint',
          create_order_adhoc:   'Create Order Adhoc Only Supplier Endpoint',
          //
          change_order: 'Change Order Supplier Endpoint',
          change_order_default: 'Change Order Default Supplier Endpoint',
          change_order_catalog: 'Change Order Catalog Only Supplier Endpoint',
          change_order_adhoc:   'Change Order Adhoc Only Supplier Endpoint',
          //
          cancel_order: 'Cancel Order Supplier Endpoint',
          cancel_order_default: 'Cancel Order Default Supplier Endpoint',
          cancel_order_catalog: 'Cancel Order Catalog Only Supplier Endpoint',
          cancel_order_adhoc:   'Cancel Order Adhoc Only Supplier Endpoint',
          //
          create_invoice_default: 'Create Invoice Buyer Endpoint',
          create_asn_default: 'Create ASN Buyer Endpoint',
          create_order_confirmation_default: 'Create Order Confirmation Buyer Endpoint',
        },

        notifications_section: 'Notifications',

        env: 'Environment',
        envs: {
          production: 'production',
          staging: 'staging',
          test: 'test',
          uat: 'uat',
          dev: 'dev',
          other: 'other',
        },
      },
      notes: {
        outbound_token: 'Empty field will use client config.',
        web_landing_page: 'Related Landing Page. Used for PunchOut Redirects. Applicable for Shopify and BigCommerce clients only. Example: collection/path or collection/path?param1=value1 .',
        po_items_mode: 'Mixed mode Allows all types of PO items Catalog & Non Catalog. A Non Catalog item is a Free-Form (AdHoc) item, like a Catalog item without a cart item reference (without SPAID).',
        inv_items_mode: '',
        asn_items_mode: '',
        order_confirmation_items_mode: '',
      },
      auth: {
        labels: {
          username: 'Username',
          password: 'Password',
          cxml_username_field: 'cXML Username Field',
        }
      },
      platform: {
        labels: {
          source: 'Source',
          url: 'URL',
          default_url: 'Default URL',
          internal_id: 'Internal ID',
          default_internal_id: 'Default Internal ID',
          external_id: 'External ID',
          default_external_id: 'Default External ID',
        }
      },
      customer: {
        labels: {
          allow_create: 'Allow Create',
          allow_update: 'Allow Update',
          allow_guest: 'Allow Guest',
          allow_signed: 'Allow Signed',
        }
      },
      cart_settings: {
        labels: {
          default_supplier_id: 'Default Supplier ID',
          default_currency: 'Default Currency',
          max_description_length: 'Max Description Length',
          encoding: 'Encoding',
        }
      },
      cart_totals: {
        labels: {
          code: 'Code',
          mode: 'Mode',
          classification_code: 'Classification Code',
          supplier_part_id: 'Supplier Part ID',
          supplier_id: 'Supplier ID',
        }
      },
      po_settings: {
        labels: {
          allow_upsert_order: 'Allow Upsert Order on Update Action',
          allow_change_order: 'Allow Change Order',
          allow_change_replace: 'Allow Replace Order',
          allow_cancel_order: 'Allow Cancel Order',
          allow_non_catalog: 'Allow All AdHocs Items',
          allowed_non_catalog_skus: 'Assume The Following Adhoc SKUs (Supplier Part IDs) as Catalog for items_mode eq catalog',
        },
        notes: {
          allow_upsert_order: 'Create a New Order if the requested PO does not exists on Update Action.',
          allow_change_replace: 'Cancel & Create New Order instead of actual update.',
          allow_change_order_shopify: 'Hint. Shopify Does not allow to update lines information.',
        }
      },
      notifications: {
        labels: {
          enabled_or_success: 'Allow Order Receipt Accept Success',
          enabled_or_failure: 'Allow Order Receipt Accept Failure',
          enabled_po_success: 'Allow Purchase Order Export Success',
          enabled_po_failure: 'Allow Purchase Order Export Failure',
        }
      }
    },

    buyer: {
      label: 'Buyers',
      create: 'Create Buyer',
      created: 'Buyer Created',
      edit: 'Edit Buyer',
      updated: 'Buyer Updated',
      labels: {
        link_view: 'View Buyer',
        invoice_settings_info: 'Invoice Settings',
        asn_settings_info: 'ASN Settings',
        order_confirmation_settings_info: 'Order Confirmation Settings',

        default_invoice_prod_connection: 'Default Invoice Prod Connection',
        default_invoice_test_connection: 'Default Invoice Test Connection',

        default_asn_prod_connection: 'Default ASN Prod Connection',
        default_asn_test_connection: 'Default ASN Test Connection',

        default_order_confirmation_prod_connection: 'Default Order Confirmation Prod Connection',
        default_order_confirmation_test_connection: 'Default Order Confirmation Test Connection',
      },
      hints: {
        default_invoice_test_connection: 'Test Connection applied when an Invoice comes with the added `X-TEST` flag via API or an exchange file places to the `Default-Test` Buyer\'s folder.',
        default_asn_test_connection: 'Test Connection applied when an ASN comes with the added `X-TEST` flag via API or an exchange file places to the `Default-Test` Buyer\'s folder.',
        default_order_confirmation_test_connection: 'Test Connection applied when an Order Confirmation comes with the added `X-TEST` flag via API or an exchange file places to the `Default-Test` Buyer\'s folder.',
      }
    },

    endpoint: {
      label:   'Endpoints',

      create:  'Create Endpoint',
      created: 'Endpoint Created',
      edit:    'Edit Endpoint',
      updated: 'Endpoint Updated',

      labels: {
        source_target: 'Source / Target Information',
        source_additional: 'Source / Additional Information',
        response_rule_conditions: 'Response Rule Conditions',
        response_mappings: 'Response Mappings',
        template_info: 'Template',
        additional_info: 'Additional',

        routes: 'Additional Routes',
        source: 'Source',
        channel: 'Channel',
        method:  'Method',
        operator:  'Operator',
        condition: 'Condition',

        uri:    'URI',
        email:    'Email',
        link_view: 'View Endpoint',
        select_client: 'Please, select a client.',

        notes: {
          method_hint: 'HTTP Method applied to the endpoint requests.',
          uri_hint: 'Available variables: document, client, endpoint. Example: {{document.references.external_id}}.',
          simple_hint: 'Available variables: document, client, endpoint. Example: {{client.email}}. Allowed several values separated via a new line.',

          attributes_hint: 'Supported Attribute Placeholders for Code and Value:',
          response_rule_conditions: 'Response assumed successful once passed all conditions.',
        },

        sources: {
          'standard': 'Standard',
          'bigcommercecloud': 'BigCommerce Cloud',
          'shopifycloud': 'Shopify Cloud',
          'restapi': 'REST API',
          'other': 'Other',
          'email': 'Email',
        },

        methods: {
          post: 'POST',
          get:  'GET',
          put:  'PUT',
          delete: 'DELETE',
          smtp: 'SMTP',
        },

        authorization: {
          method: 'Method',
          methods: {
            'disabled' : 'Disabled',
            'basic'    : 'HTTP Basic Authorization',
            'apikey'   : 'API Key',
            'connector': 'Embedded Connector',
            'outbound-token': 'Standard Outbound Token',
            'outbound-signature': 'Standard Outbound Signature',
          }
        },

        request_payload_apply:      'Apply Request Payload Approach',
        request_payload_apply_hint: 'Use a single field prepared from entire JSON body before applying signature',
        request_payload_name:   'Payload Field Name',
        request_payload_method: 'Payload Field Method',

        mappings: {
          field_name: 'Field Name',
          response_path: 'Response Path',
          is_required: 'Required?',
          //
          ext_order_id: 'External Order ID (Purchase Order)',
          ext_purchase_order_id: 'External PO ID',
          ext_invoice_id: 'External Invoice ID',
          ext_asn_id: 'External ASN ID',
          ext_other_id: 'External Other ID',
          //
          auth_url: 'Auth URL (Setup Request)'
        }
      },

      notes: {
        web_url: 'Store Frontend URL. Used for PunchOut Redirects. If empty URL is used as a default value. Example: https://www.store.com .',
        jwt_secret: 'Integration Secret. Example: Shopify Multipass Secret.',
      }
    },

    transaction: {
      view: 'Transaction Details',
      label: 'Transactions',
      labels: {
        setup_request: 'Setup Request',
        order_receipt_request: 'Order Receipt Request',
        authorization: 'Authorization',
        transferred_cart: 'Transferred Cart',
        purchase_order: 'Purchase Order',
        invoice: 'Invoice',
        asn: 'ASN',
        client_registration: 'Client Registration',
        client_unregistration: 'Client Unregistration',
        client_notification: 'Client Notification',
      }
    },

    mapper: {
      customer: {
        header: 'Customer Profile'
      },
      billing: {
        header: 'Customer Billing Address'
      },
      shipping: {
        header: 'Customer Shipping Address'
      },
      cart_item: {
        header: 'Cart Item: Edit & Inspect Session'
      },
      catalog_navigation: {
        header: 'Catalog Navigation: Create Session'
      },
    },

    license: {
      label: 'Licenses',
      create: 'Create License',
      created: 'License Created'
    },

    template: {
      label: 'Templates',
      create: 'Create Template',
      created: 'Template Created',
      edit: 'Edit Template',
      updated: 'Template Updated',

      labels: {
        template: 'Template',
        link_view: "View Template",
        notes: {
          //help_text: 'Available variables in the body and subject fields: document, client, endpoint. Example: {{document.references.external_id}}, {{document.shipping_address.0.email.0}}, {{document.billing_address.0.email.0}}.'
          help_text: '',
        }
      }
    },

    core: {
      request: {
        error: {
          missing_header_x_total: 'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        }
      }
    },

    report: {
      labels: {
        current_month: 'This Month',
        current_week:  'This Week',
        current_year:  'This Year',
        current_day:   'Today',
        last_7_days:   'Last 7 Days',
        last_30_days:  'Last 30 Days',
        last_year:     'Last Year',

        all: 'All',
        transferred_cart: 'Transferred Cart',
        purchase_order: 'Purchase Order',
        invoice: 'Invoice',
        credit_memo: 'Credit Memo',
        asn: 'ASN',
        other: 'Other'
      },
    },

    report_clients: {
      label: 'Clients Report',
    },

    table: {
      label: 'Tables',
      create:  'Create Table',
      created: 'Table Created',
      edit:    'Edit Table',
      updated: 'Table Updated',
    }
  },

  buttons: {
    view: 'View',
    edit: 'Edit',
    delete: 'Delete'
  },
}
