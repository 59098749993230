import React from 'react';
import {NumberField} from 'react-admin';
import {makeStyles} from '@mui/styles';
import styles from "../styles";

const useStyles = makeStyles(styles);

const Amount = (props) => {
  const classes = useStyles();
  const {record, amountName} = props;
  const total = record?.amounts?.[amountName];
  if (!total) {
    return null;
  }

  const options = {
    style: 'currency',
    currency: total.currency ? total.currency : '',
  };

  return (<NumberField source="amount" record={total} options={options} className={classes.total} />);
};

export default Amount;


