import jwtDecode from 'jwt-decode';
// @todo: fix it after upgrade !!!
// import jwt from 'jsonwebtoken';

export function decode(token) {
    const data = jwtDecode(token);

    if ((typeof data !== 'object') && (typeof data.payload !== 'object')) {
        return false;
    }

    return data;
}
