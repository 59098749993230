import {
  CHANNEL_API,
  CHANNEL_INTEGRATION,
  CHANNEL_PUNCHOUT,

  TYPE_INVOICE_REQUEST,
  TYPE_ASN_REQUEST,
  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_SETUP_REQUEST,

  TYPE_ORDER_CONFIRMATION_REQUEST,
} from '../../app/etc/consts';

import {
  TYPE_PURCHASE_ORDER,
  TYPE_TRANSFERRED_CART,
  TYPE_ASN,
  TYPE_INVOICE,
  TYPE_ORDER_CONFIRMATION,
} from '../document/consts';

import {DATA_FORMAT_XML} from '../connection/consts';

export const VERSION_V1 = 'V1';
export const VERSION_V2 = 'V2';

//format
export const DATA_FORMAT_CXML = 'cXML';
export const DATA_FORMAT_OCI  = 'OCI';
export const DATA_FORMAT_JSON  = 'JSON';

export const AVAILABLE_VERSIONS = [
  VERSION_V1,
  VERSION_V2,
];

export const AVAILABLE_PROFILE_CHANNELS = [
  CHANNEL_API,
  CHANNEL_PUNCHOUT,
  CHANNEL_INTEGRATION,
];

export const AVAILABLE_PROFILE_FORMATS = [
  DATA_FORMAT_CXML,
  DATA_FORMAT_OCI,
  DATA_FORMAT_JSON,
  DATA_FORMAT_XML,
];

export const AVAILABLE_PROFILE_FORMATS_PER_TYPE = {
  // Punchout
  [TYPE_SETUP_REQUEST]: [DATA_FORMAT_CXML, DATA_FORMAT_OCI],
  [TYPE_TRANSFERRED_CART]: [DATA_FORMAT_CXML, DATA_FORMAT_OCI],

  // Ordering
  [TYPE_ORDER_RECEIPT_REQUEST]: [DATA_FORMAT_CXML],
  [TYPE_PURCHASE_ORDER]: [DATA_FORMAT_JSON],

  // Invoicing
  [TYPE_INVOICE_REQUEST]: [DATA_FORMAT_XML, DATA_FORMAT_JSON],
  [TYPE_INVOICE]: [DATA_FORMAT_CXML],

  // ASN/Shipping
  [TYPE_ASN_REQUEST]: [DATA_FORMAT_XML, DATA_FORMAT_JSON],
  [TYPE_ASN]: [DATA_FORMAT_CXML],

  // ASN/Shipping
  [TYPE_ORDER_CONFIRMATION_REQUEST]: [DATA_FORMAT_XML, DATA_FORMAT_JSON],
  [TYPE_ORDER_CONFIRMATION]: [DATA_FORMAT_CXML],
};

export const AVAILABLE_PROFILE_TYPES = [
  TYPE_SETUP_REQUEST,
  TYPE_TRANSFERRED_CART,

  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_PURCHASE_ORDER,

  TYPE_INVOICE_REQUEST,
  TYPE_INVOICE,

  TYPE_ASN_REQUEST,
  TYPE_ASN,

  TYPE_ORDER_CONFIRMATION_REQUEST,
  TYPE_ORDER_CONFIRMATION,
];

//@todo: review and refactor all below....
//all mapping
export const MAPPING_NA = {id: 'N/A', name: 'N/A'};
export const MAPPING_CUSTOM = {id: 'custom', name: 'Custom'};

//customer mapper fields
export const MAPPING_CUSTOMER_FIRST_NAME = {id: 'firstname', name: 'First Name'};
export const MAPPING_CUSTOMER_LAST_NAME = {id: 'lastname', name: 'Last Name'};
export const MAPPING_CUSTOMER_MIDDLE_NAME = {id: 'middlename', name: 'Middle Name'};
export const MAPPING_CUSTOMER_EMAIL = {id: 'email', name: 'Email'};
export const MAPPING_CUSTOMER_GROUP_ID = {id: 'group_id', name: 'Group ID'};
export const MAPPING_CUSTOMER_EXTERNAL_ID = {id: 'external_id', name: 'External ID'};

//billing/shipping address mapper fields
export const MAPPING_ADDRESS_FIRST_NAME = {id: 'firstname', name: 'First Name'};
export const MAPPING_ADDRESS_LAST_NAME = {id: 'lastname', name: 'Last Name'};
export const MAPPING_ADDRESS_MIDDLE_NAME = {id: 'middlename', name: 'Middle Name'};
export const MAPPING_ADDRESS_COMPANY = {id: 'company', name: 'Company'};
export const MAPPING_ADDRESS_STREET = {id: 'street', name: 'Street'};
export const MAPPING_ADDRESS_CITY = {id: 'city', name: 'City'};
export const MAPPING_ADDRESS_REGION = {id: 'region', name: 'Region'};
export const MAPPING_ADDRESS_POSTAL_CODE = {id: 'postcode', name: 'Postal Code'};
export const MAPPING_ADDRESS_COUNTRY = {id: 'country', name: 'Country'};
export const MAPPING_ADDRESS_EMAIL = {id: 'email', name: 'Email'};
export const MAPPING_ADDRESS_TELEPHONE = {id: 'telephone', name: 'Telephone'};
export const MAPPING_ADDRESS_FAX = {id: 'fax', name: 'Fax'};
export const MAPPING_ADDRESS_EXTERNAL_ID = {id: 'external_id', name: 'External Id'};

//cart item mapper fields
export const MAPPING_CART_ITEM_INTERNAL_ID = {id: 'internal_id', name: 'Internal ID'};

//catalog navigation mapper fields
export const MAPPING_CATALOG_NAVIGATION_PRODUCT_SKU = {id: 'sku', name: 'Product SKU'};

//transform select options
export const MAPPING_TRANSFORM_OPTIONS_ADD = {id: 'add', name: 'Add Transform'};
export const MAPPING_TRANSFORM_OPTIONS_SPLIT = {id: 'split', name: 'Split'};
export const MAPPING_TRANSFORM_OPTIONS_JOIN = {id: 'join', name: 'Join'};
export const MAPPING_TRANSFORM_OPTIONS_CUT = {id: 'cut', name: 'Cut'};
export const MAPPING_TRANSFORM_OPTIONS_UPPERCASE = {id: 'uppercase', name: 'Uppercase'};
export const MAPPING_TRANSFORM_OPTIONS_LOWERCASE = {id: 'lowercase', name: 'Lowercase'};
export const MAPPING_TRANSFORM_OPTIONS_NOT = {id: 'not', name: 'Bool NOT'};
export const MAPPING_TRANSFORM_OPTIONS_DATE = {id: 'date', name: 'Date'};
export const MAPPING_TRANSFORM_OPTIONS_DEFAULT = {id: 'default', name: 'Default Value'};
export const MAPPING_TRANSFORM_OPTIONS_APPEND = {id: 'append', name: 'Append'};
export const MAPPING_TRANSFORM_OPTIONS_PREPEND = {id: 'prepend', name: 'Prepend'};
export const MAPPING_TRANSFORM_OPTIONS_MAP = {id: 'map', name: 'Map'};

export const MAPPING_CUSTOMER_FIELDS = [
  MAPPING_NA,
  MAPPING_CUSTOMER_FIRST_NAME,
  MAPPING_CUSTOMER_LAST_NAME,
  MAPPING_CUSTOMER_MIDDLE_NAME,
  MAPPING_CUSTOMER_EMAIL,
  MAPPING_CUSTOMER_GROUP_ID,
  MAPPING_CUSTOMER_EXTERNAL_ID,
  MAPPING_CUSTOM,
];

export const MAPPING_ADDRESS_FIELDS = [
  MAPPING_NA,
  MAPPING_ADDRESS_FIRST_NAME,
  MAPPING_ADDRESS_LAST_NAME,
  MAPPING_ADDRESS_MIDDLE_NAME,
  MAPPING_ADDRESS_COMPANY,
  MAPPING_ADDRESS_STREET,
  MAPPING_ADDRESS_CITY,
  MAPPING_ADDRESS_REGION,
  MAPPING_ADDRESS_POSTAL_CODE,
  MAPPING_ADDRESS_COUNTRY,
  MAPPING_ADDRESS_EMAIL,
  MAPPING_ADDRESS_TELEPHONE,
  MAPPING_ADDRESS_FAX,
  MAPPING_ADDRESS_EXTERNAL_ID,
  MAPPING_CUSTOM,
];

export const MAPPING_CART_ITEM_FIELDS = [
  MAPPING_NA,
  MAPPING_CART_ITEM_INTERNAL_ID,
  MAPPING_CUSTOM,
];

export const MAPPING_CATALOG_NAVIGATION_FIELDS = [
  MAPPING_NA,
  MAPPING_CATALOG_NAVIGATION_PRODUCT_SKU,
  MAPPING_CUSTOM,
];

export const PROFILE_FIELDS = [
  'id',
  'client_id',
  'type',
  'version',
  'status',
  'is_shared',
  'format',
  'code',
  'channel',
  'name',
  'description',
  'mapping',
  'draft',
  'created_at',
  'updated_at'
];

export const MAPPING_SECTIONS = [
  'customer', 'billing', 'shipping', 'cart_item', 'catalog_navigation'
];

export const MAPPING_FIELD_COLUMNS = [
  'path', 'system_field', 'required', 'transform', 'action'
];

//mapper index choices
export const MAPPING_INDEX_CHOICES = [
  {id: '1', name: '1'},
  {id: '2', name: '2'},
  {id: '3', name: '3'},
  {id: '4', name: '4'},
  {id: '5', name: '5'},
  {id: '6', name: '6'},
  {id: '7', name: '7'},
  {id: '8', name: '8'},
  {id: '9', name: '9'},
  {id: '10', name: '10'},
  {id: 'last', name: 'Last'},
  {id: 'all', name: 'All'},
];

//transform select
export const MAPPING_TRANSFORM_CHOICES = [
  MAPPING_TRANSFORM_OPTIONS_ADD,
  MAPPING_TRANSFORM_OPTIONS_SPLIT,
  MAPPING_TRANSFORM_OPTIONS_JOIN,
  MAPPING_TRANSFORM_OPTIONS_CUT,
  MAPPING_TRANSFORM_OPTIONS_UPPERCASE,
  MAPPING_TRANSFORM_OPTIONS_LOWERCASE,
  MAPPING_TRANSFORM_OPTIONS_NOT,
  MAPPING_TRANSFORM_OPTIONS_DATE,
  MAPPING_TRANSFORM_OPTIONS_DEFAULT,
  MAPPING_TRANSFORM_OPTIONS_APPEND,
  MAPPING_TRANSFORM_OPTIONS_PREPEND,
  MAPPING_TRANSFORM_OPTIONS_MAP,
];
