import React from 'react';
import {CUSTOM_SOURCES} from "../consts";
import {TextInput} from "react-admin";

export const skipHandling = (source) => {
  return (!source || CUSTOM_SOURCES.indexOf(source) === -1);
};

const rows = [
  // 'ext_order_id',
  'ext_purchase_order_id',
  'ext_invoice_id',
  'ext_asn_id',
  'ext_other_id',
  'auth_url',
];

export default ({ex, formData}) => {
  const {source} = formData;
  if (skipHandling(source)) {
    return <></>;
  }

  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.endpoint.labels.response_mappings')}</div>
      {rows.map((field) =>
        <TextInput source={"response.mapping." + field}  label={ex.translate('app.endpoint.labels.mappings.' + field)} />
      )}
    </>
  );
};
