//@See: https://github.com/CacheControl/json-rules-engine/blob/master/docs/rules.md#operators
export const AVAILABLE_RULE_CONDITION_EXPRESSIONS = [
  'all',
  'any',
];

//@See: https://github.com/CacheControl/json-rules-engine/blob/master/docs/rules.md#operators
export const AVAILABLE_RULE_CONDITION_OPERATORS = [
  'equal',
  'notEqual',
  'strIsEmpty',
  'strIsNotEmpty',
  'strContains',
  'strDoesNotContain',
];

export const ENV_PRODUCTION = 'production';
export const ENV_STAGING = 'staging';
export const ENV_TEST = 'test';
export const ENV_UAT = 'uat';

export const ENV_DEV = 'dev';
export const ENV_OTHER = 'other';
