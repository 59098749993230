import React, { useState } from 'react';
import {Form, Field} from 'react-final-form'
import {Link} from 'react-router-dom';

import {Button, Card, CardActions}  from '@mui/material';

import {Notification, useRedirect, useDataProvider} from 'react-admin';
import {useTranslate, useNotify} from 'ra-core';
import {TextField, CaptchaField, PublicFormIcon} from "../../../Form/Fields";
import {PublicStyles} from '../../../../../theme/skin/poc/styles';
import config from '../../../../../app/etc/config';

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(!config.google.recaptcha.enabled);

  const translate = useTranslate();
  const classes = PublicStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onSubmit = () => {
    if (!verified) {
      notify(translate('app.auth.captcha_error'));
      return;
    }

    setLoading(true);

    //get form data
    let formData = new FormData(document.forms.ResetConfirmPasswordForm);

    //call dataProvider
    dataProvider.create(config.routes.USER_PASSWORD_RESET_CONFIRM, {
      data : {
        code: formData.get('code')
      }
    })
      .then(response => {
        setLoading(false);
        notify(response.data.message);
        redirect('login')
      })
      .catch(error => {
        setLoading(false);
        notify(error.message, 'error');
      });
  }

  const validateResetConfirmPassword = (values) => {
    const errors = {};
    if (!values.code) {
      errors.code = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <div className={classes.main}>
            <Card className={classes.card}>

              <div className={classes.avatar}>
                <PublicFormIcon />
              </div>

              <form
                autoComplete="off"
                id="ResetConfirmPasswordForm"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className={classes.form}>
                  <div>
                    <h2>{translate('app.auth.reset_password_confirm_title')}</h2>
                  </div>

                  <Field autoFocus component={TextField} disabled={loading} label={translate('app.auth.confirmation_code')} name="code" />

                  <CaptchaField verified={verified} setVerified={setVerified}/>
                </div>

                <CardActions className={classes.actions}>
                  <Button
                    color="primary"
                    disabled={loading}
                    fullWidth
                    label={translate('app.auth.reset_password_confirm_code')}
                    type="submit"
                    variant="contained"
                  >
                    {translate('app.auth.reset_password_confirm_code')}
                  </Button>
                </CardActions>
                <CardActions className={classes.actions}>
                  <Link
                    className={classes.link}
                    disabled={loading}
                    to="forgotpassword"
                  >{translate('app.auth.back_to_reset')}</Link>
                </CardActions>
              </form>

            </Card>
            <Notification />
          </div>
        )}
        validate={validateResetConfirmPassword}
      />
    </div>
  );
};

export default Index;
