import React from 'react';
import {Create, Edit, SimpleForm, TextInput, SelectInput, FormDataConsumer, email, required} from 'react-admin';
import {useTranslate} from 'ra-core';

import {AVAILABLE_USER_PERMISSIONS} from './consts';
import {AVAILABLE_BASIC_STATUSES} from '../../app/etc/consts';

import session from '../../app/session';
import {deletePermission} from "./permissions";

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField} from '../../components/poc/Form/Fields';
// import {useFormContext} from "react-hook-form";

const validateEdit = (values) => {
  const errors = {};

  if (!values.firstname) {
    errors.firstname = ['The First Name is required'];
  }
  if (!values.lastname) {
    errors.lastname = ['The Last Name is required'];
  }
  if (!values.email) {
    errors.email = ['The Email is required'];
  }
  if (!values.status) {
    errors.status = ['The Status is required'];
  }

  if (session.hasAdminPermission() && !values.permission) {
    errors.permission = ['The Permission is required'];
  }
  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }

  if ((!!values.password || !!values.password_confirmation) && (values.password !== values.password_confirmation)) {
    errors.password = ['The Password Confirmation has to match the Password'];
  }

  return errors
};

const validateCreate = (values) => {
  const errors = validateEdit(values);

  if (!values.password) {
    errors.password = ['The Password is required'];
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = ['The Password Confirmation is required'];
  }

  return errors
};

export const UserCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.user.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

export const UserEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit{...props} className={classes.root} successMessage={translate('app.user.updated')}>
      {EditForm(props, 'edit')}
    </Edit>
  )
};


export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar
        validate={mode === 'edit' ? validateEdit : validateCreate} deleteConditions={mode === 'edit' ? deletePermission : false}
      />}
    >
      <Actions
        className={classes.actions}
        listUrl="/users"
        title={translate('app.user.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            {session.hasAdminPermission() &&
            <ClientField clientId={formData.client_id} ex={ex} />
            }

            <FormSectionCommon disabled={false} classes={classes} />

            <SelectInput source="status" label="Status" choices={AVAILABLE_BASIC_STATUSES} key="status"/>

            {session.hasAdminPermission() &&
            <SelectInput source="permission" label="Permission" choices={AVAILABLE_USER_PERMISSIONS} key="permission"/>
            }

            <FormSectionPassword disabled={false} classes={classes} />
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
};


export const FormSectionCommon = ({disabled, classes}) => {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.formSection}>{translate('app.general.labels.common_info')}</div>

      <TextInput source="firstname" label="First Name"  validate={[required()]}/>
      <TextInput source="lastname" label="Last Name"  validate={[required()]}/>
      <TextInput source="email" label="Email" validate={[required(), email()]}/>
    </>
  );
};

export const FormSectionPassword = ({disabled, classes}) => {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.formSection}>{translate('app.user.labels.password_info')}</div>
      <TextInput source="password" type="password" label="Password" name="password" key="password"/>
      <TextInput source="password_confirmation" type="password" key="password_confirmation" label="Password Confirmation" name="password_confirmation"/>
    </>
  );
};
