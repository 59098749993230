import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#263238';
const pocOrange = '#DF5F3D';
const pocDarkGray = '#787D8A';
const pocGray = '#9A9A96';
const pocGrayLighter = '#c3c3c3';
//const pocLightGray = '#EBEBEE';
const pocLightGray = '#F4F6F8';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: pocDarkGray,
    main: pocGray,
    lighter: pocGrayLighter,
    light: pocLightGray,
    highlight: pocOrange,
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
    highlight: 'rgba(244, 67, 54, 0.12)'
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: pocDarkGray,
  divider: colors.grey[200]
};
