import React from 'react';

import {
  ReferenceField,
  TextField,
  NumberField,

  FieldProps,

} from 'react-admin';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Grid,
  Typography
} from '@mui/material';

import {useTranslate} from "ra-core";
import {Ssf} from './ssf';

import { makeStyles } from '@mui/styles';
import styles from "../styles";
const useStyles = makeStyles(styles);


/**
id	string
...
tax	{...}
shipping	{...}
accounting	[...]
charges	[...]
items	[...]
supplier	{...}
external	{...}
customer	{...}
billing_address	[...]
shipping_address	[...]
custom_address	[...]
contacts	[...]
currency_rates	[...]

relations	[...]
endpoints	[...]

amounts	{...}
flags	{...}
dates	{...}
attributes	[...]
*/

const LINE_FIELDS = [
  //'internal_id',
  'uom',
  'supplier_part_id',
  'buyer_part_id',
  'supplier_part_aux_id',
  //'manufacturer_part_id',
  //'manufacturer_name',
  //'currency',
  //'line_type',
  //'part_type',
  //'weight',
];

const Spacer = () => <Box m={0.25}>&nbsp;</Box>;

const LineField = (props) => {
  const {record, name} = props;
  const classes = useStyles();
  const translate = useTranslate();

  if (typeof record[name] === 'undefined') {
    return null;
  }

  let val = Array.isArray(record[name]) ? record[name].join(', ') : record[name];

  return (
    <TableRow>
      <TableCell className={classes.ssfCell}>{translate('app.document.labels.item.' + name)}:</TableCell>
      <TableCell className={classes.ssfCell}>{val}</TableCell>
    </TableRow>
  );
};

const LineItem = (props) => {
  const classes = useStyles();
  const {item} = props;
  const fields = LINE_FIELDS.map(name => <LineField record={item} name={name}/>);

  return (
    <TableRow key={item.id}>
      <TableCell className={classes.itemCell}>
        <div className={classes.itemField}>{item.name}</div>
        {item.description && <div className={classes.itemField}>{item.description}</div>}
        {item.long_description && <div className={classes.itemField}>{item.long_description}</div>}

        {fields.length &&
        <Table className={classes.ssfContainer} size="small" padding="none">
          <TableBody>{fields}</TableBody>
        </Table>
        }
      </TableCell>
      <TableCell className={classes.itemCell}>
        {item.classifications &&
        <>
          <Ssf type="classifications" record={item} header/>
        </>
        }

        {item.options &&
        <>
          <Spacer />
          <Ssf type="options" record={item} header/>
        </>
        }

        {item.attributes &&
        <>
          <Spacer />
          <Ssf type="attributes" record={item} header/>
        </>
        }
        {item.dates &&
          <>
            <Spacer />
            <Ssf type="dates" record={item} header/>
          </>
        }
      </TableCell>
      <TableCell className={classes.itemCell} alignRight>
        {item.qty}
      </TableCell>
      <TableCell className={classes.itemCell} alignRight>
        <Ssf type="amounts" record={item} container/>
      </TableCell>
    </TableRow>
  );
};

const Lines = (props) => {
  const translate = useTranslate();
  const {record} = props;
  if (!record.items || !record.items.length) {
    return 'There is no one line item';
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {translate('app.document.labels.reference')}
          </TableCell>
          <TableCell>
            {translate('app.document.labels.misc')}
          </TableCell>
          <TableCell>
            {translate('app.document.labels.quantity')}
          </TableCell>
          <TableCell>
            {translate('app.document.labels.amounts')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.items.map((item, key) => <LineItem item={item} key={key} />)}
      </TableBody>
    </Table>
  );
};

export default Lines;
