/**
 * @todo: AutocompleteInput instead of SelectInput
 */
import React, {useState} from 'react';
import {
  Create,
  Edit,

  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  useDataProvider,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import {useTranslate} from 'ra-core';
import Chip from '@mui/material/Chip';
import RefreshIcon from '@mui/icons-material/Refresh';

import session from '../../app/session';
import {deletePermission, duplicatePermission, editPermission} from './permissions';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField, ProfileField, EndpointField, BuyerField, CredentialField} from '../../components/poc/Form/Fields';
import {getGatewayUrl} from '../client/helper';
import {getFormDataFromUrl} from '../../lib/url';

import {
  AVAILABLE_CONNECTION_FORMATS_PER_TYPE,
  AVAILABLE_CONNECTION_TYPES,
  AVAILABLE_CONNECTION_ENCODING_TYPES,
  AVAILABLE_CONNECTION_CART_TOTALS_CODES,
  AVAILABLE_CONNECTION_CART_TOTALS_MODES,
  //
  AVAILABLE_CHOOSER_TARGETS,
  DATA_FORMAT_CXML,

  AVAILABLE_PO_ITEMS_MODES,
  AVAILABLE_BASE_ITEMS_MODES,

  PO_ITEMS_MODE_DEFAULT,
  PO_ITEMS_MODE_CATALOG,
  PO_ITEMS_MODE_ADHOC,
  PO_ITEMS_MODE_CATALOG_AND_ADHOC, AVAILABLE_CONNECTION_ENVS,
} from './consts';

import {
  AVAILABLE_BASIC_STATUSES,
  CHANNEL_PUNCHOUT,
  CHANNEL_INTEGRATION,
  //
  TYPE_SETUP_REQUEST,
  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_INVOICE_REQUEST,
  TYPE_ASN_REQUEST, TYPE_ORDER_CONFIRMATION_REQUEST,
} from '../../app/etc/consts';

import {
  TYPE_PURCHASE_ORDER,
  TYPE_TRANSFERRED_CART,
  TYPE_INVOICE,
  TYPE_ASN,
  TYPE_ORDER_CONFIRMATION
} from "../document/consts";

import {toOptions, hasFieldValue} from "../../lib/source";
import {DATA_FORMAT_JSON} from "../profile/consts";
import {TYPE_INTEGRATION} from "../client/consts";
import FormVariablesSettings from "./components/variables";

import {
  TYPE_CXML_BUYER_ID,
  TYPE_CXML_COMMON,
  TYPE_CXML_SENDER_ID,
  TYPE_OCI_USERNAME
} from "../credential/consts";

const requestCredentialTypes = [
  TYPE_CXML_BUYER_ID,
  TYPE_CXML_SENDER_ID,
  TYPE_OCI_USERNAME,
];

const documentCredentialTypes = [
  TYPE_CXML_COMMON,
];

const canShowDefaultSection = (items_mode) => {
  return true;
};

const canShowCatalogSection = (items_mode) => {
  return [
    PO_ITEMS_MODE_DEFAULT,
    PO_ITEMS_MODE_CATALOG,
    PO_ITEMS_MODE_CATALOG_AND_ADHOC,
  ].indexOf(items_mode) !== -1;
};

const canShowAdhocSection = (items_mode) => {
  return [
    PO_ITEMS_MODE_DEFAULT,
    PO_ITEMS_MODE_ADHOC,
    PO_ITEMS_MODE_CATALOG_AND_ADHOC,
  ].indexOf(items_mode) !== -1;
};


const fieldsMap = {
  'client_id' : 'Client',
  'env' : 'Environment',
  'auth.request_credential_id': 'Credentials',
  'auth.document_credential_id': 'Credentials',

  'profiles.request_profile_id' : 'Request Profile',
  'profiles.document_profile_id' : 'Document Profile',
  'profiles.change_request_profile_id': 'Change Request Profile',
  'profiles.change_document_profile_id': 'Change Document Profile',
  'profiles.cancel_request_profile_id': 'Cancel Request Profile',

  'chooser_target': 'Connection Chooser Link Target',

  'cart_settings.encoding': 'Cart Settings Encoding',
  // 'cart_settings.default_supplier_id': 'Cart Settings Default Supplier ID',
  // 'cart_settings.default_currency': 'Cart Settings Default Currency',
  'setup_request_endpoints.authorization.endpoint_id': 'Authorization Endpoint',

  'po_settings.items_mode_create_order': 'PO Items Mode (Create Order)',
  'po_settings.items_mode_change_order': 'PO Items Mode (Change Order)',
  'po_settings.items_mode_cancel_order': 'PO Items Mode (Cancel Order)',

  'inv_settings.items_mode': 'Invoice Items Mode',
  'asn_settings.items_mode': 'Asn Items Mode',
  'order_confirmation_settings.items_mode': 'Щrder Сonfirmation Items Mode',

  'purchase_order_endpoints.default.create_endpoint_id': 'Create Order Default Endpoint',
  'purchase_order_endpoints.default.change_endpoint_id': 'Change Order Default Endpoint',
  'purchase_order_endpoints.default.cancel_endpoint_id': 'Cancel Order Default Endpoint',

  'notification_endpoints.nfy_or_success_endpoint_id': 'Order Receipt Accept Success Endpoint',
  'notification_endpoints.nfy_or_failure_endpoint_id': 'Order Receipt Accept Failure Endpoint',
  'notification_endpoints.nfy_po_success_endpoint_id': 'Purchase Order Export Success Endpoint',
  'notification_endpoints.nfy_po_failure_endpoint_id': 'Purchase Order Export Failure Endpoint',
};

const clientDependentFields = [
  'buyer_id',
  //
  'auth.request_credential_id',
  'auth.document_credential_id',
  //
  'profiles.request_profile_id',
  'profiles.document_profile_id',
  'profiles.change_request_profile_id',
  'profiles.change_document_profile_id',
  'profiles.cancel_request_profile_id',
  'profiles.cancel_document_profile_id',
  //
  'setup_request_endpoints.authorization.endpoint_id',
  //
  'purchase_order_endpoints.default.create_endpoint_id',
  'purchase_order_endpoints.default.change_endpoint_id',
  'purchase_order_endpoints.default.cancel_endpoint_id',
  //
  'notification_endpoints.nfy_or_success_endpoint_id',
  'notification_endpoints.nfy_or_failure_endpoint_id',
  'notification_endpoints.nfy_po_success_endpoint_id',
  'notification_endpoints.nfy_po_failure_endpoint_id',
];

const validate = (values) => {
  const reqFields = [
    //Common
    'name',
    'buyer_id',
    'status',
    'type',
    'env',
    'format',
    //Request/Transform Related
    //'endpoints.endpoint_id',
    'profiles.request_profile_id',
    'profiles.document_profile_id'
  ];

  if (session.hasAdminPermission()) {
    reqFields.push('client_id');
  }

  const validateCartRelated = (values.type === TYPE_SETUP_REQUEST);
  const validateOrderRelated = (values.type === TYPE_ORDER_RECEIPT_REQUEST);
  const validateInvRelated = (values.type === TYPE_INVOICE_REQUEST);
  const validateAsnRelated = (values.type === TYPE_ASN_REQUEST);
  const validateOrderConfirmationRelated = (values.type === TYPE_ORDER_CONFIRMATION_REQUEST);
  //const items_mode = !!values && !!values.po_settings ? values.po_settings.items_mode : '';

  const allow_create_order = true;
  const allow_change_order = hasFieldValue(values, 'po_settings.allow_change_order');
  const allow_cancel_order = hasFieldValue(values, 'po_settings.allow_cancel_order');

  const enabled_or_success = hasFieldValue(values, 'notifications.enabled_or_success');
  const enabled_or_failure = hasFieldValue(values, 'notifications.enabled_or_failure');
  const enabled_po_success = hasFieldValue(values, 'notifications.enabled_po_success');
  const enabled_po_failure = hasFieldValue(values, 'notifications.enabled_po_failure');

  if (validateCartRelated) {
    reqFields.push('chooser_target');
    //reqFields.push('cart_settings.default_supplier_id');
    //reqFields.push('cart_settings.default_currency');
    reqFields.push('setup_request_endpoints.authorization.endpoint_id');
  }

  if (validateInvRelated) {
    reqFields.push('inv_settings.items_mode');
  }
  if (validateAsnRelated) {
    reqFields.push('asn_settings.items_mode');
  }
  if (validateOrderConfirmationRelated) {
    reqFields.push('order_confirmation_settings.items_mode');
  }

  if (validateOrderRelated && allow_create_order) {
    reqFields.push('profiles.request_profile_id');
    reqFields.push('profiles.document_profile_id');
    reqFields.push('purchase_order_endpoints.default.create_endpoint_id');
    reqFields.push('po_settings.items_mode_create_order');
  }

  if (validateOrderRelated && allow_change_order) {
    reqFields.push('profiles.change_request_profile_id');
    reqFields.push('profiles.change_document_profile_id');
    reqFields.push('purchase_order_endpoints.default.change_endpoint_id');
    reqFields.push('po_settings.items_mode_change_order');
  }

  if (validateOrderRelated && allow_cancel_order) {
    reqFields.push('profiles.cancel_request_profile_id');
    reqFields.push('purchase_order_endpoints.default.cancel_endpoint_id');
    reqFields.push('po_settings.items_mode_cancel_order');
  }

  if (validateOrderRelated && enabled_or_success) {
    reqFields.push('notification_endpoints.nfy_or_success_endpoint_id');
  }
  if (validateOrderRelated && enabled_or_failure) {
    reqFields.push('notification_endpoints.nfy_or_failure_endpoint_id');
  }
  if (validateOrderRelated && enabled_po_success) {
    reqFields.push('notification_endpoints.nfy_po_success_endpoint_id');
  }
  if (validateOrderRelated && enabled_po_failure) {
    reqFields.push('notification_endpoints.nfy_po_failure_endpoint_id');
  }

  const errors = {};
  reqFields.map(f => {
    if (!values[f]) {
      if (typeof fieldsMap[f] === 'string') {
        f = fieldsMap[f];
      } else {
        f = f[0].toUpperCase() + f.slice(1);
      }
      errors[f] = 'The %field% is required.'.replace(/%field%/g, f);
    }
  });

  if (validateCartRelated) {
    //Cart Encoding for cXML
    if (values['format'] === DATA_FORMAT_CXML) {
      reqFields.push('cart_settings.encoding');
    }

    //TOTALS
    let totalsCodeError = false;
    let totalsModeError = false;

    //fix it
    let i = 0;
    while (true) {
      if (typeof values['cart_totals[' + i + '].code'] === 'undefined') {
        break;
      }
      totalsCodeError = totalsCodeError || !values['cart_totals[' + i + '].code'];
      totalsModeError = totalsModeError || !values['cart_totals[' + i + '].mode'];
      i++;
    }

    if (totalsCodeError && totalsModeError) {
      errors.cart_totals = ['Please, fill correctly Cart Totals Information section.', 'Both Code and Mode are required.'];
    } else if (totalsCodeError) {
      errors.cart_totals = ['Please, fill correctly Cart Totals Information section.', 'The Code is required.'];
    } else if (totalsModeError) {
      errors.cart_totals = ['Please, fill correctly Cart Totals Information section.', 'The Mode is required.'];
    }
  }

  return errors;
};

const defaultClient = () => {
  return {
    id: session.getClientId(),
    type: session.getClientType(),
    code: session.getClientCode(),
  }
};

const handlers = (ex) => {
  const {state, provider} = ex;

  const loadClient = (id) => {
    state.setLoading(true);

    provider.getOne('client', {id: id})
      .then(({data}) => {
        state.setClient({
          id: data.id,
          type: data.type,
          code: data.code,
        });

        state.setError(false);
        state.setLoading(false);
      }).catch(error => {
        state.setError(error);
        state.setLoading(false);
      })
  };

  const loadCredential = (id) => {
    state.setLoading(true);

    provider.getOne('credential', {id: id})
      .then(({data}) => {
        state.setCredential({
          id: data.id,
          type: data.type,
          name: data.name,
          public_authorization: data.public_authorization,
        });
        state.setError(false);
        state.setLoading(false);
      }).catch(error => {
      state.setError(error);
      state.setLoading(false);
    })
  };

  return {
    loadClient: loadClient,
    loadCredential: loadCredential,
  }
};

const resetClientDependentFields = (formContext) => {
  for (const formFieldKey of clientDependentFields) {
    formContext.setValue(formFieldKey, '');
  }
};

export const ConnectionCreate = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(defaultClient());
  const [credential, setCredential] = useState(null);

  const state = {
    client:  client,        setClient: setClient,
    credential: credential, setCredential: setCredential,
    error:   error,    setError: setError,
    loading: loading,  setLoading: setLoading
  };

  const ex = {
    mode:      'create',
    props:     props,
    classes:   ViewStyles(),
    translate: useTranslate(),
    provider:  useDataProvider(),
    state:     state
  };

  return (
    <Create {...props} className={ex.classes.root} successMessage={ex.translate('app.connection.created')}>
      {EditForm(ex)}
    </Create>
  );
};

export const ConnectionEdit = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(defaultClient());
  const [credential, setCredential] = useState(null);

  const state = {
    client:  client,        setClient: setClient,
    credential: credential, setCredential: setCredential,
    error:   error,    setError: setError,
    loading: loading,  setLoading: setLoading
  };

  const ex = {
    mode:      'edit',
    props:     props,
    classes:   ViewStyles(),
    translate: useTranslate(),
    provider:  useDataProvider(),
    state:     state
  };

  return (
    <Edit {...props} className={ex.classes.root} successMessage={ex.translate('app.connection.edit')}>
      {EditForm(ex)}
    </Edit>
  );
};

const EditForm = (ex) => {
  const { mode, classes, translate } = ex;
  const disabled = ex.state.loading;

  //https://github.com/marmelab/react-admin/issues/2572
  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar validate={validate} deleteConditions={mode === 'edit' ? deletePermission : false}/>}
      defaultValues={mode === 'create' ? getFormDataFromUrl() : null}
    >

      <Actions
        className={classes.actions}
        listUrl="/connections"
        title={translate('app.connection.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
        duplicateConditions={mode === 'edit' ? duplicatePermission : false}
        saveConditions={mode === 'edit' ? editPermission : false}
      />

      <FormDataConsumer>
        {({formData}) => {
          if (!ex.state.client) {
            ex.state.setClient(formData.client);//Set initial client from connection
          }
          if (!ex.state.credential) {
            ex.state.setClient(formData.client);//Set initial client from connection
            ex.state.setCredential(formData.credential);//Set initial credential from connection
          }
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {formData =>
          <>
            <FormSectionClientSettings {...formData} disabled={disabled} ex={ex} client={ex.state.client} credential={ex.state.credential}  />
            <FormSectionCommonSettings {...formData} disabled={disabled} ex={ex} client={ex.state.client} mode={mode}/>

            {(formData.formData && (formData.formData.type === TYPE_ORDER_RECEIPT_REQUEST)) &&
              <>
                <FormSectionPurchaseOrderSettings  {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              </>
            }
            {(formData.formData && (formData.formData.type === TYPE_INVOICE_REQUEST)) &&
              <>
                <FormSectionInvoiceSettings  {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              </>
            }
            {(formData.formData && (formData.formData.type === TYPE_ASN_REQUEST)) &&
              <>
                <FormSectionAsnSettings  {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              </>
            }
            {(formData.formData && (formData.formData.type === TYPE_ORDER_CONFIRMATION_REQUEST)) &&
              <>
                <FormSectionOrderConfirmationSettings  {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              </>
            }

            <>
              <FormSectionProfileSettings  {...formData} disabled={disabled} ex={ex} client={ex.state.client} />
            </>

            <FormSectionAuthSettings {...formData} disabled={disabled} ex={ex} client={ex.state.client}  credential={ex.state.credential} />

            {(formData.formData && (formData.formData.type === TYPE_SETUP_REQUEST)) &&
            <>
              <FormSectionMiscSettings       {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              <FormSectionCustomerSettings   {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              <FormSectionCartSettings       {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              <FormSectionCartTotalsSettings {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
            </>
            }

            {(formData.formData && (formData.formData.type === TYPE_ORDER_RECEIPT_REQUEST)) &&
              <>
                <FormSectionNotificationsSettings  {...formData} disabled={disabled} ex={ex} client={ex.state.client}/>
              </>
            }

            <FormVariablesSettings  source="variables" ex={ex} formData={formData} />
          </>
        }
      </FormDataConsumer>

    </SimpleForm>
  );
};

const ClientDependentSection = ({client, ex, connection, credential}) => {
  if (!client || !client.code) {
    return null;
  }


  const gatewayUrl = getGatewayUrl(client);
  return (
    <dl className={ex.classes.formSimpleList}>
      <dt className="marginTop0"><label>{ex.translate('app.client.labels.gateway_url')}:</label></dt>
      <dd><Chip label={`${gatewayUrl}`}/>&nbsp;{connection.auth
        && connection.auth.request_credential_id
        && ConnectionTestField({ex: ex, gatewayUrl: gatewayUrl, connection: connection, credential: credential})}</dd>
    </dl>
  );
};

const ConnectionTestField = ({ex, gatewayUrl, connection, credential}) => {
  if (!connection || !connection.format || !credential || !credential.public_authorization) {
    return <></>;
  }

  let format = connection.format.toLowerCase();
  let url = `https://pcsf.cloud.punchoutexpress.com/simulator/cart/${format}.php?`;
  let params = [`gateway_url=${gatewayUrl}`, `type=${connection.type}`, `authorization=${credential.public_authorization}`];

  url = url + encodeURI(params.join('&'));

  return (
    <a href={url} target="_blank" className={ex.classes.formIconButton}>
      <span><RefreshIcon /></span>
      {ex.translate('app.connection.labels.test_button', {name: !!credential.name ? credential.name : ''})}
    </a>
  );
};



const FormSectionClientSettings = ({ formData, ex, disabled, client, credential }) => {
  // const form = useFormContext();
  // { watch, resetField, setValue }
  // const clientId = useWatch({ name: 'client_id' });

  const ClientDependentSectionContainer = ClientDependentSection({ex: ex, client: client, credential: credential, disabled: disabled, connection: formData});
  if (!session.hasAdminPermission()) {
    return ClientDependentSectionContainer;
  }

  // ------------------------------------------------------------------------------------//
  const formContext = useFormContext();
  formContext.watch((data, { name, type }) => {
    if (name === 'client_id'  && type === 'change') {
      // @todo: fix client_id on change //
      formContext.setValue('format', '');
      resetClientDependentFields(formContext);
      if (!!data.client_id && (!client || !client.id || client.id !== data.client_id)) {
        handlers(ex).loadClient(data.client_id);
      }
    }
  });
  // ------------------------------------------------------------------------------------//

  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.relations')}</div>
      <ClientField clientId={formData.client_id} disabled={disabled} ex={ex} afterElementContainer={ClientDependentSectionContainer} />
    </>
  );
};


const FormSectionCommonSettings = ({ formData, disabled, ex, mode }) => {
  // const onFormatChange = (e) => resetClientDependentFields(form);
  const formContext = useFormContext();
  formContext.watch((data, { name, type }) => {
    if (name === 'client_id' && type === 'change') {
      resetClientDependentFields(formContext);
    }
  });
  // ------------------------------------------------------------------------------------//

  //Edit
  let disabledFlag = true;////disabled || !!id;

  //hot-fix
  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.common_info')}</div>

      <BuyerField formData={formData} ex={ex}/>

      <SelectInput
                    // hot-fix after upgrade
                   // choices={toOptions([formData.type], 'app.connection.labels.')}
                   choices={[]}
                   source="type"
                   label={ex.translate('app.connection.labels.type')}
                   // hot-fix after upgrade - disabled values don't pass, so should use empty*
                   // disabled={disabledFlag}
                   required
                   emptyText={'app.connection.labels.' + formData.type}
                   emptyValue={formData.type}
      />

      <SelectInput choices={toOptions(AVAILABLE_CONNECTION_ENVS, 'app.connection.labels.envs.')}
                   source="env"
                   disabled={disabled}
                   label={ex.translate('app.connection.labels.env')}
                   required
      />

      <TextInput source="name" disabled={disabled} required label={ex.translate('app.connection.labels.name')}/>
      {mode !== 'create' &&
        <TextInput source="code" disabled={true} label={ex.translate('app.general.labels.guid')} />
      }
      {formData.type &&
        <SelectInput source="format" choices={toOptions(AVAILABLE_CONNECTION_FORMATS_PER_TYPE[formData.type])} disabled={disabled} required />
      }
      {!formData.type &&
        <TextInput helperText="app.connection.labels.select_type_hint" label="Format" source="format" disabled={false} required/>
      }

      <SelectInput choices={AVAILABLE_BASIC_STATUSES} source="status" disabled={disabled} label={ex.translate('app.connection.labels.status')} required/>
    </>
  );
};

const FormSectionAuthSettings = ({ formData, ex, disabled}) => {
  const translate = useTranslate();
  const source = 'auth';

  const applyMatchRule = !!formData && !!formData.apply_match_rule;
  const type = !!formData && !!formData.type ? formData.type : '';

  const onChange = e => {
    //load data
    const credentialId = (e.target && e.target.value) ? e.target.value : null;
    if (!!credentialId) {
      handlers(ex).loadCredential(credentialId)
    }
  };

  if (type === TYPE_INVOICE_REQUEST || type === TYPE_ASN_REQUEST || type === TYPE_ORDER_CONFIRMATION_REQUEST) {
    return (
      <>
        <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>
        <CredentialField formData={formData} ex={ex} source="auth.document_credential_id" onChange={onChange} label="app.connection.labels.document_credential" required={false} allowEmpty={true} credentialTypes={documentCredentialTypes} />
      </>
    );
  } else {
    return (
      <>
        <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>

        <CredentialField formData={formData} ex={ex} source="auth.request_credential_id" onChange={onChange}  label="app.connection.labels.request_credential" credentialTypes={requestCredentialTypes} />

        <BooleanInput parse={v => !!v ? 1 : 0} source="apply_match_rule"/>

        {applyMatchRule &&
          <>
            <TextInput source="match_rule" disabled={disabled} multiline resettable label={ex.translate('app.connection.labels.match_rule')}/>
            <div className={ex.classes.fieldNotes} >Matches a connection if returned result contains any value.<br />Use xpath rules for cXML format and json-path for OCI inbound requests correspondingly.<br /><br /><b>cXML Example:</b><br />/cXML/Request[1]/PunchOutSetupRequest[1]/Extrinsic[@name="UserEmail" and text()="test-user@punchoutcatalogs.com"]<br />/cXML/Request[1]/PunchOutSetupRequest[Extrinsic\[@name="UserEmail" and text()="qacxml@punchoutcatalogs.com"\] and BuyerCookie\[text() = "testBuyerCookie"\]]<br /><br /><b>OCI Example:</b><br />$..[?(@.business_unit=="bu2000")]<br/>$..[?(@.email=="test-user@punchoutcatalogs.com" && @.business_unit=="bu2000")]</div>
          </>
        }

      </>
    );
  }
};

const FormSectionMiscSettings = ({ formData, ex, client, disabled}) => {
  const translate = useTranslate();

  return (
    <>
      <div className={ex.classes.formSection} key="misc">{translate('app.connection.labels.misc')}</div>
        <SelectInput choices={AVAILABLE_CHOOSER_TARGETS} source="chooser_target" disabled={disabled} required  label={translate('app.connection.labels.chooser_target')} defaultValue="default"/>

        {client && client.type === TYPE_INTEGRATION &&
        <TextInput source="web_landing_page" label="app.connection.labels.web_landing_page"
                   helperText={translate('app.connection.notes.web_landing_page')}/>
        }
    </>
  );
};

const FormSectionCustomerSettings = ({ disabled, ex }) => {
  const translate = useTranslate();
  const source = 'customer';

  const fields = [];

  ['allow_create', 'allow_update', 'allow_guest', 'allow_signed'].map(k => {
    fields.push(<BooleanInput source={source + '.' + k} disabled={disabled} label={translate('app.connection.' + source + '.labels.' + k)} />);
  });

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>
      {fields}
    </>
  );
};

const FormSectionCartSettings = ({ formData, disabled, ex }) => {
  const translate = useTranslate();
  const source = 'cart_settings';

  const showEncoding  = (formData.type === TYPE_SETUP_REQUEST && formData.format === DATA_FORMAT_CXML);

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>

      <TextInput source={source + '.default_supplier_id'} disabled={disabled}  label={translate('app.connection.'+ source+ '.labels.default_supplier_id')} />
      <TextInput source={source + '.default_currency'} disabled={disabled}  label={translate('app.connection.'+ source+ '.labels.default_currency')} />
      <TextInput source={source + '.max_description_length'} disabled={disabled} label={translate('app.connection.'+ source+ '.labels.max_description_length')} />

      {showEncoding &&
      <SelectInput choices={AVAILABLE_CONNECTION_ENCODING_TYPES} source={source + '.encoding'} disabled={disabled} label={translate('app.connection.'+ source+ '.labels.encoding')} required/>
      }
    </>
  );
};

const FormSectionCartTotalsSettings = ({ formData, client, disabled, ex }) => {
  const translate = useTranslate();
  const source = 'cart_totals';

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>
      <ArrayInput source={source} label="" >
        <SimpleFormIterator>

          <SelectInput choices={AVAILABLE_CONNECTION_CART_TOTALS_CODES}  source="code" disabled={disabled} required label={translate('app.connection.'+ source +'.labels.code')}/>
          <SelectInput choices={AVAILABLE_CONNECTION_CART_TOTALS_MODES}  source="mode" disabled={disabled} required label={translate('app.connection.'+ source +'.labels.mode')}/>

          <TextInput source="classification_code" disabled={disabled}  label={translate('app.connection.'+ source +'.labels.classification_code')}/>
          <TextInput source="supplier_part_id" disabled={disabled}  label={translate('app.connection.'+ source +'.labels.supplier_part_id')}/>
          <TextInput source="supplier_id" disabled={disabled} label={translate('app.connection.'+ source +'.labels.supplier_id')}/>

        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

const FormSectionPurchaseOrderSettings = ({ formData, disabled, ex , client}) => {
  const translate = useTranslate();
  const source = 'po_settings';

  // const items_mode = !!formData && !!formData.po_settings ? formData.po_settings.items_mode : '';
  const allow_change_order = !!formData && !!formData.po_settings && !!formData.po_settings.allow_change_order;
  const allow_change_order_help = (!!client && client.type === TYPE_INTEGRATION )
    ? 'app.connection.' + source + '.notes.allow_change_order_shopify'
    : null;

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>

      <BooleanInput source="po_settings.allow_upsert_order" disabled={disabled} label={translate('app.connection.' + source + '.labels.allow_upsert_order')}
                    helperText={translate('app.connection.' + source + '.notes.allow_upsert_order')}
      />

      <BooleanInput source="po_settings.allow_change_order" disabled={disabled} label={translate('app.connection.' + source + '.labels.allow_change_order')}
                    helperText={!!allow_change_order_help ? translate(allow_change_order_help) : ''}
      />

      {allow_change_order && client && client.type === TYPE_INTEGRATION &&
        <BooleanInput source="po_settings.allow_change_replace" disabled={disabled} label={translate('app.connection.' + source + '.labels.allow_change_replace')} helperText={translate('app.connection.' + source + '.notes.allow_change_replace')} />
      }

      <BooleanInput source="po_settings.allow_cancel_order" disabled={disabled} label={translate('app.connection.' + source + '.labels.allow_cancel_order')}/>

      <>
        <ArrayInput source="po_settings.allowed_non_catalog_skus" label={translate('app.connection.' + source + '.labels.allowed_non_catalog_skus')}>
          <SimpleFormIterator>
            <TextInput disabled={disabled} label=""/>
          </SimpleFormIterator>
        </ArrayInput>
      </>
    </>
  );
};


const FormSectionInvoiceSettings = ({disabled, ex}) => {
  const translate = useTranslate();
  const source = 'inv_settings';

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>

      <SelectInput
        source="inv_settings.items_mode"
        choices={toOptions(AVAILABLE_BASE_ITEMS_MODES, 'app.connection.labels.inv_items_modes.')}
        label={translate('app.connection.labels.inv_items_mode')}
        helperText={translate('app.connection.notes.inv_items_mode')}
        required={true}
        disabled={disabled}
      />
    </>
  );
};

const FormSectionAsnSettings = ({disabled, ex}) => {
  const translate = useTranslate();
  const source = 'asn_settings';

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>

      <SelectInput
        source="asn_settings.items_mode"
        choices={toOptions(AVAILABLE_BASE_ITEMS_MODES, 'app.connection.labels.asn_items_modes.')}
        label={translate('app.connection.labels.asn_items_mode')}
        helperText={translate('app.connection.notes.asn_items_mode')}
        required={true}
        disabled={disabled}
      />
    </>
  );
};

const FormSectionOrderConfirmationSettings = ({disabled, ex}) => {
  const translate = useTranslate();
  const source = 'order_confirmation_settings';

  return (
    <>
      <div className={ex.classes.formSection} key={source}>{translate('app.connection.labels.' + source)}</div>

      <SelectInput
        source="order_confirmation_settings.items_mode"
        choices={toOptions(AVAILABLE_BASE_ITEMS_MODES, 'app.connection.labels.order_confirmation_items_modes.')}
        label={translate('app.connection.labels.order_confirmation_items_mode')}
        helperText={translate('app.connection.notes.order_confirmation_items_mode')}
        required={true}
        disabled={disabled}
      />
    </>
  );
};


// @todo: redo POC-2751
const FormSectionProfileSettings = ({ formData, disabled, ex, client }) => {
  const {type} = formData;

  if (type === TYPE_ORDER_RECEIPT_REQUEST) {
    return <FormSectionProfilesForTypeOrderRequestRequest formData={formData} disabled={disabled} ex={ex} client={client} />;
  } else if (type === TYPE_INVOICE_REQUEST) {
    return <FormSectionProfilesForTypeInvoiceRequest formData={formData} disabled={disabled} ex={ex} client={client} />;
  } else if (type === TYPE_ASN_REQUEST) {
    return <FormSectionProfilesForTypeAsnRequest formData={formData} disabled={disabled} ex={ex} client={client} />;
  } else if (type === TYPE_ORDER_CONFIRMATION_REQUEST) {
    return <FormSectionProfilesForTypeOrderConfirmationRequest formData={formData} disabled={disabled} ex={ex} client={client} />;
  } else {
    return <FormSectionProfilesForTypeSetupRequest formData={formData} disabled={disabled} ex={ex} client={client} />;
  }
};

const FormSectionProfilesForTypeOrderRequestRequest= ({ formData, disabled, ex, client}) => {
  const translate = useTranslate();

  let {po_settings} = formData;
  po_settings = po_settings || {};

  // ----
  const canShowDefaultCreateOrder = canShowDefaultSection(po_settings.items_mode_create_order || PO_ITEMS_MODE_DEFAULT);
  const canShowCatalogCreateOrder = canShowCatalogSection(po_settings.items_mode_create_order || PO_ITEMS_MODE_DEFAULT);
  const canShowAdhocCreateOrder = canShowAdhocSection(po_settings.items_mode_create_order || PO_ITEMS_MODE_DEFAULT);

  const canShowDefaultChangeOrder = canShowDefaultSection(po_settings.items_mode_change_order || PO_ITEMS_MODE_DEFAULT);
  const canShowCatalogChangeOrder = canShowCatalogSection(po_settings.items_mode_change_order || PO_ITEMS_MODE_DEFAULT);
  const canShowAdhocChangeOrder = canShowAdhocSection(po_settings.items_mode_change_order || PO_ITEMS_MODE_DEFAULT);

  const canShowDefaultCancelOrder = canShowDefaultSection(po_settings.items_mode_cancel_order || PO_ITEMS_MODE_DEFAULT);
  const canShowCatalogCancelOrder = canShowCatalogSection(po_settings.items_mode_cancel_order || PO_ITEMS_MODE_DEFAULT);
  const canShowAdhocCancelOrder = canShowAdhocSection(po_settings.items_mode_cancel_order || PO_ITEMS_MODE_DEFAULT);
  // ----

  return (
    <>

      <>
        <div className={ex.classes.formSection}>{translate('app.connection.labels.create_order_section')}</div>

        <SelectInput source="po_settings.items_mode_create_order" disabled={disabled}  choices={toOptions(AVAILABLE_PO_ITEMS_MODES, 'app.connection.labels.po_items_modes.')} label={translate('app.connection.labels.po_items_mode')} helperText={translate('app.connection.notes.po_items_mode')} required={true}/>

        <ProfileField formData={formData} type={TYPE_ORDER_RECEIPT_REQUEST} channel={CHANNEL_PUNCHOUT}
                      label={translate('app.profile.labels.request_profile')}
                      source="profiles.request_profile_id"
                      disabled={disabled} ex={ex} client={ex.state.client} required={true}
        />
        <ProfileField formData={formData} type={TYPE_PURCHASE_ORDER} channel={CHANNEL_INTEGRATION}
                      label={translate('app.profile.labels.document_profile')}
                      source="profiles.document_profile_id"
                      disabled={disabled} ex={ex} client={ex.state.client} required={true}
                      format={DATA_FORMAT_JSON}
        />

        {canShowDefaultCreateOrder &&
          <EndpointField formData={formData}
                         disabled={disabled} ex={ex} client={ex.state.client}
                         source="purchase_order_endpoints.default.create_endpoint_id"
                         label="app.connection.labels.endpoints.create_order_default"
                         required={true}
                         allowEmpty={true}
          />
        }

        {canShowCatalogCreateOrder &&
          <EndpointField formData={formData}
                         disabled={disabled} ex={ex} client={ex.state.client}
                         source="purchase_order_endpoints.catalog.create_endpoint_id"
                         label="app.connection.labels.endpoints.create_order_catalog"
                         required={false}
                         allowEmpty={true}
          />
        }

        {canShowAdhocCreateOrder &&
          <EndpointField formData={formData}
                         disabled={disabled} ex={ex} client={ex.state.client}
                         source="purchase_order_endpoints.adhoc.create_endpoint_id"
                         label="app.connection.labels.endpoints.create_order_adhoc"
                         required={false}
                         allowEmpty={true}
          />
        }
      </>


      {!!po_settings.allow_change_order &&
        <>
          <div className={ex.classes.formSection}>{translate('app.connection.labels.change_order_section')}</div>

          <SelectInput source="po_settings.items_mode_change_order" disabled={disabled}  choices={toOptions(AVAILABLE_PO_ITEMS_MODES, 'app.connection.labels.po_items_modes.')} label={translate('app.connection.labels.po_items_mode')} helperText={translate('app.connection.notes.po_items_mode')} required={true}/>

          <ProfileField formData={formData} type={TYPE_ORDER_RECEIPT_REQUEST} channel={CHANNEL_PUNCHOUT}
                        label={translate('app.profile.labels.change_request_profile')}
                        source="profiles.change_request_profile_id"
                        disabled={disabled} ex={ex} client={ex.state.client} required={true}
          />
          <ProfileField formData={formData} type={TYPE_PURCHASE_ORDER} channel={CHANNEL_INTEGRATION}
                        label={translate('app.profile.labels.change_document_profile')}
                        source="profiles.change_document_profile_id"
                        disabled={disabled} ex={ex} client={ex.state.client} required={true}
                        format={DATA_FORMAT_JSON}
          />

          {client && client.type === TYPE_INTEGRATION && !!po_settings.allow_change_replace &&
            <ProfileField formData={formData} type={TYPE_PURCHASE_ORDER} channel={CHANNEL_INTEGRATION}
                          label={translate('app.profile.labels.replace_cancel_document_profile')}
                          source="profiles.replace_cancel_document_profile_id"
                          disabled={disabled} ex={ex} client={ex.state.client}
                          format={DATA_FORMAT_JSON}
            />
          }

          {canShowDefaultChangeOrder &&
            <EndpointField formData={formData}
                           disabled={disabled} ex={ex} client={ex.state.client}
                           source="purchase_order_endpoints.default.change_endpoint_id"
                           label="app.connection.labels.endpoints.change_order_default"
                           required={true}
                           allowEmpty={true}
            />
          }

          {canShowCatalogChangeOrder &&
            <EndpointField formData={formData}
                           disabled={disabled} ex={ex} client={ex.state.client}
                           source="purchase_order_endpoints.catalog.change_endpoint_id"
                           label="app.connection.labels.endpoints.change_order_catalog"
                           required={false}
                           allowEmpty={true}
            />
          }

          {canShowAdhocChangeOrder &&
            <EndpointField formData={formData}
                           disabled={disabled} ex={ex} client={ex.state.client}
                           source="purchase_order_endpoints.adhoc.change_endpoint_id"
                           label="app.connection.labels.endpoints.change_order_adhoc"
                           required={false}
                           allowEmpty={true}
            />
          }
        </>
      }

      {!!po_settings.allow_cancel_order &&
        <>
          <div className={ex.classes.formSection}>{translate('app.connection.labels.cancel_order_section')}</div>

          <SelectInput source="po_settings.items_mode_cancel_order" disabled={disabled}  choices={toOptions(AVAILABLE_PO_ITEMS_MODES, 'app.connection.labels.po_items_modes.')} label={translate('app.connection.labels.po_items_mode')} helperText={translate('app.connection.notes.po_items_mode')} required={true}/>

          <ProfileField formData={formData} type={TYPE_ORDER_RECEIPT_REQUEST} channel={CHANNEL_PUNCHOUT}
                        label={translate('app.profile.labels.cancel_request_profile')}
                        source="profiles.cancel_request_profile_id"
                        disabled={disabled} ex={ex} client={ex.state.client} required={true}
          />

          <ProfileField formData={formData} type={TYPE_PURCHASE_ORDER} channel={CHANNEL_INTEGRATION}
                        label={translate('app.profile.labels.cancel_document_profile')}
                        source="profiles.cancel_document_profile_id"
                        disabled={disabled} ex={ex} client={ex.state.client}
                        format={DATA_FORMAT_JSON}
          />

          {canShowDefaultCancelOrder &&
            <EndpointField formData={formData}
                           disabled={disabled} ex={ex} client={ex.state.client}
                           source="purchase_order_endpoints.default.cancel_endpoint_id"
                           label="app.connection.labels.endpoints.cancel_order_default"
                           required={true}
                           allowEmpty={true}
            />
          }

          {canShowCatalogCancelOrder &&
            <EndpointField formData={formData}
                           disabled={disabled} ex={ex} client={ex.state.client}
                           source="purchase_order_endpoints.catalog.cancel_endpoint_id"
                           label="app.connection.labels.endpoints.cancel_order_catalog"
                           required={false}
                           allowEmpty={true}
            />
          }

          {canShowAdhocCancelOrder &&
            <EndpointField formData={formData}
                           disabled={disabled} ex={ex} client={ex.state.client}
                           source="purchase_order_endpoints.adhoc.cancel_endpoint_id"
                           label="app.connection.labels.endpoints.cancel_order_adhoc"
                           required={false}
                           allowEmpty={true}
            />
          }
        </>
      }

    </>
  );
};

const FormSectionProfilesForTypeSetupRequest= ({ formData, disabled, ex }) => {
  const translate = useTranslate();

  return (
    <>
      <div className={ex.classes.formSection}>{translate('app.connection.labels.setup_request_section')}</div>
      <ProfileField formData={formData} type={TYPE_SETUP_REQUEST} channel={CHANNEL_PUNCHOUT}
                    label={translate('app.profile.labels.request_profile')}
                    source="profiles.request_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
      />
      <ProfileField formData={formData} type={TYPE_TRANSFERRED_CART} channel={CHANNEL_PUNCHOUT}
                    label={translate('app.profile.labels.document_profile')}
                    source="profiles.document_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
      />

      <EndpointField formData={formData}
                     disabled={disabled} ex={ex} client={ex.state.client}
                     source="setup_request_endpoints.authorization.endpoint_id"
                     label="app.connection.labels.endpoints.authorization"
                     required={true}
                     allowEmpty={true}
      />
    </>
  );
}

const FormSectionProfilesForTypeInvoiceRequest= ({ formData, disabled, ex }) => {
  const translate = useTranslate();

  return (
    <>
      <div className={ex.classes.formSection}>{translate('app.connection.labels.invoice_request_section')}</div>
      <ProfileField formData={formData} type={TYPE_INVOICE_REQUEST} channel={CHANNEL_INTEGRATION}
                    label={translate('app.profile.labels.request_profile')}
                    source="profiles.request_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
      />
      <ProfileField formData={formData} type={TYPE_INVOICE} channel={CHANNEL_INTEGRATION}
                    label={translate('app.profile.labels.document_profile')}
                    source="profiles.document_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
                    format={DATA_FORMAT_CXML}
      />

      <EndpointField formData={formData}
                     disabled={disabled} ex={ex} client={ex.state.client}
                     source="invoice_endpoints.default.create_endpoint_id"
                     label="app.connection.labels.endpoints.create_invoice_default"
                     required={true}
                     allowEmpty={true}
      />
    </>
  );
}

const FormSectionProfilesForTypeAsnRequest= ({ formData, disabled, ex }) => {
  const translate = useTranslate();

  return (
    <>
      <div className={ex.classes.formSection}>{translate('app.connection.labels.asn_request_section')}</div>
      <ProfileField formData={formData} type={TYPE_ASN_REQUEST} channel={CHANNEL_INTEGRATION}
                    label={translate('app.profile.labels.request_profile')}
                    source="profiles.request_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
      />
      <ProfileField formData={formData} type={TYPE_ASN} channel={CHANNEL_INTEGRATION}
                    label={translate('app.profile.labels.document_profile')}
                    source="profiles.document_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
                    format={DATA_FORMAT_CXML}
      />

      <EndpointField formData={formData}
                     disabled={disabled} ex={ex} client={ex.state.client}
                     source="asn_endpoints.default.create_endpoint_id"
                     label="app.connection.labels.endpoints.create_asn_default"
                     required={true}
                     allowEmpty={true}
      />
    </>
  );
}

const FormSectionProfilesForTypeOrderConfirmationRequest= ({ formData, disabled, ex }) => {
  const translate = useTranslate();

  return (
    <>
      <div className={ex.classes.formSection}>{translate('app.connection.labels.order_confirmation_request_section')}</div>
      <ProfileField formData={formData} type={TYPE_ORDER_CONFIRMATION_REQUEST} channel={CHANNEL_INTEGRATION}
                    label={translate('app.profile.labels.request_profile')}
                    source="profiles.request_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
      />
      <ProfileField formData={formData} type={TYPE_ORDER_CONFIRMATION} channel={CHANNEL_INTEGRATION}
                    label={translate('app.profile.labels.document_profile')}
                    source="profiles.document_profile_id"
                    disabled={disabled} ex={ex} client={ex.state.client} required={true}
                    format={DATA_FORMAT_CXML}
      />

      <EndpointField formData={formData}
                     disabled={disabled} ex={ex} client={ex.state.client}
                     source="order_confirmation_endpoints.default.create_endpoint_id"
                     label="app.connection.labels.endpoints.create_order_confirmation_default"
                     required={true}
                     allowEmpty={true}
      />
    </>
  );
}

/**
 * enabled_or_success
 * enabled_or_failure
 * enabled_po_success
 * enabled_po_failure
 *
 * //
 * nfy_or_success_endpoint_id
 * nfy_or_failure_endpoint_id
 * nfy_po_success_endpoint_id
 * nfy_po_failure_endpoint_id
 * */
const FormSectionNotificationsSettings = ({ formData, disabled, ex }) => {
  const translate = useTranslate();
  const source = 'notifications';

  let {notifications} = formData;
  notifications = notifications || {};

  let filter = {source: 'email'};

  return (
  <>
    <div className={ex.classes.formSection}>{translate('app.connection.labels.notifications_section')}</div>

    <BooleanInput source="notifications.enabled_or_success" disabled={disabled} label={translate('app.connection.' + source + '.labels.enabled_or_success')}/>
    {!!notifications.enabled_or_success &&
      <EndpointField
        formData={formData}
        disabled={disabled}
        ex={ex}
        client={ex.state.client}
        source="notification_endpoints.nfy_or_success_endpoint_id"
        filter={filter}
      />
    }

    <BooleanInput source="notifications.enabled_or_failure" disabled={disabled} label={translate('app.connection.' + source + '.labels.enabled_or_failure')}/>
    {!!notifications.enabled_or_failure &&
      <EndpointField
        formData={formData}
        disabled={disabled}
        ex={ex}
        client={ex.state.client}
        source="notification_endpoints.nfy_or_failure_endpoint_id"
        filter={filter}
      />
    }

    <BooleanInput source="notifications.enabled_po_success" disabled={disabled} label={translate('app.connection.' + source + '.labels.enabled_po_success')}/>
    {!!notifications.enabled_po_success &&
      <EndpointField
        formData={formData}
        disabled={disabled}
        ex={ex}
        client={ex.state.client}
        source="notification_endpoints.nfy_po_success_endpoint_id"
        filter={filter}
      />
    }

    <BooleanInput source="notifications.enabled_po_failure" disabled={disabled} label={translate('app.connection.' + source + '.labels.enabled_po_failure')}/>
    {!!notifications.enabled_po_failure &&
      <EndpointField
        formData={formData}
        disabled={disabled}
        ex={ex}
        client={ex.state.client}
        source="notification_endpoints.nfy_po_failure_endpoint_id"
        filter={filter}
      />
    }
  </>
  );
};
