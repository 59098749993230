import {decode as tokenDecode} from '../lib/utils/jwt';
import {TYPE_INTEGRATION, TYPE_INTERNAL} from '../view/client/consts';
import {PERMISSION_ADMIN, PERMISSION_CLIENT_OWNER, PERMISSION_CLIENT_USER}  from '../view/user/consts';
import {AuthorizationError} from '../lib/errors';

const roles = new Map([
  [PERMISSION_ADMIN,        'admin'],
  [PERMISSION_CLIENT_USER,  'regular user'],
  [PERMISSION_CLIENT_OWNER, 'owner'],
]);

export default {
  getOriginalData() {
    if (!localStorage.getItem('original.auth.session')) {
      return false;
    }
    return JSON.parse(localStorage.getItem('original.auth.session'));
  },

  getData() {
    if (!localStorage.getItem('current.auth.session')) {
      return false;
    }
    return JSON.parse(localStorage.getItem('current.auth.session'));
  },

  setData(data) {
    if (!localStorage.getItem('current.auth.session')) {
      return false;
    }

    const currentData = JSON.parse(localStorage.getItem('current.auth.session'));

    currentData.firstname = data.firstname;
    currentData.lastname = data.lastname;
    currentData.email = data.email;

    localStorage.removeItem('current.auth.session');
    localStorage.setItem('current.auth.session', JSON.stringify(currentData));
  },

  getAccessToken() {
    const session = this.getData()
    return !!session.access_token ? session.access_token : null;
  },

  getTokenType() {
    const session = this.getData()
    return !!session.token_type ? session.token_type : '';
  },

  isLogged() {
    try {
      return this.isValidSessionData(this.getData())
    } catch (e) {
      return false;
    }
  },

  isActingOnBehalfOf() {
    try {
      return this.isLogged() && this.getIsOnBehalf();
    } catch (e) {
      return false;
    }
  },

  isValidSessionData(session) {
    if (!(
      (typeof session === 'object')
      && !!session.access_token
      && !!session.expires_in
      && !!session.token_type
      && !!session.role
      && !!session.email
      && !!session.clientId
      && !!session.clientType
    )) {
      return false;
    }

    const token = tokenDecode(session.access_token);
    if (!token) {
      return false;
    }

    var now = new Date();
    var utc = (new Date(now.getTime() + now.getTimezoneOffset() * 60000) / 1000);

    return (utc < token.exp);
  },

  login(response) {
    const session = this.toSessionData(response);

    localStorage.removeItem('current.auth.session');
    localStorage.setItem('current.auth.session', JSON.stringify(session));

    localStorage.removeItem('original.auth.session');
    localStorage.setItem('original.auth.session', JSON.stringify(session));

    return session;
  },

  switch(response) {
    const session = this.toSessionData(response);

    localStorage.removeItem('current.auth.session');
    localStorage.setItem('current.auth.session', JSON.stringify(session));
    return session;
  },

  switchBack() {
    const session = this.getOriginalData();

    localStorage.removeItem('current.auth.session');
    localStorage.setItem('current.auth.session', JSON.stringify(session));
    return session;
  },

  logout() {
    localStorage.removeItem('current.auth.session');
    localStorage.removeItem('original.auth.session');
    return true;
  },

  toSessionData(response) {
    const {access_token} = response;
    const data = tokenDecode(access_token);

    if (data === false) {
      throw new AuthorizationError('Invalid token specified. Could not decode.')
    }

    // const data = tokenData.payload;
    return {
      //token related fields
      access_token: response.access_token,
      token_type:   response.token_type,
      expires_in:   response.expires_in,

      // session related fields
      role: data.role,
      isOnBehalf: !!data.is_on_behalf,

      //
      userId: data.user.id,
      email:  data.user.email,
      firstname: data.user.firstname,
      lastname:  data.user.lastname,
      avatar:    data.user.avatar,

      // client related fields
      clientId:   data.client.id,
      clientType: data.client.type,
      clientCode: data.client.code,
      clientName: data.client.name,
    };
  },

  hasAdminPermission() {
    const session = this.getData();
    return (session.role === PERMISSION_ADMIN);
  },

  hasClientPermission() {
    const session = this.getData();
    return (session.role === PERMISSION_CLIENT_OWNER || session.role === PERMISSION_ADMIN);
  },

  hasClientUserPermission() {
    const session = this.getData();
    return (session.role === PERMISSION_CLIENT_USER || session.role === PERMISSION_CLIENT_OWNER || session.role === PERMISSION_ADMIN);
  },

  hasAnonymousPermission() {
    return true;
  },

  getUserId() {
    return this.getData().userId;
  },

  getUserEmail() {
    return this.getData().email;
  },

  getRole() {
    return this.getData().role;
  },

  getIsOnBehalf() {
    return !!this.getData().isOnBehalf;
  },

  getRoleName() {
    let role = this.getRole();
    return roles.has(role) ? roles.get(role) : role;
  },

  getClientId() {
    return this.getData().clientId;
  },

  getClientType() {
    return this.getData().clientType;
  },

  getClientCode() {
    return this.getData().clientCode;
  },

  getClientName() {
    return this.getData().clientName;
  },

  isClientInternal() {
    return this.getClientType() === TYPE_INTERNAL;
  },

  isClientIntegration() {
    return this.getClientType() === TYPE_INTEGRATION;
  }
}
