import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  FormDataConsumer,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
  TextField,
  SelectField,
  DateField,
  ReferenceField,
  FunctionField,
  BooleanInput,
  Show,
} from 'react-admin';

import {useTranslate} from 'ra-core';

import {deletePermission} from "./permissions";
import {ViewStyles} from '../../theme/skin/poc/styles';

import Toolbar from '../../components/poc/Toolbar';
import Actions from '../../components/poc/Actions';
import {ClientField} from '../../components/poc/Form/Fields';

import session from '../../app/session';
import config from "../../app/etc/config";
import {toOptions} from "../../lib/source";
import {AVAILABLE_KEY_TYPES} from "./consts";

function getValue(record, source) {
    let value = typeof record[source] !== 'undefined' ? record[source] : '';
    if (typeof value === 'string') {
      value = value.trim();
    }
    return value;
}

const validate = (values) => {
  const errors = {};
  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['Client is required'];
  }
  if (!values.type) {
    errors.type = ['Type is required'];
  }
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.body) {
    errors.body = ['Body is required'];
  }
  if (!values.hasOwnProperty('expires_never') && !values.expires_at) {
    errors.body = ['Expires At is required'];
  }
  return errors
};

export const KeyCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();
  return (
    <Create {...props} className={classes.root} successMessage={translate('app.key.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

const ShowActions = (props) => {
  const { basePath } = props;

  const translate = useTranslate();
  const classes = ViewStyles();
  return (
    <TopToolbar>
      <h1 className={classes.headerTopToolbar}>{translate('app.key.view')}</h1>
      <ListButton basePath={basePath} className={classes.button}/>
    </TopToolbar>
  );
};

export const KeyView = (props) => {
  const classes = ViewStyles();

  return (
    <Show className={classes.root} actions={<ShowActions basePath="/keys"/>} >
      <TabbedShowLayout>

        <Tab label="General">
          <TextField   className={classes.fieldWithLabel} source="id"/>
          <SelectField choices={toOptions(AVAILABLE_KEY_TYPES)} className={classes.fieldWithLabel} source="type"/>

          <TextField   className={classes.fieldWithLabel} source="name"/>
          <DateField   className={classes.fieldWithLabel} source="expires_at"/>
          <DateField   className={classes.fieldWithLabel} showTime source="created_at"/>

          {session.hasAdminPermission() &&
            <ReferenceField
              className={classes.fieldWithLabel}
              label="Client"
              reference="client"
              sortable={false}
              source="client_id"
              perPage={config.pagination.associated}
              sort={{field: 'name', order: 'ASC'}}
            >
              <TextField source="name"/>
            </ReferenceField>
          }
        </Tab>

        <Tab label="Body">
          <FunctionField className={classes.fieldWithLabel} source="body" component="pre" render={getValue} label=""/>
        </Tab>

      </TabbedShowLayout>
    </Show>
  )
};

export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar validate={validate} deleteConditions={mode === 'edit' ? deletePermission : false}/>}
    >
      <Actions
        className={classes.actions}
        listUrl="/keys"
        title={translate('app.key.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            {session.hasAdminPermission() &&
            <ClientField clientId={formData.client_id} ex={ex} />
            }

            <SelectInput source="type" choices={toOptions(AVAILABLE_KEY_TYPES)} key="type" label="Type" required/>
            <TextInput source="name"    label="Name" required/>

            <BooleanInput source="expires_never" />
            {!formData.expires_never &&
              <DateInput source="expires_at"/>
            }
            <TextInput source="body" multiline label="Body" required/>
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
}
