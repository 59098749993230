import React from 'react';

import {useShowController} from 'react-admin';

import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
} from '@mui/material';

import {makeStyles} from '@mui/styles';
import {useTranslate} from "ra-core";

import HeaderInformation from './components/header';
import LinesInformation from './components/lines';
import RelationsInformation from './components/relations';

import {Ssf} from './components/ssf';

const Spacer = () => <Box m={1}>&nbsp;</Box>;

export const DocumentBrief = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();
  const translate = useTranslate();

  if (!record) return null;
  return (
  <>
    <Card className={classes.root}>
      <CardContent>
        <HeaderInformation {...props} record={record} />

        {record.items &&
        <>
          <Spacer />
          <Typography variant="h4" gutterBottom>
            {translate('app.document.labels.line_items')}
          </Typography>
          <Box>
            <LinesInformation record={record} />
          </Box>
          <Spacer />
        </>
        }

        <Spacer />
        <Typography variant="h4" gutterBottom>
          {translate('app.document.labels.misc')}
        </Typography>
        <Grid container spacing={1}>
          {record.attributes &&
          <Grid item xs={3}>
            <Ssf type="attributes" record={record} container/>
          </Grid>
          }

          {record.flags &&
          <Grid item xs={3}>
            <Ssf type="flags" record={record} container/>
          </Grid>
          }

          {record.dates &&
          <Grid item xs={3}>
            <Ssf type="dates" record={record} container/>
          </Grid>
          }

          {record.amounts &&
          <Grid item xs={3}>
            <Ssf type="amounts" record={record} container header/>
          </Grid>
          }
        </Grid>

        {record.relations &&
          <RelationsInformation record={record} container/>
        }

      </CardContent>
    </Card>
  </>
  );
};

const useStyles = makeStyles({
  root: { margin: 'auto' },
  spacer: { height: 20 },
  documents: { margin: '10px 0' },
});

