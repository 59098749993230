// ---------------- HTTP REGISTRY -------------- //
export const TYPE_CXML_BUYER_ID  = 'cxml_buyer_id';
export const TYPE_CXML_SENDER_ID = 'cxml_sender_id';
export const TYPE_CXML_COMMON    = 'cxml_common';
export const TYPE_OCI_USERNAME   = 'oci_username';

export const AVAILABLE_TYPES = [
  TYPE_CXML_BUYER_ID,
  TYPE_CXML_SENDER_ID,
  TYPE_CXML_COMMON,
  TYPE_OCI_USERNAME,
];
