import React from 'react';
import {
  TextField,
  ReferenceField,
  TextInput,
  SelectInput,
  ReferenceInput,
  FunctionField,
  SelectField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';

import {deletePermission} from "./permissions";
import {formatClientName} from "../../components/poc/Grid/Fields";
import {AVAILABLE_TYPES} from "./consts";
import {toOptions} from "../../lib/source";
import session from '../../app/session';
import config from "../../app/etc/config";

const CredentialFilter  = props => {
  return (
    <ListFilter {...props}>
      <TextInput source="id:in" label="ID"/>
      <TextInput source="name:like" label="Name"/>

      <SelectInput choices={toOptions(AVAILABLE_TYPES, 'app.credential.labels.types.')} source="type:in" label="Type"/>

      {session.hasAdminPermission() &&
        <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
          <SelectInput optionText={formatClientName}/>
        </ReferenceInput>
      }

    </ListFilter>
  )
};

export const CredentialGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<CredentialFilter/>}
      resource="credential"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <SelectField source="type" choices={toOptions(AVAILABLE_TYPES, 'app.credential.labels.types.')}/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <TextField source="name"/>

      <Actions edit={true} delete={deletePermission}/>
    </ListWithLayout>
  )
};
