import session from '../../app/session'

export const deletePermission = (row) => {
  return (
    (!!row && !!row.is_system)
    || !row
    || !row.id
  ) ? false : session.hasClientUserPermission();
};

export const editPermission = (row) => {
  return session.hasClientUserPermission();
};
