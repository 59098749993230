import React from "react";
import {ArrayInput, required, SimpleFormIterator, TextInput} from "react-admin";

export const skipHandling = (authorization) => {
  return (!authorization || !authorization.method);
};

export default ({ source, formData, ex }) => {
  const {authorization} = formData;

  if (skipHandling(authorization)) {
    return <></>;
  }

  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.' + source)}</div>

      <ArrayInput source={source} label="" >
        <SimpleFormIterator>
          <TextInput source="code"  label="app.general.labels.code" validate={[required()]}/>
          <TextInput source="value" label="app.general.labels.value" validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>

    </>
  );
};
