import React from 'react';

import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  DateField,
  SingleFieldList,
  ChipField
} from 'react-admin';

import {TextArrayField} from '../../components/poc/TextArrayField';

export const BatchBrief = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Batch ID"/>
        <TextField source="attempt_number" label="Attempt #"/>

        <DateField source="created_at" label="Created At" showTime={true}/>
        <DateField source="updated_at" label="Updated At" showTime={true}/>

        <ReferenceField label="Profile"
                        resource="profile"
                        reference="profile"
                        source="profile_id"
                        sortable={false}
                        basePath="/profile"
        >
          <TextField source="name"/>
        </ReferenceField>

        <ReferenceField label="Connection"
                        resource="Connection"
                        reference="connection"
                        source="connection_id"
                        sortable={false}
                        basePath="/connection"
        >
          <TextField source="name"/>
        </ReferenceField>

        <TextArrayField source="entity_ids" label="Entity IDs">
          <SingleFieldList>
            <ChipField source="id" />
          </SingleFieldList>
        </TextArrayField>

        <TextField source="message" label="Message"/>
      </SimpleShowLayout>
    </Show>
  );
};

