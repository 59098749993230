import React, {useState, useEffect} from 'react';
//import {Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays';
import {
  Notification,
  //TextInput,
  useRedirect,
  useDataProvider,
  Loading,
  FormDataConsumer,
  Form
} from 'react-admin';
import {useTranslate, useNotify} from 'ra-core';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {ViewStyles} from '../../theme/skin/poc/styles';

import session from '../../app/session'
import {TYPE_INTEGRATION} from './consts';
import Actions from '../../components/poc/Actions';
import Error from '../../components/poc/Alert';
import {
  FormSectionContacts,
  FormSectionPlatform,
  FormSectionOci,
  FormSectionCommon,
  FormHeadersSection,
  FormSectionSecurity
} from './view';

const CompanyProfile = () => {
  const redirect = useRedirect();
  if (!session.hasClientUserPermission()) {
    redirect('/login');
  }

  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const classes = ViewStyles();


  const onSubmit = (props) => {
    let data = props

    delete data.is_system;
    delete data.type;
    delete data.info;

    dataProvider
      .update('client', {data: data})
      .then(() => {
        notify(translate('app.client.labels.profile_updated'));
      })
      .catch(error => {
        // failure side effects go here
        const msg = !!error.message ? error.message : 'An Alert Happened';
        notify(`${msg}`, 'warning');
      });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = ['The Name is required'];
    }
    if (!values.email) {
      errors.email = ['The Email is required'];
    }

    return errors
  };

  useEffect(() => {
    dataProvider.getOne('client', {id: session.getData().clientId})
      .then(({data}) => {
        ['status', 'code', 'created_at', 'updated_at'].map(i => { delete data[i]; });

        setClient(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, []);

  if (loading) return <Loading/>;
  if (error) return <Error message={error}/>;
  if (!client) return null;

  return (
    <div className={classes.root}>
      <Form
        mutators={{...arrayMutators}}
        onSubmit={onSubmit}
        validate={validate}
        defaultValues={client}
      >
        <Card>
          <CardContent>
            <Actions
              className={classes.actions}
              title={translate('app.client.labels.profile')}
            />

            <FormSectionCommon disabled={false} classes={classes} />

            {client.type === TYPE_INTEGRATION &&
              <FormSectionPlatform disabled={true} classes={classes} />
            }
            <FormSectionOci disabled={false} classes={classes} />
            <FormSectionContacts disabled={false} classes={classes} />
            <FormHeadersSection source="attributes"  classes={classes} disabled={false}  />

            <FormDataConsumer>
              {({ formData }) =>
                <FormSectionSecurity disabled={false} classes={classes} formData={formData} />
              }
            </FormDataConsumer>
          </CardContent>

          <CardActions>
            <Button color="primary" type="submit" variant="contained">Save</Button>
          </CardActions>
        </Card>
        <Notification/>
      </Form>
    </div>
  );
};

export default CompanyProfile;
