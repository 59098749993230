import React, {useState} from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  PasswordInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  required
} from 'react-admin';
import {useTranslate} from 'ra-core';

import session from '../../app/session';
import {deletePermission, editPermission} from './permissions';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField} from '../../components/poc/Form/Fields';
import {AVAILABLE_TYPES, TYPE_CXML_COMMON} from './consts';
import {toOptions} from '../../lib/source';

const isTypeCxmlCommon = (type) => (type === TYPE_CXML_COMMON);

const validate = (values) => {
  const errors = {};

  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.type) {
    errors.status = ['Type is required'];
  }

  if (!isTypeCxmlCommon(values.type) && !values['auth.username']) {
    errors.status = ['Username is required'];
  }
  if (!isTypeCxmlCommon(values.type) && !values['auth.password']) {
    errors.status = ['Password is required'];
  }

  return errors
};

const HeadersSection = ({source, disabled}) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <>
      <div className={classes.formSection}>{translate('app.credential.labels.' + source)}</div>
      <ArrayInput source={source} label="">
        <SimpleFormIterator>
          <TextInput source="domain"   disabled={disabled} label={translate('app.credential.headers.cxml_domain')}  validate={[required()]} />
          <TextInput source="identity" disabled={disabled} label={translate('app.credential.headers.cxml_identity')}  validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export const CredentialCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.credential.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

export const CredentialEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit{...props} className={classes.root} successMessage={translate('app.credential.updated')}>
      {EditForm(props, 'edit')}
    </Edit>
  )
};

export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();
  const [loading, setLoading] = useState(false);

  const canShowCommonInfo = (formData) => {
    return (formData && isTypeCxmlCommon(formData.type));
  };

  const credentialFieldLabel = (formData, key = 'username') => {
    const type = formData && formData.type ? formData.type : '';
    return translate(`app.credential.labels.creds.${type}.${key}`)
  };

  const disabled = !!loading;

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar
        validate={validate}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />}
    >
      <Actions
        className={classes.actions}
        listUrl="/credentials"
        title={translate('app.credential.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
        saveConditions={mode === 'edit' ? editPermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            {session.hasAdminPermission() &&
              <ClientField clientId={formData.client_id} ex={ex} />
            }

            <div className={ex.classes.formSection}>{ex.translate('app.general.labels.common_info')}</div>
            <SelectInput source="type" disabled={disabled} choices={toOptions(AVAILABLE_TYPES, 'app.credential.labels.types.')}  label={ex.translate('app.general.labels.type')} required />
            <TextInput source="name" disabled={disabled} label={ex.translate('app.general.labels.name')} required />
            <TextInput source="description" disabled={disabled} label={ex.translate('app.general.labels.description')} multiline/>

            {formData && formData.type && canShowCommonInfo(formData) &&
              <>
                <HeadersSection source="headers.from"   disabled={disabled} />
                <HeadersSection source="headers.to"     disabled={disabled} />
                <HeadersSection source="headers.sender" disabled={disabled} />

                <div className={ex.classes.formSection}>{translate('app.credential.headers.cxml_common')}</div>
                <PasswordInput source={'auth.password'} disabled={disabled} label={credentialFieldLabel(formData, 'password')} autocomplete="off" list="autocompleteOff" placeholder={credentialFieldLabel(formData, 'password')}  />
              </>
            }

            {formData && formData.type && !canShowCommonInfo(formData) &&
              <>
                <div className={ex.classes.formSection}>{translate('app.general.labels.authorization')}</div>
                <TextInput source={'auth.username'} disabled={disabled} label={credentialFieldLabel(formData, 'username')} autocomplete="off" list="autocompleteOff" placeholder={credentialFieldLabel(formData, 'username')} required />
                <PasswordInput source={'auth.password'} disabled={disabled} label={credentialFieldLabel(formData, 'password')} autocomplete="off" list="autocompleteOff" placeholder={credentialFieldLabel(formData, 'password')} required  />
              </>
            }
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
};
