import React from 'react';
import { Loading } from 'react-admin';
import { Grid } from '@mui/material';
import hookIntoProps from 'hook-into-props'
import {useDataProvider, useTranslate, useNotify} from 'ra-core';
import {DashboardStyles} from "../../../theme/skin/poc/styles";

import ContainerAbstract from "../ContainerAbstract";
import DocsSummaryGraphFilters from "./DocsSummaryGraphFilters";
import DocsSummaryGraphReport from "./DocsSummaryGraphReport";
import {MODE_AMOUNTS, MODE_TRANSACTIONS, SELECTOR_TIME_LAST_YEAR} from "../consts";
import DocumentTypesSummaryBars from "./DocsSummaryBlocksReport";

class DocsSummaryGraphContainer extends ContainerAbstract {
  getShowFilters() {
    return !!this.props.showFilters;
  }

  render() {
    if (this.getLoading()) {
      return (<Loading />);
    }

    return (
      <Grid container spacing={4}>
        {this.getShowFilters() &&
          <div className={this.css.graphFilterContainer}>
            <DocsSummaryGraphFilters setTime={this.setSelectedTime} getTime={this.getSelectedTime} caption={this.props.filterCaption}/>
          </div>
        }

        <div className={this.css.graphBarContainer}>
          <Grid container spacing={4}>
            <DocumentTypesSummaryBars getRecords={this.getRecords} getTime={this.getSelectedTime}/>
          </Grid>
        </div>

        <div className={this.css.graphLinearContainer}>
          {this.caption(this.props.graphAmountsCaption, this.props.graphAmountsHint)}
          <DocsSummaryGraphReport getRecords={this.getRecords} getTime={this.getSelectedTime} mode={MODE_AMOUNTS}/>
        </div>

        <div className={this.css.graphLinearContainer}>
          {this.caption(this.props.graphNumbersCaption, this.props.graphNumbersHint)}
          <DocsSummaryGraphReport getRecords={this.getRecords} getTime={this.getSelectedTime} mode={MODE_TRANSACTIONS}/>
        </div>
      </Grid>
    );
  }

  getSelectedTimeDefault() {
    return !!this.props.defaultSelectedTime ? this.props.defaultSelectedTime : SELECTOR_TIME_LAST_YEAR;
  }
};

/**                                                              *
 *  ------------------------------------------------------------ *
 *                                                               */
const useHooks = () => ({
  translate: useTranslate(),
  styles: DashboardStyles(),
  dataProvider: useDataProvider(),
  notify: useNotify(),
});

export default hookIntoProps(useHooks)(DocsSummaryGraphContainer);
