import React from 'react';
import hookIntoProps from 'hook-into-props'
import {useDataProvider, useTranslate, useNotify} from 'ra-core';
import { Line } from 'react-chartjs-2';

import SummaryAbstract from '../SummaryAbstract';
import {DashboardStyles} from "../../../theme/skin/poc/styles";

import {
  SELECTOR_TIME_LAST_30_DAYS,
  SELECTOR_TIME_LAST_7_DAYS,
  MODE_AMOUNTS,
  COLORS
} from "../consts";

import {formatToYYYYMMDD} from "../../../lib/dates";

class DocsSummaryGraphReport extends SummaryAbstract {
  constructor(props) {
    super(props);

    this.types = [];

    if (props.mode !== MODE_AMOUNTS) {
      this.types.push({'name': 'all', 'color': COLORS.all});
    }

    this.types = this.types.concat([
      {'name': 'transferred_cart', 'color': COLORS.transferred_cart},
      {'name': 'purchase_order',   'color': COLORS.purchase_order},
      {'name': 'invoice',          'color': COLORS.invoice},
      {'name': 'asn',              'color': COLORS.asn},
    ]);
  }

  isSplitByDay() {
    return (this.getTime() === SELECTOR_TIME_LAST_30_DAYS || this.getTime() === SELECTOR_TIME_LAST_7_DAYS);
  }

  formatDate(d) {
    return formatToYYYYMMDD(d);
  }

  fd(d) {
    return d;
  }

  getDefaultData(records = []) {
    let data = {
      labels: records.map(row => this.fd(row.date)),
      datasets: [],
      custom_date_map: {},
      plugins: {
        legend: {
          display: true,
          labels: {
            color: 'rgb(255, 99, 132)'
          }
        }
      }
    };

    this.types.forEach((t, idx) => {
      data.datasets.push({
        label: t.name,
        fill: false,
        borderColor: t.color,
        data: records.map(r => Math.random() * 10000),
      });
    });

    return data;
  }

  render() {
    const data = this.summarize();

    return (
      <div className={this.css.chartContainer}>
        <Line data={data} options={this.getLinearGraphOptions()} />
      </div>
    );
  }

  summarize() {
    let res = this.getRecords()
      .reduce(
        (accumulator, row) => this.summarizeRow(accumulator, row),
        this.getDefaultData(this.getRecords())
      );

    return res;
  }

  summarizeRow(accumulator, row) {
    let idx = accumulator.labels.indexOf(this.fd(row.date));
    if (idx === -1) {
      console.log('INVALID DATE FOR DATASET: ', this.fd(row.date));
      return accumulator;
    }

    let documentsKey = this.props.mode === MODE_AMOUNTS ? 'documents_amount' : 'documents_number';
    let transactionsKey = this.props.mode === MODE_AMOUNTS ? 'transactions_amount' : 'transactions_number';

    this.types.forEach((t, col_idx) => {
      accumulator.datasets[col_idx].data[idx] = (
          parseFloat(row[documentsKey][t.name]) + parseFloat(row[transactionsKey][t.name])
      ).toFixed(2);
    });

    return accumulator;
  }

  getLinearGraphOptions() {
    return {
      layout: {
        padding: 0
      },

      padding:"0px",
      responsive: true,
      maintainAspectRatio: false,
      animation: false,

      legend:{
        display: true,
      },

      //cornerRadius: 20,
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: true,
        borderWidth: 1,
        displayColors: true,
      },

      scales: {
        xAxes: [
          {
            barThickness: 12,
            maxBarThickness: 10,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            // ticks: {
            //   //fontColor: palette.text.secondary
            // },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              //fontColor: palette.text.secondary,
              beginAtZero: true,
              min: 0
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              //color: palette.divider,
              drawBorder: false,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
              //zeroLineColor: palette.divider
            }
          }
        ]
      }
    };
  }
};

/**                                                              *
 *  ------------------------------------------------------------ *
 *                                                               */
const useHooks = () => ({
  translate: useTranslate(),
  styles: DashboardStyles(),
  dataProvider: useDataProvider(),
  notify: useNotify(),
});

export default hookIntoProps(useHooks)(DocsSummaryGraphReport);
