import React, { useState } from 'react';
import {Create, Edit, SimpleForm, TextInput, FormDataConsumer, useRecordContext} from 'react-admin';
import {useTranslate} from 'ra-core';
import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField} from '../../components/poc/Form/Fields';
import {deletePermission, duplicatePermission, editPermission} from './permissions';
import session from '../../app/session';

/**
 * @See: https://react-datasheet-grid.netlify.app/docs/getting-started
 * @See: https://react-datasheet-grid.netlify.app/docs/controlling-the-grid
 * @todo: set adjustable columns width via mouse
 * @See: replace clunky grid with any like: https://www.ag-grid.com/react-data-grid/column-state/
 */
import {DataSheetGrid, keyColumn, textColumn} from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
// import {useFormContext} from "react-hook-form";

const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

const columns = alphabet.map(l => {return {...keyColumn(l, textColumn), title: l, width: 1, minWidth: 200}});

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.client_id && session.hasAdminPermission()) {
    errors.client_id = ['Client is required'];
  }
  return errors
};

export const TableCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.table.created')}>
      {TableForm(props, 'create')}
    </Create>
  )
};

export const TableEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit {...props} className={classes.root} successMessage={translate('app.table.updated')}>
      {TableForm(props, 'edit')}
    </Edit>
  )
};

export const TableForm = (props, mode = 'create') => {
  const classes = ViewStyles();

  const translate = useTranslate();
  const [sheetChanged, setSheetChanged] = useState(false);
  const [sheetData, setSheetData] = useState([{}, {}, {}, {}, {}]);

  const onSheetChange = (data) => {
    setSheetChanged(true);
    return setSheetData(data);
  };

  const transform = (data) => {
    if (!sheetChanged) {
      return data;
    }

    data.rows = Array.isArray(sheetData) ? sheetData : [];
    data.rows = data.rows.map(obj => {
      const filtered = Object.entries(obj).filter(([key, value]) => (typeof value === 'string' && value !== ''));
      return Object.fromEntries(filtered);
    });

    data.rows = data.rows.filter(r => !!Object.keys(r).length);
    return data;
  };

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };


  return (
  <SimpleForm
    id="viewForm"
    toolbar={<Toolbar
      validate={validate}
      transform={transform}
      deleteConditions={mode === 'edit' ? deletePermission : false}
    />}
  >

    <Actions
      className={classes.actions}
      listUrl="/tables"
      title={translate('app.table.' + mode)}
      deleteConditions={mode === 'edit' ? deletePermission : false}
      duplicateConditions={mode === 'edit' ? duplicatePermission : false}
      // saveConditions={mode === 'edit' ? editPermission : false}
    />

    <FormDataConsumer>
      {({formData}) =>
        <>
          <div className={classes.formSection} key="basic">{translate('app.general.labels.common_info')}</div>
          <TextInput source="name" required/>

          {session.hasAdminPermission() &&
          <ClientField clientId={formData.client_id} ex={ex} />
          }
        </>
      }
    </FormDataConsumer>

    <div className={classes.formSection} key="basic">{translate('app.general.labels.sheet_info')}</div>
    <DataSheetGridContainer rows={sheetData} onChange={onSheetChange} columns={columns} isChanged={sheetChanged}/>
  </SimpleForm>
  )
};


const DataSheetGridContainer = ({rows, onChange, columns, isChanged}) => {
  const record = useRecordContext();

  if (isChanged) {
    return <DataSheetGrid value={rows} onChange={onChange} columns={columns} />;
  } else {
    return <DataSheetGrid value={!!record ? record.rows : []} onChange={onChange} columns={columns} />;
  }
}
