import {
  CHANNEL_API,
  CHANNEL_PUNCHOUT,
  CHANNEL_INTEGRATION,

  TYPE_SETUP_REQUEST,
  TYPE_AUTHORIZATION,
  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_INVOICE_REQUEST,
  TYPE_ASN_REQUEST,
  TYPE_ORDER_CONFIRMATION_REQUEST,
  TYPE_OTHER_REQUEST,
} from '../../app/etc/consts';

import {
  TYPE_PURCHASE_ORDER,
  TYPE_TRANSFERRED_CART,
  TYPE_ASN,
  TYPE_INVOICE,
  TYPE_ORDER_CONFIRMATION,
  TYPE_OTHER,
} from '../document/consts';

import {DATA_FORMAT_XML} from '../connection/consts';

export const AVAILABLE_TRANSACTION_CHANNELS = [
  CHANNEL_PUNCHOUT,
  CHANNEL_API,
  CHANNEL_INTEGRATION,
];

export const STATUS_SUCCESS = 'success';
export const STATUS_FAILURE = 'failure';

export const ACTION_REQUEST  = 'request';
export const ACTION_RESPONSE = 'response';
export const ACTION_REDIRECT = 'redirect';

export const TYPE_CLIENT_REGISTRATION   = 'client_registration';
export const TYPE_CLIENT_UNREGISTRATION = 'client_unregistration';
export const TYPE_CLIENT_NOTIFICATION   = 'client_notification';

export const DATA_FORMAT_CXML  = 'cXML';
export const DATA_FORMAT_OCI   = 'OCI';
export const DATA_FORMAT_UBL   = 'UBL';
export const DATA_FORMAT_XCBL  = 'xCBL';
export const DATA_FORMAT_JSON  = 'JSON';

export const AVAILABLE_TRANSACTION_STATUSES = [
  STATUS_SUCCESS,
  STATUS_FAILURE,
];

export const AVAILABLE_TRANSACTION_ACTIONS = [
  ACTION_REQUEST,
  ACTION_RESPONSE,
  ACTION_REDIRECT,
];

export const AVAILABLE_TRANSACTION_TYPES = [
  TYPE_SETUP_REQUEST,
  TYPE_AUTHORIZATION,
  TYPE_TRANSFERRED_CART,

  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_PURCHASE_ORDER,

  TYPE_INVOICE_REQUEST,
  TYPE_INVOICE,
  TYPE_ASN_REQUEST,
  TYPE_ASN,
  TYPE_ORDER_CONFIRMATION_REQUEST,
  TYPE_ORDER_CONFIRMATION,

  TYPE_OTHER_REQUEST,
  TYPE_OTHER,

  TYPE_CLIENT_REGISTRATION,
  TYPE_CLIENT_UNREGISTRATION,
  TYPE_CLIENT_NOTIFICATION,
];

export const AVAILABLE_TRANSACTION_FORMATS = [
  DATA_FORMAT_CXML,
  DATA_FORMAT_OCI,
  DATA_FORMAT_UBL,
  DATA_FORMAT_XCBL,
  //
  DATA_FORMAT_JSON,
  DATA_FORMAT_XML,
];
