export const STATUS_ACTIVE   = 'active';
export const STATUS_INACTIVE = 'inactive';

export const STATUS_ACTIVE_LABEL   = 'Active';
export const STATUS_INACTIVE_LABEL = 'Inactive';

export const AVAILABLE_BASIC_STATUSES = [
  {id: STATUS_ACTIVE, name: STATUS_ACTIVE_LABEL},
  {id: STATUS_INACTIVE, name: STATUS_INACTIVE_LABEL},
];

export const CHANNEL_PUNCHOUT    = 'punchout';
export const CHANNEL_API         = 'api';
export const CHANNEL_INTEGRATION = 'integration';//@todo: fix in the frontend transactions


export const TYPE_AUTHORIZATION         = 'authorization';
export const TYPE_SETUP_REQUEST         = 'setup_request';
export const TYPE_ORDER_RECEIPT_REQUEST = 'order_receipt_request';
export const TYPE_INVOICE_REQUEST       = 'invoice_request';
export const TYPE_ASN_REQUEST           = 'asn_request';
export const TYPE_ORDER_CONFIRMATION_REQUEST = 'order_confirmation_request';
export const TYPE_OTHER_REQUEST         = 'other_request';
