import React from 'react';
import {CLIENT_ID_ALL} from "../client/consts";
import session from "../../app/session";
import {
  SELECTOR_TIME_CURRENT_MONTH,
  SELECTOR_TIME_LAST_30_DAYS,
  SELECTOR_TIME_LAST_7_DAYS,
  SELECTOR_TIME_LAST_YEAR
} from "./consts";
import {formatToYYYYMMDD} from '../../lib/dates';

class ContainerAbstract extends React.Component {
  constructor(props) {
    super(props);
    //
    this._ = props.translate;
    this.css = props.styles;
    this.notify = props.notify;
    this.dataProvider = props.dataProvider;

    //
    this.state = {
      selected_time: this.getSelectedTimeDefault(),
      selected_client_id: session.hasAdminPermission() ? (!!this.props.selectedClientId ? this.props.selectedClientId : CLIENT_ID_ALL) : null,

      filters: {
        time: this._getTimeDefault(),
      },

      records: [],
      loading: true,
      error: null,
    };

    //
    this.setLoading = this.setLoading.bind(this);
    this.getLoading = this.getLoading.bind(this);
    //
    this.notifyError = this.notifyError.bind(this);

    //
    this.setSelectedTime = this.setSelectedTime.bind(this);
    this.getSelectedTime = this.getSelectedTime.bind(this);
    //
    this.setRecords = this.setRecords.bind(this);
    this.getRecords = this.getRecords.bind(this);

    this.getFilters = this.getFilters.bind(this);
  }

  toValue(value) {
    return (value && value.target && value.target.value)? value.target.value : value;
  }

  setSelectedTime(value) {
    this.setState({selected_time: this.toValue(value)}, this.load);
  }

  getSelectedTime() {
    return this.state.selected_time;
  }

  getSelectedTimeDefault() {
    return SELECTOR_TIME_CURRENT_MONTH;
  }

  setSelectedClientId(value) {
    this.setState({selected_client_id: this.toValue(value)}, this.load);
  }

  getSelectedClientId() {
    return this.state.selected_client_id;
  }

  setRecords(value) {
    this.setState({records: this.toValue(value)});
  }

  getRecords() {
    return this.state.records;
  }

  setLoading(value) {
    this.setState({loading: this.toValue(value)});
  }

  getLoading() {
    return this.state.loading;
  }

  setFilters(value) {
    this.setState({filters: this.toValue(value)});
  }

  formatDate(date) {
    return formatToYYYYMMDD(date);
  }

  getFilters() {
    let d = new Date();
    let filters = {};

    if (this.getSelectedTime() == SELECTOR_TIME_CURRENT_MONTH) {
      const monthStartDate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-01';
      filters['time'] = 'day';
      filters['date:min'] = this.formatDate(monthStartDate);
    } else if (this.getSelectedTime() == SELECTOR_TIME_LAST_7_DAYS) {
      filters['time'] = 'day';
      filters['date:min'] = this.formatDate(d.setDate(d.getDate() - 7));
      filters['date:max'] = this.formatDate(new Date());
    } else if (this.getSelectedTime() == SELECTOR_TIME_LAST_30_DAYS) {
      filters['time'] = 'day';
      filters['date:min'] = this.formatDate(d.setDate(d.getDate() - 30));
      filters['date:max'] = this.formatDate(new Date());
    } else if (this.getSelectedTime() == SELECTOR_TIME_LAST_YEAR) {
      filters['time'] = 'month';
      filters['date:min'] = this.formatDate(d.setDate(d.getDate() - 365));
      filters['date:max'] = this.formatDate(new Date());
    } else if (this.getSelectedTime().indexOf('current_') !== -1) {
      filters['time'] = this.getSelectedTime().replace('current_', '');
    }

    if (this.getSelectedClientId()) {
      filters['client_id:in'] = this.getSelectedClientId();
    }

    return filters;
  }

  notifyError(msg, type = 'error') {
    this.notify(msg, type);
  }

  componentDidMount() {
    this.load();
  }

  verifyLoadedData(data) {
    if (data && data.error) {
      this.notifyError(data.error);
    } else if (data && data[0] && data[0].id === 'error') {
      this.notifyError(data[0].message);
    } else {
      return false;
    }
    return true;
  }

  load() {
    this.setLoading(true);

    this.dataProvider.getList('report/statistic', this.getFilters())
      .then(({data}) => {
        if (this.verifyLoadedData(data)) {
          this.setLoading(false);
          return;
        }

        this.setRecords(data);
        this.setLoading(false);
      })
      .catch(() => {
        this.setLoading(false);
        this.notifyError('An Error happened');
      })
  }

  render() {
    return null;
  }

  _getTimeDefault() {
    return this.getSelectedTimeDefault()
      .replace('current_', '')
      .replace('last_30_days', 'day')
      .replace('last_7_days', 'day')
     ;
  }

  caption(caption, hint) {
    return (
    <>
      <h2 className={this.css.graphLabel}>{caption}</h2>
      <h6 className={this.css.graphHint}>{hint}</h6>
    </>
    );
  }
};

export default ContainerAbstract;
