import palette from '../palette';

export default {
  root: {
    //buttons
    '&button:hover': {
      backgroundColor: palette.primary.highlight + ' !important'
    },

    //text buttons
    '&.MuiButton-text:hover': {
      color: palette.primary.highlight + ' !important'
    },
    '&.MuiButton-text:hover > span > div': {
      color: palette.primary.highlight + ' !important'
    },
    '&.MuiButton-text.MuiButton-textSizeSmall:hover': {
      backgroundColor: 'transparent'
    },
  },
  contained: {
    boxShadosw:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '&:hover': {
      backgroundColor: palette.primary.highlight + ' !important'
    },
    '&$disabled': {
      color: palette.white
    }
  }
};
