import React from "react";
import {SelectInput, TextInput} from "react-admin";
import {AVAILABLE_BASIC_STATUSES} from "../../../app/etc/consts";

export default ({ ex }) => {
  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.general.labels.common_info')}</div>

      <SelectInput source="status" label="app.general.labels.status"  choices={AVAILABLE_BASIC_STATUSES} required />
      <TextInput   source="name"   label="app.general.labels.name"   required />
    </>
  );
};
