import session from '../../app/session'

export const viewPermission = (row) => {
  return (!!row && !!row.id) ? session.hasClientUserPermission() : false;
};

export const deletePermission = (row) => {
  return (!!row && !!row.id) ? session.hasClientUserPermission() : false;
};

