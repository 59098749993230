import React from 'react';
import {Button, useDataProvider, useNotify, useRecordContext, useRedirect, useResourceContext} from 'react-admin';
import SaveIcon from '@mui/icons-material/Save';
import config from "../../../../../app/etc/config";
import {useTranslate} from "ra-core";
import {getResourcePath} from '../../../../../provider/dataProvider';

export default (props) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const {conditions} = props;
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  let show = true;

  if (typeof conditions === 'function') {
    show = conditions(record);
  }

  let resourceUrl = getResourcePath(resource);

  const duplicate = () => {
      dataProvider
        .create(resourceUrl + '/duplicate/' + record.id, {})
        .then(() => {
          // success side effects go here
          redirect('/' + config.api.listing[resource]);
          notify(translate('app.' + resource + '.label') + ' duplicated');
        })
        .catch(error => {
          // failure side effects go here
          notify(translate('app.' + resource + '.label') + ` duplicate error: ${error.message}`, 'warning');
        });
  };

  return (
    <>
      {show &&
      <Button onClick={duplicate} color="secondary" label="Duplicate" startIcon={<SaveIcon/>}  />}
    </>
  )
};
