import session from '../../app/session'

export const deletePermission = (row) => {
  return (!!row && !!row.id) ? session.hasClientUserPermission() : false;
};

export const duplicatePermission = (row) => {
  return (!!row && !!row.id) ? session.hasClientUserPermission() : false;
};

export const editPermission = (row) => {
  return (!!row && !!row.id && !row.is_system) ? session.hasClientUserPermission() : false;
};
