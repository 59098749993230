import React from 'react';
import {
  TextField,
  SelectField,
  ReferenceField,
  EditButton,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput, FunctionField
} from 'react-admin';
import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';

import {deletePermission} from "./permissions";

import session from '../../app/session';
import config from "../../app/etc/config";
import {formatClientName} from "../../components/poc/Grid/Fields";

const WhitelistRefererFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <TextInput source="host:like" label="Host"/>
    <DateInput source="created_at"/>
    <DateInput source="updated_at"/>
  </ListFilter>
);

export const WhitelistRefererGrid = props => {
  return (
    <ListWithLayout
      {...props}
      filters={<WhitelistRefererFilter/>}
      resource="referer"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <ReferenceField label="Connection" reference="connection" source="connection_id" sortable={false}>
        <TextField source="name"/>
      </ReferenceField>

      <TextField source="host"/>

      <Actions edit={false} delete={deletePermission}/>
    </ListWithLayout>
  );
}
