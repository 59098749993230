import {TYPE_PURCHASE_ORDER, TYPE_INVOICE, TYPE_ASN, TYPE_OTHER, TYPE_ORDER_CONFIRMATION} from '../document/consts';
import {
  TYPE_ORDER_RECEIPT_REQUEST
} from '../../app/etc/consts';

export const BATCH_ACTION_IMPORT = 'import';
export const BATCH_ACTION_EXPORT = 'export';

export const BATCH_STATUS_PENDING    = 'pending';
export const BATCH_STATUS_COMPLETE   = 'complete';
export const BATCH_STATUS_PROCESSING = 'processing';
export const BATCH_STATUS_FAILED     = 'failed';
export const BATCH_STATUS_ERROR      = 'error';

export const AVAILABLE_BATCH_STATUSES = [
  BATCH_STATUS_PENDING,
  BATCH_STATUS_PROCESSING,
  BATCH_STATUS_COMPLETE,
  BATCH_STATUS_FAILED,
  BATCH_STATUS_ERROR,
];

export const EXPORTABLE_BATCH_STATUSES = [
  BATCH_STATUS_PENDING,
  BATCH_STATUS_FAILED,
  BATCH_STATUS_ERROR,
];

export const ACTION_CREATE  = 'create';
export const ACTION_CANCEL  = 'cancel';
export const ACTION_UPDATE  = 'update';
export const ACTION_REPLACE = 'replace';

export const AVAILABLE_BATCH_TYPES = [
  TYPE_ORDER_RECEIPT_REQUEST, //legacy
  TYPE_ORDER_RECEIPT_REQUEST + '.' + ACTION_CREATE,
  TYPE_ORDER_RECEIPT_REQUEST + '.' + ACTION_CANCEL,
  TYPE_ORDER_RECEIPT_REQUEST + '.' + ACTION_UPDATE,
  // ---------------------------------------------//
  TYPE_PURCHASE_ORDER, //legacy
  TYPE_PURCHASE_ORDER + '.' + ACTION_CREATE,
  TYPE_PURCHASE_ORDER + '.' + ACTION_CANCEL,
  TYPE_PURCHASE_ORDER + '.' + ACTION_UPDATE,
  TYPE_PURCHASE_ORDER + '.' + ACTION_REPLACE,
  // ---------------------------------------------//
  // TYPE_INVOICE_REQUEST, //legacy
  // TYPE_INVOICE_REQUEST + '.' + ACTION_CREATE,
  // TYPE_INVOICE_REQUEST + '.' + ACTION_CANCEL,
  // TYPE_INVOICE_REQUEST + '.' + ACTION_UPDATE,
  // ---------------------------------------------//
  // TYPE_INVOICE, //legacy
  TYPE_INVOICE + '.' + ACTION_CREATE,
  // TYPE_INVOICE + '.' + ACTION_CANCEL,
  // TYPE_INVOICE + '.' + ACTION_UPDATE,
  // TYPE_INVOICE + '.' + ACTION_REPLACE,
  // ---------------------------------------------//
  //TYPE_ASN_REQUEST, //legacy
  // TYPE_ASN_REQUEST + '.' + ACTION_CREATE,
  // TYPE_ASN_REQUEST + '.' + ACTION_CANCEL,
  // TYPE_ASN_REQUEST + '.' + ACTION_UPDATE,
  // ---------------------------------------------//
  // TYPE_ASN, //legacy
  TYPE_ASN + '.' + ACTION_CREATE,
  // TYPE_ASN + '.' + ACTION_CANCEL,
  // TYPE_ASN + '.' + ACTION_UPDATE,
  // TYPE_ASN + '.' + ACTION_REPLACE,
  TYPE_ORDER_CONFIRMATION + '.' + ACTION_CREATE,
  // ---------------------------------------------//
  // TYPE_OTHER_REQUEST, //legacy
  // TYPE_OTHER_REQUEST + '.' + ACTION_CREATE,
  // TYPE_OTHER_REQUEST + '.' + ACTION_CANCEL,
  // TYPE_OTHER_REQUEST + '.' + ACTION_UPDATE,
  // ---------------------------------------------//
  // TYPE_OTHER, //legacy
  TYPE_OTHER + '.' + ACTION_CREATE,
  // TYPE_OTHER + '.' + ACTION_CANCEL,
  // TYPE_OTHER + '.' + ACTION_UPDATE,
  // TYPE_OTHER + '.' + ACTION_REPLACE,
];

export const AVAILABLE_BATCH_ACTIONS = [
  BATCH_ACTION_IMPORT,
  BATCH_ACTION_EXPORT,
];
