export const PERMISSION_ADMIN = 'admin';
export const PERMISSION_CLIENT_OWNER = 'client_owner';
export const PERMISSION_CLIENT_USER = 'client_user';

export const PERMISSION_ADMIN_LABEL = 'System Admin';
export const PERMISSION_CLIENT_OWNER_LABEL = 'Owner';
export const PERMISSION_CLIENT_USER_LABEL = 'Regular User';

export const AVAILABLE_USER_PERMISSIONS = [
  {id: PERMISSION_ADMIN, name: PERMISSION_ADMIN_LABEL},
  {id: PERMISSION_CLIENT_OWNER, name: PERMISSION_CLIENT_OWNER_LABEL},
  {id: PERMISSION_CLIENT_USER, name: PERMISSION_CLIENT_USER_LABEL},
];

export const AVAILABLE_USER_PERMISSIONS_FOR_CLIENT = [
  {id: PERMISSION_CLIENT_OWNER, name: PERMISSION_CLIENT_OWNER_LABEL},
  {id: PERMISSION_CLIENT_USER, name: PERMISSION_CLIENT_USER_LABEL},
];
