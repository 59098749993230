import React from 'react';
import {useTranslate} from 'ra-core';
import {Card, CardContent, CardHeader} from '@mui/material';
import {Datagrid} from 'react-admin';
import {GridStyles} from '../../../theme/skin/poc/styles';

import List from './components/List';
import ListEmpty from './components/ListEmpty';
import ListToolbar from './components/ListToolbar';

const Index = props => {
  const {createButton, actions, resource, bulkActionButtons, exporter, headerContent, footerContent, ...rest} = props;
  const translate = useTranslate();
  const classes = GridStyles();

  return (
    <Card {...rest} className={classes.root}>
      <CardHeader title={translate('app.' + resource + '.label')} className={classes.header} />
      {headerContent}
      <CardContent className={classes.content}>
        <List
          {...rest}
          emptyWhileLoading={true}
          empty={<ListEmpty createButton={createButton} actions={actions} resource={resource}/>}
          actions={<ListToolbar createButton={createButton} actions={actions} exporter={exporter} maxResults={5000}/>}
          bulkActionButtons={bulkActionButtons || false}
          className={classes.list}
          hasCreate
          hasEdit
          hasList
          hasShow
          resource={resource}
          basePath={resource}
        >
          <Datagrid {...rest} className={classes.grid}/>
        </List>
      </CardContent>
      {footerContent}
    </Card>
  )
};

export default Index;
