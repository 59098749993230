import React from 'react';
import {required, TextInput, PasswordInput} from 'react-admin';

export default () => {
  return (
    <>
      <TextInput
        source="authorization.basic.username"
        label="app.general.labels.username"
        validate={[required()]}
      />
      <PasswordInput
        source="authorization.basic.password"
        label="app.general.labels.password"
        validate={[required()]}
      />
    </>
  );
};
