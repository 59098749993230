import React from "react";
import {required, SelectInput, TextInput} from "react-admin";

import {toOptions} from "../../../lib/source";
import {SOURCES, SOURCE_BIGCOMMERCE_CLOUD, AVAILABLE_METHODS, CUSTOM_SOURCES, SIMPLE_SOURCES, SOURCE_EMAIL} from "../consts";
import session from "../../../app/session";
import SimpleRequest from "../../../lib/simple-request";

export default class Source extends React.Component
{
  constructor(props) {
    super(props);

    this.onChannelsSuccess = this.onChannelsSuccess.bind(this);
    this.onChannelsError   = this.onChannelsError.bind(this);
    this.onChannelsLoad    = this.onChannelsLoad.bind(this);

    this.state = {channels: {}, force_update: ''};
  }

  getClientId() {
    return session.hasAdminPermission() ? this.props.formData.client_id : session.getData().clientId;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.hasPropsChangedUpdate(nextProps);
  }

  componentDidMount() {
    this.updateChannels();
  }

  hasPropsChangedUpdate(props) {
    return (
      (props.formData.client_id !== this.props.formData.client_id)
      || (props.formData.source !== this.props.formData.source)
      || (props.loading !== this.props.loading)
    );
  }

  hasPropsChangedMount(props) {
    return (
      (props.formData.client_id !== this.props.formData.client_id)
      || (props.formData.source !== this.props.formData.source)
    );
  }

  componentDidUpdate(prevProps) {
    if (this.hasPropsChangedMount(prevProps)) {
      this.updateChannels();
    }
  }

  updateChannels() {
    const source = this.props.formData.source;
    if (source !== SOURCE_BIGCOMMERCE_CLOUD) {
      return ;
    }

    const request = new SimpleRequest();
    request.call(
      'bigcommerce/channels/' + this.getClientId(),
      this.onChannelsSuccess,
      this.onChannelsError,
      this.onChannelsLoad
    );
  }

  onChannelsSuccess(resp) {
    this.setState({channels: resp.json, force_update: (new Date).getTime()});
    this.props.setError(false);
    this.props.setLoading(false);
  }

  onChannelsError(err) {
    this.setState({channels: [], force_update: (new Date).getTime()});
    this.props.setError(err);
    this.props.setLoading(false);
  }

  onChannelsLoad() {
    this.props.setLoading(true);
  }

  render() {
    const source = this.props.formData.source;
    const clientId = this.props.formData.client_id;

    const choices = toOptions(SOURCES, 'app.endpoint.labels.sources.');
    const methods = toOptions(AVAILABLE_METHODS, 'app.endpoint.labels.methods.');
    const channels = Array.isArray(this.state.channels) ? this.state.channels: [];

    const isSimple = SIMPLE_SOURCES.indexOf(source) !== -1;
    const helpText = !isSimple ? 'app.endpoint.labels.notes.uri_hint' : 'app.endpoint.labels.notes.simple_hint';

    let uriLabel = 'app.endpoint.labels.uri';
    if (source == SOURCE_EMAIL) {
      uriLabel = 'app.endpoint.labels.email';
    }

    return (
      <>
        <div className={this.props.classes.formSection}>{this.props.translate('app.endpoint.labels.source_target')}</div>

        <SelectInput source="source"
                     choices={choices}
                     label="app.endpoint.labels.source"
                     required
                     allowEmpty
        />

        <TextInput source="uri" label={this.props.translate(uriLabel)} helperText={helpText} multiline={isSimple} required/>

        {source && CUSTOM_SOURCES.indexOf(source) !== -1 && SIMPLE_SOURCES.indexOf(source) === -1 &&
          <SelectInput source="method"
                       choices={methods}
                       label="app.endpoint.labels.method"
                       helperText="app.endpoint.labels.notes.method_hint"
                       required
          />
        }

        {source && (source === SOURCE_BIGCOMMERCE_CLOUD) &&
        <SelectInput source="channel_id"
                     choices={channels}
                     label="app.endpoint.labels.channel"
                     required={required()}
                     disabled={!clientId || !!this.props.loading}
        />
        }
      </>
    );
  }
};
