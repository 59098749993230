import React from 'react';
import {TopToolbar, RefreshButton, ListButton, useRecordContext, useResourceContext} from 'react-admin';
import {ActionStyles} from '../../../theme/skin/poc/styles';
import DeleteButton from '../Grid/Button/DeleteButton';
import DuplicateButton from '../Grid/Button/DuplicateButton';
// import SaveButton from '../Grid/Button/SaveButton';
import SaveButton from '../../poc/Toolbar/components/SaveButton';
import {ClientFieldTransform} from "../Form/Fields";
import {useFormContext} from "react-hook-form";

export default (props) => {
  const record = useRecordContext();
  const formContext = useFormContext();

  // const resource = useResourceContext();
  // const {className, record, resource, title, listUrl} = props;
  const {className, title, listUrl} = props;
  const classes = ActionStyles();

  let showDelete = true;
  if (typeof props.deleteConditions === 'boolean') {
    showDelete = props.deleteConditions;
  } if (typeof props.deleteConditions === 'function') {
    showDelete = props.deleteConditions(record);
  }

  let duplicate = false;
  if (typeof props.duplicateConditions === 'function') {
    duplicate = props.duplicateConditions(record);
  }

  let save = false;
  if (typeof props.saveConditions === 'function') {
    save = props.saveConditions(record);
  }

  //@See: POC-2829 - fix client_id from autocomplete
  const transform = (data = {}) => {
    return ClientFieldTransform(formContext, data);
  }

  return (
    <TopToolbar className={className}>
      <div className="actions-title">{title}</div>
      <div className="actions-buttons">
        {record &&
          <>
            {save &&
            <SaveButton transform={transform} />
            }

            {duplicate &&
            <DuplicateButton />
            }

            <ListButton basePath={listUrl} className={classes.button} />
            <RefreshButton className={classes.button} />

            {showDelete &&
            <DeleteButton />
            }
          </>}
      </div>
    </TopToolbar>
  )
};
