import React from 'react';
import Chip from '@mui/material/Chip';
import {FunctionField} from 'react-admin';

export const TextArrayFieldRender = (record, source) => {
  const array = record[source]
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div/>
  } else {
    return (
      <>
        {array.map(item => <Chip label={item} key={item}/>)}
      </>
    )
  }
};

export const TextArrayField = ({source}) => {
  return (<FunctionField render={record => TextArrayFieldRender(record, source)}/>)
};

TextArrayField.defaultProps = { addLabel: true };
