import React from 'react';
import {CUSTOM_SOURCES} from "../consts";
import {AVAILABLE_RULE_CONDITION_EXPRESSIONS, AVAILABLE_RULE_CONDITION_OPERATORS} from "../../core/consts";
import {ArrayInput, required, SelectInput, SimpleFormIterator, TextInput} from "react-admin";
import {toOptions} from "../../../lib/source";

export const skipHandling = (source) => {
  return (!source || CUSTOM_SOURCES.indexOf(source) === -1);
};

export default ({ex, formData}) => {
  const {source} = formData;
  if (skipHandling(source)) {
    return <></>;
  }

  const sourceExpression  = `response.rule.expr`;
  const sourceConditions  = `response.rule.conditions`;

  return (
    <>
      <div className={ex.classes.formSection}>{ex.translate('app.endpoint.labels.response_rule_conditions')}</div>
      <div className={ex.classes.noteSection}>{ex.translate('app.endpoint.labels.notes.response_rule_conditions')}</div>

      <SelectInput source={sourceExpression}
                   choices={toOptions(AVAILABLE_RULE_CONDITION_EXPRESSIONS)}
                   label="app.endpoint.labels.condition"
                   validate={[required()]}
                   defaultValue="any"
                   /** sx={{width: '150px', minWidth: '150px'}} */
      />

      <ArrayInput source={sourceConditions} label="">
        <SimpleFormIterator>
          <TextInput   source="path"     label="app.general.labels.path" validate={[required()]} />
          <SelectInput source="operator" choices={toOptions(AVAILABLE_RULE_CONDITION_OPERATORS)} label="app.endpoint.labels.operator" validate={[required()]}/>
          <TextInput   source="value"    label="app.general.labels.value" />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
