import session from '../../app/session'
import {BATCH_STATUS_FAILED} from './consts';

export const deletePermission = (row) => {
  return false;
};

export const retryPermission = (row) => {
  return (!!row && !!row.id) ? (session.hasAdminPermission() && (row.status === BATCH_STATUS_FAILED)): false;
};
