import clsx from "clsx";
import React from 'react';
import hookIntoProps from 'hook-into-props'
import {useDataProvider, useTranslate, useNotify} from 'ra-core';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MoneyIcon from "@mui/icons-material/Money";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import SummaryAbstract from '../SummaryAbstract';
import {DashboardStyles} from "../../../theme/skin/poc/styles";

class DocsSummaryBlocksReport extends SummaryAbstract {
  constructor(props) {
    super(props);

    //this.col_all = 0;
    this.col_transferred_cart = 0;
    this.col_purchase_order = 1;
    this.col_invoice = 2;
    this.col_asn = 3;

    this.types = {
      //all:              {name: 'all', className: this.css.graphBarItemAll},
      transferred_cart: {name: 'transferred_cart', className: this.css.graphBarItemTransferredCart},
      purchase_order:   {name: 'purchase_order', className: this.css.graphBarItemPurchaseOrder},
      invoice:          {name: 'invoice', className: this.css.graphBarItemInvoice},
      asn:              {name: 'asn', className: this.css.graphBarItemAsn},
    };
  }

  getDefaultData() {
    return Object.values(this.types)
      .reduce(function (acc, t) {
        acc.push({type: t.name, transactions_amount: 0.00, documents_amount: 0.00}); return acc;
      }, []);
  }

  item(row) {
    const type = this.types[row.type];

    return (
      <Grid item xs={12} sm={6} md={3} lg={3} className={!!type ? type.className : this.css.graphBarItemUnknown}>
        <Card>
          <CardContent>
            <Grid container justify="space-between">
              {this.details(row)}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  details(row) {
    return (
      <Grid item>
        <Typography className={this.css.title} color="textSecondary" gutterBottom variant="body2"><span className={clsx(this.css.barIndicator, 'indicator')}></span>{this._('app.report.labels.' + row.type)}</Typography>
        <Typography variant="h5">Documents: ${!!row.documents_amount ? row.documents_amount.toFixed(2) : 0.00}</Typography>
        <Typography variant="h5">Mapping: ${!!row.transactions_amount ? row.transactions_amount.toFixed(2) : 0.00}</Typography>
      </Grid>
    );
  }

  icon(row) {
    return (
      <Grid item>
        <Avatar className={this.css.avatar}>
          {row.type === 'transferred_cart' && <ShoppingCartIcon className={this.css.icon} />}
          {row.type === 'purchase_order' && <MoneyIcon className={this.css.icon} />}
          {row.type === 'invoice' && <ReceiptIcon className={this.css.icon} />}
          {row.type === 'asn' && <LocalShippingIcon className={this.css.icon} />}
        </Avatar>
      </Grid>
    );
  }

  summarizeRow(accumulator, row) {
    //accumulator[this.col_all].documents_amount += parseFloat(row.documents_amount['all']);
    accumulator[this.col_transferred_cart].documents_amount += parseFloat(row.documents_amount['transferred_cart']);
    accumulator[this.col_purchase_order].documents_amount += parseFloat(row.documents_amount['purchase_order']);
    accumulator[this.col_invoice].documents_amount += parseFloat(row.documents_amount['invoice']);
    accumulator[this.col_asn].documents_amount += parseFloat(row.documents_amount['asn']);

    //accumulator[this.col_all].transactions_amount += parseFloat(row.transactions_amount['all']);
    accumulator[this.col_transferred_cart].transactions_amount += parseFloat(row.transactions_amount['transferred_cart']);
    accumulator[this.col_purchase_order].transactions_amount += parseFloat(row.transactions_amount['purchase_order']);
    accumulator[this.col_invoice].transactions_amount += parseFloat(row.transactions_amount['invoice']);
    accumulator[this.col_asn].transactions_amount += parseFloat(row.transactions_amount['asn']);

    return accumulator;
  }
};

/**                                                              *
 *  ------------------------------------------------------------ *
 *                                                               */
const useHooks = () => ({
  translate: useTranslate(),
  styles: DashboardStyles(),
  dataProvider: useDataProvider(),
  notify: useNotify(),
});

export default hookIntoProps(useHooks)(DocsSummaryBlocksReport);
