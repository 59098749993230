export const TYPE_TRANSFERRED_CART       = 'transferred_cart';
export const TYPE_TRANSFERRED_CART_LABEL = 'Transferred Cart';

export const TYPE_PURCHASE_ORDER       = 'purchase_order';
export const TYPE_PURCHASE_ORDER_LABEL = 'Purchase Order';

export const TYPE_INVOICE        = 'invoice';
export const TYPE_INVOICE_LABEL  = 'Invoice';

export const TYPE_CREDIT_MEMO        = 'credit_memo';
export const TYPE_CREDIT_MEMO_LABEL  = 'Credit Memo';

export const TYPE_ASN = 'asn';
export const TYPE_ASN_LABEL  = 'ASN';

export const TYPE_ORDER_CONFIRMATION = 'order_confirmation';
export const TYPE_ORDER_CONFIRMATION_LABEL = 'Order Confirmation';

export const TYPE_OTHER = 'other';
export const TYPE_OTHER_LABEL = 'Other';

export const AVAILABLE_TYPES = [
  {id: TYPE_TRANSFERRED_CART, name: TYPE_TRANSFERRED_CART_LABEL},
  {id: TYPE_PURCHASE_ORDER, name: TYPE_PURCHASE_ORDER_LABEL},
  {id: TYPE_INVOICE, name: TYPE_INVOICE_LABEL},
  {id: TYPE_CREDIT_MEMO, name: TYPE_CREDIT_MEMO_LABEL},
  {id: TYPE_ASN, name: TYPE_ASN_LABEL},
  {id: TYPE_ORDER_CONFIRMATION, name: TYPE_ORDER_CONFIRMATION_LABEL},
  {id: TYPE_OTHER, name: TYPE_OTHER_LABEL},
];

//*-ing statuses | statuses which expects to be scheduled to do smt.
export const DOCUMENT_STATUS_PENDING            = 'pending';
export const DOCUMENT_STATUS_CANCELLING         = 'cancelling';
export const DOCUMENT_STATUS_UPDATING           = 'updating';
export const DOCUMENT_STATUS_PROCESSING         = 'processing';
//done statuses
export const DOCUMENT_STATUS_DELETED            = 'deleted';
export const DOCUMENT_STATUS_TRANSFERRED        = 'transferred';
export const DOCUMENT_STATUS_CANCELLED          = 'cancelled';
export const DOCUMENT_STATUS_COMPLETE           = 'complete';
export const DOCUMENT_STATUS_ERROR              = 'error';
export const DOCUMENT_STATUS_FAILED             = 'failed';

export const AVAILABLE_STATUSES = [
  DOCUMENT_STATUS_PENDING,
  DOCUMENT_STATUS_CANCELLING,
  DOCUMENT_STATUS_UPDATING,
  DOCUMENT_STATUS_PROCESSING,
  DOCUMENT_STATUS_DELETED,
  DOCUMENT_STATUS_TRANSFERRED,
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENT_STATUS_COMPLETE,
  DOCUMENT_STATUS_ERROR,
  DOCUMENT_STATUS_FAILED,
];

export const EXPORTABLE_TYPES = [
  TYPE_PURCHASE_ORDER,
  TYPE_INVOICE,
  TYPE_ASN,
  TYPE_ORDER_CONFIRMATION
];

