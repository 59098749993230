import React from 'react';

import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';

import {EmptyStyles} from '../../../../../theme/skin/poc/styles';
import {useTranslate} from 'ra-core';
import {CreateButton} from 'react-admin';
import inflection from 'inflection';
import QuickCreateButton from '../ListToolbar/components/QuickCreateButton';

const Index = ({ resource, basePath, createButton, actions, ...rest }) => {
  const {
    total,
    loaded,
    loading,
    data
  } = rest;

  const classes = EmptyStyles();
  const translate = useTranslate();

  const resourceName = inflection.humanize(
    translate(`resources.${resource}.name`, {
      smart_count: 0,
      _: inflection.pluralize(resource),
    }),
    true
  );

  //data.error - just an example of by passing error through a mess of react-admin code
  if (!total && loaded && !loading && !!data.error) {
    return (<Alert severity="error"><AlertTitle>Error</AlertTitle>Internal Error Happened</Alert>);
  }

  const emptyMessage = translate('ra.page.empty', { name: resourceName });
  const inviteMessage = translate('ra.page.invite');

  const msg = translate(`resources.${resource}.empty`, {
    _: emptyMessage,
  });
  const msgAdd = translate(`resources.${resource}.invite`, {
    _: inviteMessage,
  });

  return (
    <>
      <div className={classes.message}>
        <Alert severity="warning">{msg}</Alert>

        {(createButton || actions) &&
        <Alert severity="info">
          {msgAdd}

          {createButton && resource !== 'token' &&
          <CreateButton basePath={basePath} resource={resource} />
          }

          {createButton && resource === 'token' &&
          <QuickCreateButton basePath={basePath} resource={resource} />
          }

          {actions && React.cloneElement(actions, {
            basePath,
            resource
          })}
        </Alert>
        }
      </div>
    </>
  );
};

export default Index;
