import React, {useState, useEffect} from 'react';

import {
  TextField,
  SelectField,
  ReferenceField,
  DateField,
  FunctionField,
  Link,
  TabbedShowLayout,
  Tab,
  Pagination,
  ReferenceManyField,
  Datagrid,
  ListButton,
  TopToolbar,
  Loading,
  Show,
  useRecordContext,
  useGetRecordId,
  WithRecord,
} from 'react-admin';
//import XmlBeautify from 'xml-beautify';
import {useDataProvider, useTranslate} from 'ra-core';
import {ViewStyles} from '../../theme/skin/poc/styles';
import Error from '../../components/poc/Alert';
import Exchanges from './components/exchanges';

import {
  AVAILABLE_TRANSACTION_FORMATS,
  AVAILABLE_TRANSACTION_STATUSES,
  AVAILABLE_TRANSACTION_ACTIONS,
  AVAILABLE_TRANSACTION_CHANNELS,
  AVAILABLE_TRANSACTION_TYPES
} from './consts';

import session from '../../app/session';
import config from '../../app/etc/config';
import {toOptions} from '../../lib/source';
import {flipPermission} from './permissions';

function isXml(value) {
  return (value.indexOf('<?xml') === 0 || value.indexOf('<') === 0);
}

function getValue(record, source) {
  var value = typeof record[source] !== 'undefined' ? record[source] : '{}';
  if (typeof value === 'string') {
    value = value.trim();
  }
  return value;
}

function renderJsonField(record, source = '') {
  var value = record;
  if (typeof record === 'object') {
    value = getValue(record, source);
  }

  try {
    if (isXml(value)) {
      /**
      value = new XmlBeautify().beautify(value, {
        indent: "  ",
        useSelfClosingElement: false
      });*/
    } else {
      var j = JSON.parse(value);
      //
      if (typeof j === 'string') {
        value = j;
      } else {
        value = JSON.stringify(j, null, 2);
      }
    }
  } catch (e) {
  }

  return value;
};

function renderMessageField(record, source = '') {
  const classes = ViewStyles();

  return (<textarea className={classes.transactionMessageMedium}>{renderJsonField(record, source)}</textarea>)
}

function renderMessageFieldSmall(record, source = '') {
  const classes = ViewStyles();

  return (<textarea className={classes.transactionMessageSmall}>{renderJsonField(record, source)}</textarea>)
}

const rowStyle = (record, index) => ({
  backgroundColor: index % 2 ? '#FFFFFF' : '#DDEFEF',
});


const ShowActions = (props) => {
  const { basePath } = props;

  const translate = useTranslate();
  const classes = ViewStyles();
  return (
    <TopToolbar>
      <h1 className={classes.headerTopToolbar}>{translate('app.transaction.view')}</h1>
      <ListButton basePath={basePath} className={classes.button}/>
    </TopToolbar>
  );
};

const FlipToOrderReceipt = ({ record }) => {
  const dataProvider = useDataProvider();
  const [orderReceiptRequest, setOrderReceiptRequest] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const classes = ViewStyles();

  useEffect(() => {
    dataProvider.getOne(
        config.routes.TRANSACTION_FLIP_OR,
      {id: record.id, 'content-type': 'application/xml'}
      )
      .then(({data}) => {
        setOrderReceiptRequest(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error message={error}/>;
  if (!orderReceiptRequest) return null;

  return (
    <textarea className={classes.transactionMessage}>{orderReceiptRequest}</textarea>
  )
};

export const TransactionView = (props) => {
  const classes = ViewStyles();
  const { id } = props;

  return (
    <Show {...props} className={classes.root} actions={<ShowActions basePath="/transactions"/>} >
      <WithRecord render={record => (<TabbedShowLayout>
        <Tab label="General">
          <TextField   className={classes.fieldWithLabel} source="id"/>
          <SelectField choices={toOptions(AVAILABLE_TRANSACTION_STATUSES, 'app.general.labels.')} className={classes.fieldWithLabel} source="status"/>
          <SelectField choices={toOptions(AVAILABLE_TRANSACTION_TYPES, 'app.general.labels.')} className={classes.fieldWithLabel} source="type"/>
          <TextField   className={classes.fieldWithLabel} source="session_id"/>
          <TextField   className={classes.fieldWithLabel} source="document_id"/>
          <DateField   className={classes.fieldWithLabel} showTime source="created_at"/>
          <SelectField choices={toOptions(AVAILABLE_TRANSACTION_CHANNELS, 'app.general.labels.')} className={classes.fieldWithLabel} source="channel"/>
          <SelectField choices={toOptions(AVAILABLE_TRANSACTION_ACTIONS, 'app.general.labels.')}  className={classes.fieldWithLabel} source="action"/>
          <SelectField choices={toOptions(AVAILABLE_TRANSACTION_FORMATS)}  className={classes.fieldWithLabel} source="format"/>

          {session.hasAdminPermission() &&
            <ReferenceField
              className={classes.fieldWithLabel}
              label="Client"
              reference="client"
              sortable={false}
              source="client_id"
              perPage={config.pagination.associated}
              sort={{field: 'name', order: 'ASC'}}
            >
              <TextField source="name"/>
            </ReferenceField>
          }

          <ReferenceField
            className={classes.fieldWithLabel}
            label="Connection"
            reference="connection"
            sortable={false}
            source="connection_id"
          >
            <TextField
              className={classes.fieldWithLabel}
              source="name"
            />
          </ReferenceField>

          <ReferenceField
            className={classes.fieldWithLabel}
            label="Endpoint"
            reference="endpoint"
            sortable={false}
            source="endpoint_id"
          >
            <TextField
              className={classes.fieldWithLabel}
              source="name"
            />
          </ReferenceField>

          <ReferenceField
            className={classes.fieldWithLabel}
            label="Profile" reference="profile"
            sortable={false}
            source="profile_id"
          >
            <TextField
              className={classes.fieldWithLabel}
              source="name"
            />
          </ReferenceField>
        </Tab>

        <Tab label="Data">
          <FunctionField className={classes.fieldWithLabel} source="message"    component="pre" render={renderMessageField}/>
          <FunctionField className={classes.fieldWithLabel} source="data"       component="pre" render={renderMessageField}/>
          <FunctionField className={classes.fieldWithLabel} source="flow"       component="pre" render={renderMessageField}/>
          <FunctionField className={classes.fieldWithLabel} source="map"        component="pre" render={renderMessageFieldSmall}/>
          <FunctionField className={classes.fieldWithLabel} source="context"    component="pre" render={renderMessageFieldSmall}/>
          <FunctionField className={classes.fieldWithLabel} source="summary"    component="pre" render={renderMessageFieldSmall}/>
        </Tab>

        <Tab label="Debug">
          <FunctionField className={classes.fieldWithLabel} source="additional" component="pre" render={renderMessageFieldSmall}/>
          <FunctionField className={classes.fieldWithLabel} source="notes"      component="pre" render={renderMessageFieldSmall}/>

          <FunctionField
            label="Matched Connection IDs"
            className={classes.fieldWithLabel}
            component="div"
            render={row => `${row.associated_connection_ids && row.associated_connection_ids.length ? row.associated_connection_ids.join(', ') : 'N/A'}`}
          />
        </Tab>

        {record && flipPermission(record) &&
          <Tab label="Order Receipt Flip">
            <FlipToOrderReceipt {...props} />
          </Tab>
        }

        {record && Array.isArray(record.exchanges) &&
          <Tab label="API Exchanges">
            <Exchanges label="" record={record} />
          </Tab>
        }

        <Tab label="Related Transactions">
          <ReferenceManyField
            label=""
            reference="transaction"
            target="_"
            filter={{'session_id': (!!record && record.session_id) ? record.session_id : '000000000000000000000000'}}
            pagination={<Pagination/>}
            sort={false}
          >
            <Datagrid className={classes.grid} rowStyle={rowStyle}>
              <FunctionField
                label="ID"
                sortBy={false}
                render={(row) => {
                  let linkText = row.id;
                  if (id === row.id) {
                    linkText = <strong>{row.id}</strong>;
                  }
                  return (<Link to={{ pathname: `/transaction/${row.id}/show` }} className={classes.link_grid}>{linkText}</Link>)
                }}
              />

              <SelectField choices={toOptions(AVAILABLE_TRANSACTION_STATUSES, 'app.general.labels.')} source="status"/>
              <SelectField choices={toOptions(AVAILABLE_TRANSACTION_TYPES, 'app.general.labels.')} source="type"/>
              <SelectField choices={toOptions(AVAILABLE_TRANSACTION_CHANNELS, 'app.general.labels.')} source="channel"/>
              <SelectField choices={toOptions(AVAILABLE_TRANSACTION_ACTIONS, 'app.general.labels.')} source="action"/>

              <SelectField choices={toOptions(AVAILABLE_TRANSACTION_FORMATS)} source="format"/>
              <DateField className={classes.fieldWithLabel} showTime source="created_at"/>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>)} />
    </Show>
  )
};
