import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  DateInput,
  useDataProvider,
  FormDataConsumer
} from 'react-admin';
import {useTranslate} from 'ra-core';
import isValidDomain from 'is-valid-domain';

import {deletePermission} from "./permissions";

import {ViewStyles} from '../../theme/skin/poc/styles';

import Toolbar from '../../components/poc/Toolbar';
import Actions from '../../components/poc/Actions';
import {ClientField} from '../../components/poc/Form/Fields';

import session from '../../app/session';

const validate = (values) => {
  const errors = {};

  if (session.hasAdminPermission() && !values.client_id) {
    errors.client_id = ['The Client is required'];
  }

  if (!values.domain) {
    errors.domain = ['The Admin Console Domain is required'];
  } else if (!isValidDomain(values.domain, {subdomain: true, wildcard: false})) {
    errors.domain = ['The Admin Console Domain is not a valid domain value'];
  }

  if (!values.connections_number || parseInt(values.connections_number) < 1) {
    errors.connections_number = ['The Connections Number is required'];
  }

  if (!values.expires_at) {
    errors.expires_at = ['The Expires at is required'];
  }
  return errors
};

export const LicenseCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();
  return (
    <Create {...props} className={classes.root} successMessage={translate('app.license.created')}>
      {EditForm(props, 'create')}
    </Create>
  )
};

export const EditForm = (props, mode) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
    <SimpleForm
      id="viewForm"
      toolbar={<Toolbar validate={validate} deleteConditions={mode === 'edit' ? deletePermission : false}/>}
    >
      <Actions
        className={classes.actions}
        listUrl="/licenses"
        title={translate('app.license.' + mode)}
        deleteConditions={mode === 'edit' ? deletePermission : false}
      />

      <FormDataConsumer>
        {({formData}) =>
          <>
            {session.hasAdminPermission() &&
            <ClientField clientId={formData.client_id} ex={ex} />
            }

            <TextInput source="domain" label="Admin Console Domain"/>
            <NumberInput source="connections_number" label="Connections Number"/>
            <DateInput source="expires_at"/>
          </>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
}
