import React from 'react';
import { useTranslate } from 'ra-core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Divider, Drawer } from '@mui/material';

//@See: https://material.io/resources/icons/?style=baseline
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import ImageIcon from '@mui/icons-material/Image';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HttpIcon from '@mui/icons-material/Http';
import LinkIcon from '@mui/icons-material/Link';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GridOnIcon from '@mui/icons-material/GridOn';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import PolicyIcon from '@mui/icons-material/Policy';
import WebIcon from '@mui/icons-material/Web';

import { Profile, SidebarNav } from './components';

import session from '../../../../app/session';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const {open, variant, onClose, className, ...rest} = props;
  const translate = useTranslate();
  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/',
      icon: <DashboardIcon/>
    }
  ];

  if (session.hasAdminPermission()) {
    pages.push(
      {
        title: translate('app.client.label'),
        href: '/clients',
        icon: <ShoppingBasketIcon/>
      }
    );
  }

  if (session.hasClientPermission()) {
    pages.push(
      {
        title: translate('app.user.label'),
        href: '/users',
        icon: <PeopleIcon/>
      }
    );
  }

  if (session.hasClientUserPermission()) {
    pages.push(
      {
        title: translate('app.endpoint.label'),
        href: '/endpoints',
        icon: <LinkIcon/>
      },
      {
        title: translate('app.buyer.label'),
        href: '/buyers',
        icon: <RecentActorsIcon/>
      },
      {
        title: translate('app.credential.label'),
        href: '/credentials',
        icon: <LockOpenIcon/>
      },
      {
        title: translate('app.connection.label'),
        href: '/connections',
        icon: <SettingsEthernetIcon/>
      },
      {
        title: translate('app.profile.label'),
        href: '/profiles',
        icon: <AccountTreeIcon/>
      },
      {
        title: translate('app.template.label'),
        href: '/templates',
        icon: <WebIcon/>
      },
      {
        title: translate('app.table.label'),
        href: '/tables',
        icon: <GridOnIcon/>
      },
      {
        title: translate('app.document.label'),
        href: '/documents',
        icon: <AttachMoneyIcon/>
      },
      {
        title: translate('app.importexport_batch.label'),
        href: '/import-export',
        icon: <ImportExportIcon/>
      },
      {
        title: translate('app.transaction.label'),
        href: '/transactions',
        icon: <ImageIcon/>
      },
      {
        title: translate('app.token.label'),
        href: '/tokens',
        icon: <LockOpenIcon/>
      },
      {
        title: translate('app.key.label'),
        href: '/keys',
        icon: <VpnKeyIcon/>
      },
      {
        title: translate('app.license.label'),
        href: '/licenses',
        icon: <PolicyIcon/>
      }
    );
  }

  if (session.hasAdminPermission()) {
    pages.push(
      {
        title: translate('app.referer.label'),
        href: '/referers',
        icon: <HttpIcon/>
      }
    );
    pages.push(
      {
        title: translate('app.report_clients.label'),
        href: '/report/clients',
        icon: <AssessmentIcon/>
      }
    );
  }

  return (
    <Drawer
      anchor="left"
      classes={{paper: classes.drawer}}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile/>
        <Divider className={classes.divider}/>
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
