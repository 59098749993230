import React from 'react';
import {useTranslate} from 'ra-core';
import {DeleteWithConfirmButton as BaseDeleteButton, useRecordContext, useResourceContext} from 'react-admin';

export default (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const resource = useResourceContext();
  const {conditions} = props;

  let show = true;

  if (typeof conditions === 'function') {
    show = conditions(record);
  }

  let name = record.id;
  if (!!record.name) {
    name = record.name;
  } else if (!!record.email) {
    name = record.email;
  } else if (!!record.host) {
    name = record.host;
  } else if (!!record.code) {
    name = record.code;
  }

  return (
    <>
      {show &&
      <BaseDeleteButton
        {...props}
        confirmContent={translate("app.general.labels.delete_content", {name: name})}
      />}
    </>
  )
};
