// import {version} from '../../../package.json';

let url = 'http://cloud.pexlocal.com:8080/api/v2/manage';
let gatewayUrl = 'http://cloud.pexlocal.com:8080/gateway/%client_code%';

if (typeof process.env.REACT_APP_CONSOLE_URL === 'string') {
  url = process.env.REACT_APP_CONSOLE_URL;
} else if (typeof window.dataProviderUrl !== 'undefined') {
  url = window.dataProviderUrl;//http://jsonplaceholder.typicode.com
}

if (typeof process.env.REACT_APP_GATEWAY_URL === 'string') {
  gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
}

export default {
  version: '1.6.1',
  api: {
    //hot-fix for manage url
    url: url.replace('v2/console', 'v2/manage'),

    listing: {
      'client': 'clients',
      'connection': 'connections',
      'buyer': 'buyers',
      'endpoint': 'endpoints',
      'profile': 'profiles',
      'token': 'tokens',
      'key': 'keys',
      'transaction': 'transactions',
      'user': 'users',
      'document': 'documents',
      'license': 'licenses',
      'referer': 'referers',
      'table': 'tables',
      'credential': 'credentials',
      'template': 'templates'
    },

    access: ['token', 'key'],
    punchout: ['connection', 'transaction', 'buyer'],
    mapping: ['profile'],
    report: ['report/statistic', 'report/clients', 'report_statistic', 'report_clients']
  },
  gateway: {
    url: gatewayUrl,
  },
  jwt: {
    verify_options: {
      issuer: 'Punchout Cloud Core',
      subject: 'info@punchoutcatalogs.com',
      audience: 'Punchout Cloud Client',//@todo: remove
      expiresIn: '86400s',
      algorithms: ['HS256']
    }
  },
  google: {
    recaptcha: {
      enabled: !!parseInt(process.env.REACT_APP_GOOGLE_RECAPTCHA_ENABLED),
      site_key: process.env.REACT_APP_GOOGLE_SITE_KEY,
      secret_key: process.env.REACT_APP_GOOGLE_SECRET_KEY,
    }
  },
  routes: {
    USER_PASSWORD_RESET: 'user/password/reset',
    USER_PASSWORD_RESET_CONFIRM: 'user/password/reset/confirm',
    TRANSACTION_FLIP_OR: 'punchout/transaction/flip-to-order-receipt'
  },
  logo: {
    public_img: '/images/logos/logo-small-nav.png',
    private_img: '/images/logos/logo-small-nav.png',
  },
  pagination: {
    grid: 50,
    associated: 250
  },
  common_boolean: [
    'is_system',
    'is_shared',
    'is_default',
    'apply_match_rule',
    'secured',
  ],
  platform: {
    default: 'magento / custom'
  }
};
