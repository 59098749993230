import React from 'react';
import {FunctionField} from 'react-admin';
import Chip from '@mui/material/Chip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const IMPORT = ['import'];
const EXPORT = ['export'];

export const ActionFieldRender = (record, source ) => {
  if (typeof record[source] === 'undefined' || record[source] === null) {
    return null;
  }

  const val = record[source];
  let icon = null;

  if (IMPORT.indexOf(val) !== -1) {
    icon = <ArrowDownwardIcon />;
  } else if (EXPORT.indexOf(val) !== -1) {
    icon = <ArrowUpwardIcon />;
  }

  return (
    <Chip
      size="small"
      label={val}
      icon={icon}
      color='primary'
      /** style={{backgroundColor: color}} */
    />
  )
};

export const ActionField = ({source}) => {
  return (<FunctionField render={record => ActionFieldRender(record, source)}/>)
};

ActionField.defaultProps = { addLabel: true };
