import defaultEnglishMessages from 'ra-language-english';
import domainEnglishMessages from '../app/locale/en';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from "ra-core";

let messages = {
    en: { ...defaultEnglishMessages, ...domainEnglishMessages },
    //fr: { ...defaultFrenchMessages, ...domainFrenchMessages},
}

//i18nProvider
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale()
);
export default i18nProvider;