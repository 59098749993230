import React from 'react';
import {useTranslate} from 'ra-core';
import {useDataProvider, useRefresh, useNotify, useRecordContext, useResourceContext} from 'react-admin';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const Index = ({...props}) => {
  //const record = useRecordContext();
  const resource = useResourceContext();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const save = () => {
    dataProvider
      .create(resource, {})
      .then(() => {
        refresh();
        notify(translate('app.' + resource + '.label') + ' updated');
        //window.location.reload();
      })
      .catch(error => {
        notify(translate('app.' + resource + '.label') + ` update error: ${error.message}`, 'warning');
      });
  }

  return <Button
    color="primary"
    label="Create"
    onClick={save}
    startIcon={<AddIcon />}
    variant={props.variant}
  >Create</Button>;
};

export default Index;
