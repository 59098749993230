export const KEY_TYPE_OPENSSL_RSA_2048_PRIVATE = 'openssl_rsa_2048_private';
export const KEY_TYPE_OPENSSL_RSA_2048_PUBLIC  = 'openssl_rsa_2048_public';
export const KEY_TYPE_OPENSSL_RSA_2048_X509_CERT = 'openssl_rsa_2048_x509_cert';

export const AVAILABLE_KEY_TYPES = [
  KEY_TYPE_OPENSSL_RSA_2048_PRIVATE,
  KEY_TYPE_OPENSSL_RSA_2048_PUBLIC,
  KEY_TYPE_OPENSSL_RSA_2048_X509_CERT,
];

// export const PAYLOAD_METHODS = [
//   'rfc4648_base64',
//   'rfc4648_base64url',
//   'rfc4648_base32',
//   'rfc4648_base32hex',
//   'rfc4648_base16',
//   'rfc4648_codec',
// ];
