import React from 'react';
import {
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput, FunctionField
} from 'react-admin';

import ListFilter from '../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../components/poc/ListWithLayout';
import Actions from '../../components/poc/Grid/Row/Actions';

import {deletePermission, viewPermission} from "./permissions";

import session from '../../app/session';
import config from "../../app/etc/config";
import {toOptions} from "../../lib/source";
import {AVAILABLE_KEY_TYPES} from "./consts";
import {formatClientName} from "../../components/poc/Grid/Fields";

const KeyFilter = (props) => (
  <ListFilter {...props}>
    <SelectInput source="type" choices={toOptions(AVAILABLE_KEY_TYPES)} key="type" label="Type"/>

    <TextInput source="name:like" label="Domain"/>

    {session.hasAdminPermission() &&
      <ReferenceInput label="Client" reference="client" source="client_id" perPage={config.pagination.associated} sort={{field: 'name', order: 'ASC'}}>
        <SelectInput optionText={formatClientName}/>
      </ReferenceInput>
    }

    <DateInput source="expires_at" label="Expires At"/>
    <DateInput source="created_at" label="Created At"/>
  </ListFilter>
);

export const KeyGrid = props => {
  let bulkActionButtons = null;
  if (!session.hasAdminPermission()) {
    bulkActionButtons = {bulkActionButtons:false};
  }

  let createButton = {createButton:false};
  if (session.hasAdminPermission()) {
    createButton = {createButton:true};
  }
  return (
    <ListWithLayout
      {...props}
      {...bulkActionButtons}
      {...createButton}
      filters={<KeyFilter/>}
      resource="key"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      createButton={true}
    >
      <TextField source="id"/>
      <TextField source="type"/>

      <TextField source="name"/>

      {session.hasAdminPermission() &&
        <ReferenceField label="Client" reference="client" source="client_id" sortable={false}>
          <FunctionField render={formatClientName}/>
        </ReferenceField>
      }

      <DateField source="expires_at" label="Expires At"/>
      <DateField source="created_at" label="Created At"/>

      <Actions delete={deletePermission} view={viewPermission}/>
    </ListWithLayout>
  )
}
