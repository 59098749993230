import {
  TYPE_ASN_REQUEST,
  TYPE_INVOICE_REQUEST, TYPE_ORDER_CONFIRMATION_REQUEST,
  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_SETUP_REQUEST
} from "../../app/etc/consts";

import {
  ENV_PRODUCTION,
  ENV_STAGING,
  ENV_TEST,
  ENV_UAT,
  ENV_DEV,
  ENV_OTHER
} from "../core/consts";

export const DATA_FORMAT_CXML = 'cXML';
export const DATA_FORMAT_OCI  = 'OCI';
export const DATA_FORMAT_XML  = 'XML';
export const DATA_FORMAT_JSON = 'JSON';

export const AUTH_CXML_USERNAME_FIELD_BUYER_ID = 'buyer_id';
export const AUTH_CXML_USERNAME_FIELD_SENDER_ID = 'sender_id';
export const AUTH_CXML_USERNAME_FIELD_BUYER_ID_LABEL = 'Buyer ID';
export const AUTH_CXML_USERNAME_FIELD_SENDER_ID_LABEL = 'Sender ID';
export const AUTH_ENCODING_BASE64 = 'base64';
export const AUTH_ENCODING_URLENCODED = 'urlencoded';
export const AUTH_ENCODING_BASE64_LABEL = 'Base64';
export const AUTH_ENCODING_URLENCODED_LABEL = 'URL Encoded';

export const CART_TOTALS_CODE_TAX = 'tax';
export const CART_TOTALS_CODE_SHIPPING = 'shipping';
export const CART_TOTALS_CODE_DISCOUNT = 'discount';
export const CART_TOTALS_CODE_HANDLING = 'handling';
export const CART_TOTALS_CODE_TAX_LABEL = 'Tax';
export const CART_TOTALS_CODE_SHIPPING_LABEL = 'Shipping';
export const CART_TOTALS_CODE_DISCOUNT_LABEL = 'Discount';
export const CART_TOTALS_CODE_HANDLING_LABEL = 'Handling';
export const CART_TOTALS_MODE_MAPPING = 'mapping';
export const CART_TOTALS_MODE_LINE = 'line';
export const CART_TOTALS_MODE_MAPPING_LABEL = 'Mapping';
export const CART_TOTALS_MODE_LINE_LABEL = 'Line';

export const PO_ITEMS_MODE_DEFAULT = 'default';
export const PO_ITEMS_MODE_CATALOG = 'catalog';
export const PO_ITEMS_MODE_ADHOC = 'adhoc';
export const PO_ITEMS_MODE_CATALOG_AND_ADHOC = 'catalog_and_adhoc';

export const INV_ITEMS_MODE_DEFAULT = 'default';
export const INV_ITEMS_MODE_PO = 'po';
export const INV_ITEMS_MODE_NON_PO = 'non_po';
export const INV_ITEMS_MODE_PO_AND_NON_PO = 'po_and_non_po';
//
export const CONNECTION_CHOOSER_TARGET_DEFAULT  = 'default';
export const CONNECTION_CHOOSER_TARGET_NEW = 'new';

export const AVAILABLE_CONNECTION_FORMATS = [
  DATA_FORMAT_CXML,
  DATA_FORMAT_OCI,
  DATA_FORMAT_XML,
  DATA_FORMAT_JSON,
];

export const AVAILABLE_CONNECTION_TYPES = [
  TYPE_SETUP_REQUEST,
  TYPE_ORDER_RECEIPT_REQUEST,
  TYPE_INVOICE_REQUEST,
  TYPE_ASN_REQUEST,
  TYPE_ORDER_CONFIRMATION_REQUEST,
];

export const AVAILABLE_CONNECTION_FORMATS_PER_TYPE = {
  [TYPE_SETUP_REQUEST]:         [DATA_FORMAT_CXML, DATA_FORMAT_OCI],
  [TYPE_ORDER_RECEIPT_REQUEST]: [DATA_FORMAT_CXML],
  [TYPE_INVOICE_REQUEST]:       [DATA_FORMAT_XML, DATA_FORMAT_JSON],
  [TYPE_ASN_REQUEST]:           [DATA_FORMAT_XML, DATA_FORMAT_JSON],
  [TYPE_ORDER_CONFIRMATION_REQUEST]:           [DATA_FORMAT_XML, DATA_FORMAT_JSON],
};

export const AVAILABLE_CHOOSER_TARGETS = [
  {id: CONNECTION_CHOOSER_TARGET_DEFAULT, name: 'Default'},
  {id: CONNECTION_CHOOSER_TARGET_NEW,     name: 'New Window'}
];

export const AVAILABLE_CONNECTION_AUTH_CXML_USERNAME_FIELDS = [
  {id: AUTH_CXML_USERNAME_FIELD_BUYER_ID, name: AUTH_CXML_USERNAME_FIELD_BUYER_ID_LABEL},
  {id: AUTH_CXML_USERNAME_FIELD_SENDER_ID, name: AUTH_CXML_USERNAME_FIELD_SENDER_ID_LABEL},
];

export const AVAILABLE_CONNECTION_ENCODING_TYPES = [
  {id: AUTH_ENCODING_BASE64, name: AUTH_ENCODING_BASE64_LABEL},
  {id: AUTH_ENCODING_URLENCODED, name: AUTH_ENCODING_URLENCODED_LABEL},
];

export const AVAILABLE_CONNECTION_CART_TOTALS_CODES = [
  {id: CART_TOTALS_CODE_TAX, name: CART_TOTALS_CODE_TAX_LABEL},
  {id: CART_TOTALS_CODE_SHIPPING, name: CART_TOTALS_CODE_SHIPPING_LABEL},
  {id: CART_TOTALS_CODE_DISCOUNT, name: CART_TOTALS_CODE_DISCOUNT_LABEL},
  {id: CART_TOTALS_CODE_HANDLING, name: CART_TOTALS_CODE_HANDLING_LABEL},
];

export const AVAILABLE_CONNECTION_CART_TOTALS_MODES = [
  {id: CART_TOTALS_MODE_MAPPING, name: CART_TOTALS_MODE_MAPPING_LABEL},
  {id: CART_TOTALS_MODE_LINE, name: CART_TOTALS_MODE_LINE_LABEL},
];


export const AVAILABLE_PO_ITEMS_MODES = [
  PO_ITEMS_MODE_DEFAULT,
  PO_ITEMS_MODE_CATALOG,
  PO_ITEMS_MODE_ADHOC,
  PO_ITEMS_MODE_CATALOG_AND_ADHOC,
];

export const AVAILABLE_BASE_ITEMS_MODES = [
  // INV_ITEMS_MODE_DEFAULT,
  INV_ITEMS_MODE_PO,
  // INV_ITEMS_MODE_NON_PO,
  // INV_ITEMS_MODE_PO_AND_NON_PO,
];

export const AVAILABLE_CONNECTION_ENVS = [
  ENV_PRODUCTION,
  ENV_STAGING,
  ENV_TEST,
  ENV_UAT,
  ENV_DEV,
  ENV_OTHER,
];
