import React from 'react';
import {Button, useDataProvider, useNotify, useRedirect, useRecordContext, useResourceContext} from 'react-admin';
import {useTranslate} from 'ra-core';
import SaveIcon from '@mui/icons-material/Save';
import config from '../../../../../app/etc/config';

const Index = ({...props}) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  // const record = props.data.record;
  // const resource = props.data.resource;
  const validate = props.validate;
  const transform = props.transform;

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const save = () => {
    let formEl = document.forms.viewForm;
    let formData = new FormData(formEl);
    let data = {};

    for (let pair of formData.entries()) {
      //connection platform needs to be handled slightly different
      if (resource=='connection' && pair[0].includes('platform')) {
        let keys = pair[0].split('.');
        if (!Object.prototype.hasOwnProperty.call(data, keys[0])) {
          data[keys[0]] = {};
        }
        data[keys[0]][keys[1]] = pair[1];
      } else {
        data[pair[0]] = pair[1];
      }
    }

    //transform form data
    if (typeof transform === 'object') {
      data = transform.validate(data);
    } else if (typeof transform === 'function') {
      data = transform(data);
    }

    //handle error for pages without validator
    let errors = [];
    if (typeof validate === 'object') {
      errors = validate.validate(data);
    } else if (typeof validate === 'function') {
      errors = validate(data);
    }

    if (Object.keys(errors).length>0) {
      let errorMessage = '';
      for(let field of Object.keys(errors)) {
        errorMessage += errors[field] + '\n';
      }
      notify(errorMessage,'error');
    } else {
      if (record && Object.prototype.hasOwnProperty.call(record, 'id')) {
        return (
          dataProvider
            .update(resource, {id: record.id, data: data})
            .then(() => {
            // success side effects go here
              redirect('/' + config.api.listing[resource]);
              notify(translate('app.' + resource + '.label') + ' updated');
            })
            .catch(error => {
            // failure side effects go here
              notify(translate('app.' + resource + '.label') + ` update error: ${error.message}`, 'warning');
            }));
      } else {
        return (
          dataProvider
            .create(resource, {data: data})
            .then(() => {
            // success side effects go here
              redirect('/' + config.api.listing[resource]);
              notify(translate('app.' + resource + '.label') + ' created');
            })
            .catch(error => {
            // failure side effects go here
              notify(translate('app.' + resource + '.label') + ` creation error: ${error.message}`, 'warning');
            }));
      }
    }
  };

  return <Button
    color="secondary"
    label="Save"
    onClick={save}
    size="medium"
    startIcon={<SaveIcon/>}
    variant="contained"
  />;
};

export default Index;
