import config from '../app/etc/config';
import session from '../app/session';

export default {
  // called when the user attempts to log in
  login: async (params) => {
    const {email, password} = params;
    const request = new Request(config.api.url + '/authenticate', {
      method: 'POST',
      body: JSON.stringify({email, password}),
      headers: new Headers({'Content-Type': 'application/json'}),
    });

    return await fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          let error = response.text();
          return error.then(function(result) {
            let error = JSON.parse(result);

            throw new Error( error.message );
          })

        }
        return response.json();
      })
      // https://medium.com/@siddharthac6/json-web-token-jwt-the-right-way-of-implementing-with-node-js-65b8915d550e
      .then((resp) => {
        session.login(resp);
      })
      .catch((err) => {
        return err;
      })
  },
  // called when the user clicks on the logout button
  logout: () => {
    session.logout();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      // session.logout();//@todo: uncomment
      // return Promise.reject();//@todo: uncomment
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (session.isLogged()) {
      return Promise.resolve();
    } else {
      session.logout();
      return Promise.reject();
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    if (session.isLogged()) {
      return Promise.resolve(session.getData().role);
    } else {
      //return Promise.reject();
      return Promise.resolve('anonymous');
    }
  },
};
