import React from 'react';
import {required, TextInput, PasswordInput} from 'react-admin';

export default () => {
  return (
    <>
      <TextInput
        source="authorization.apikey.key"
        label="app.general.labels.key"
        validate={[required()]}
      />
      <PasswordInput
        source="authorization.apikey.value"
        label="app.general.labels.value"
        validate={[required()]}
      />
    </>
  );
};
