import React, { useState } from 'react';
import {Form, Field} from 'react-final-form'
import {Link} from 'react-router-dom';

import {Button, Card, CardActions}  from '@mui/material';
import {Notification, useRedirect, useDataProvider} from 'react-admin';
import {useTranslate, useNotify} from 'ra-core';
import {TextField, CaptchaField, PublicFormIcon} from "../Form/Fields";
import {PublicStyles} from '../../../theme/skin/poc/styles';
import config from '../../../app/etc/config';

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(!config.google.recaptcha.enabled);

  const translate = useTranslate();
  const classes = PublicStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onSubmit = () => {
    if (!verified) {
      notify(translate('app.auth.captcha_error'));
      return;
    }

    setLoading(true);

    //get form data
    let formData = new FormData(document.forms.ResetPasswordForm);

    //call dataProvider
    dataProvider.create(config.routes.USER_PASSWORD_RESET, {
      data : {
        email: formData.get('email')
      }
    })
      .then(response => {
        setLoading(false);
        notify(response.data.message);
        redirect('forgotpasswordconfirm')
      })
      .catch(error => {
        setLoading(false);
        notify(error.message, 'error');
      });
  }

  const validateResetPassword = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = translate('ra.validation.required');
    }
    return errors;
  };

  return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            autoComplete="off"
            id="ResetPasswordForm"
            noValidate
            onSubmit={handleSubmit}
          >

          <div className={classes.main}>
            <Card className={classes.card}>

              <div className={classes.avatar}>
                <PublicFormIcon />
              </div>

              <div className={classes.form}>
                <div>
                  <h2>{translate('app.auth.reset_password_request_title')}</h2>
                </div>

                <Field autoFocus component={TextField} disabled={loading} label={translate('app.auth.email')} name="email" />

                <CaptchaField verified={verified} setVerified={setVerified}/>
              </div>

              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  disabled={loading}
                  fullWidth
                  label={translate('app.auth.send_confirmation_code')}
                  type="submit"
                  variant="contained"
                >{translate('app.auth.send_confirmation_code')}</Button>
              </CardActions>
              <CardActions className={classes.actions}>
                <Link
                  className={classes.link}
                  disabled={loading}
                  to="login"
                >{translate('app.auth.back_to_login')}</Link>
              </CardActions>
            </Card>
            <Notification />
          </div>
          </form>
        )}
        validate={validateResetPassword}
      />
  );
};

export default Index;
