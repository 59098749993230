import React, {useState} from 'react';
import {ShowButton, EditButton, FunctionField} from 'react-admin';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteButton from '../../Button/DeleteButton';
import {GridStyles} from '../../../../../theme/skin/poc/styles';

export default (props) => {
  return (<FunctionField render={record => GridRowActionsRenderer(record, props) } />);
}

export function GridRowActionsRenderer(record, props) {
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const classes = GridStyles();

  let conditions = {
    view:   !!props.view ? props.view : false,
    edit:   !!props.edit ? props.edit : false,
    delete: !!props.delete ? props.delete : false,
  };

  Object.keys(conditions).map(k => {
    if (typeof conditions[k] === 'function') {
      conditions[k] = conditions[k](record);
    }
  });

  let options = [];
  if (props.customOptionsBefore && typeof props.customOptionsBefore === 'function') {
    const customOptionsBefore = props.customOptionsBefore(record);
    if (!!customOptionsBefore) {
      options.push(customOptionsBefore);
    }
  }

  if (conditions.view) {
    options.push(<ShowButton {...props} className={classes.actionItem}/>);
  }
  if (conditions.edit) {
    options.push(<EditButton {...props} className={classes.actionItem}/>);
  }
  if (conditions.delete) {
    options.push(<DeleteButton {...props} record={record}  className={classes.actionItem}/>);
  }

  if (props.customOptionsAfter && typeof props.customOptionsAfter === 'function') {
    const customOptionsAfter = props.customOptionsAfter(props);
    if (!!customOptionsAfter) {
      options.push(customOptionsAfter);
    }
  }

  if (!options.length) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleClose}>{option}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};
