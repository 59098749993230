import React, { useState } from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer
} from 'react-admin';
import { useTranslate } from 'ra-core';

import {ViewStyles} from '../../theme/skin/poc/styles';
import Actions from '../../components/poc/Actions';
import Toolbar from '../../components/poc/Toolbar';
import {ClientField} from '../../components/poc/Form/Fields';

import {
  AVAILABLE_PROFILE_TYPES,
  AVAILABLE_PROFILE_FORMATS_PER_TYPE,
  AVAILABLE_PROFILE_CHANNELS,
  AVAILABLE_VERSIONS
} from './consts';

import {AVAILABLE_BASIC_STATUSES} from '../../app/etc/consts';

import {duplicatePermission, deletePermission, editPermission} from './permissions';
import session from '../../app/session';
import {toOptions} from '../../lib/source';

//import { XpathBuilder } from './xpath_builder';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.client_id && session.hasAdminPermission()) {
    errors.client_id = ['Client is required'];
  }
  if (!values.type) {
    errors.type = ['Type is required'];
  }
  if (!values.format) {
    errors.format = ['Format is required'];
  }
  if (!values.mapping) {
    errors.mapping = ['Mapping is required'];
  }
  return errors
};

export const ProfileCreate = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Create {...props} className={classes.root} successMessage={translate('app.profile.created')}>
      {ProfileForm(props, 'create')}
    </Create>
  )
};

export const ProfileEdit = (props) => {
  const translate = useTranslate();
  const classes = ViewStyles();

  return (
    <Edit {...props} className={classes.root} successMessage={translate('app.profile.updated')}>
      {ProfileForm(props, 'edit')}
    </Edit>
  )
};

export const ProfileForm = (props, mode = 'create') => {
  //const [editMode, setEditMode] = useState(false);
  //const [flags, setFlags] = useState(false);
  const translate = useTranslate();
  const classes = ViewStyles();

  //const toggleEditMapping = () => setEditMode(!editMode);
  //const EditMappingTitle = editPermission(record) ? 'Edit Mapping' : 'View Mapping';

  const ex = {
    mode:      mode,
    props:     props,
    classes:   classes,
    translate: translate,
  };

  return (
  <SimpleForm
    id="viewForm"
    toolbar={<Toolbar validate={validate} deleteConditions={mode === 'edit' ? deletePermission : false} editConditions={editPermission}/>}
  >
    <Actions
      className={classes.actions}
      listUrl="/profiles"
      title={translate('app.profile.' + mode)}
      deleteConditions={mode === 'edit' ? deletePermission : false}
      duplicateConditions={mode === 'edit' ? duplicatePermission : false}
    />

    <FormDataConsumer>
      {({formData}) =>
        <>

          <TextInput source="name" required/>

          {mode !== 'create' &&
          <TextInput source="code" disabled={true}/>
          }

          <TextInput source="description" multiline />

          {session.hasAdminPermission() &&
          <ClientField clientId={formData.client_id} ex={ex} />
          }

          <SelectInput source="type"       choices={toOptions(AVAILABLE_PROFILE_TYPES, 'app.general.labels.')}   required/>
          <SelectInput source="version"    choices={toOptions(AVAILABLE_VERSIONS)}   required helperText={translate('app.profile.labels.version_hint')}/>

          {formData.type &&
          <SelectInput source="format" choices={toOptions(AVAILABLE_PROFILE_FORMATS_PER_TYPE[formData.type])} required/>
          }
          {!formData.type &&
          <TextInput helperText="app.profile.labels.select_type_hint" label="Format" source="format" disabled={true} required/>
          }

          <SelectInput source="status"  choices={AVAILABLE_BASIC_STATUSES}   required/>
          <SelectInput source="channel" choices={toOptions(AVAILABLE_PROFILE_CHANNELS, 'app.general.labels.')} required />

          {session.hasAdminPermission() &&
          <BooleanInput parse={v => !!v ? 1 : 0} source="is_shared"/>
          }

          <TextInput source="mapping" key="mapping" multiline name="mapping" fullWidth />
          <TextInput source="draft" key="draft" multiline name="draft" fullWidth />

          {
           // <Link onClick={toggleEditMapping} className={classes.link} to="#">{EditMappingTitle}</Link>
            //editMode &&
          //<FormDataConsumer>
            //{formDataProps => (<XpathBuilder{...formDataProps} flags={flags} setFlags={setFlags}/>)}
          //</FormDataConsumer>
          }
        </>
      }
    </FormDataConsumer>
  </SimpleForm>
  )
};
