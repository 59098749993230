import React from 'react';
import PropTypes from 'prop-types';
import {Notification, useDataProvider, useLogout, Form} from 'react-admin';
import {useTranslate, useNotify} from 'ra-core';
//import {Form} from 'react-final-form'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {ViewStyles} from '../../theme/skin/poc/styles';
import session from '../../app/session'
import Actions from '../../components/poc/Actions';
import {FormSectionCommon, FormSectionPassword} from "./view";

const UserProfile = () => {
  const logout = useLogout();
  //const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = ViewStyles();

  if (!session.hasClientUserPermission()) {
    logout();
    return null;
  }

  const onSubmit = (props) => {
    dataProvider
      .update('user', {data: props})
      .then(response => {
        session.setData(response.data);
        notify(translate('app.user.labels.profile_updated'));
      })
      .catch(error => {
        // failure side effects go here
        const msg = !!error.message ? error.message : 'An Error Happened';
        notify(`${msg}`, 'warning');
      });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.firstname) {
      errors.firstname = ['The First Name is required'];
    }
    if (!values.lastname) {
      errors.lastname = ['The Last Name is required'];
    }
    if (!values.email) {
      errors.email = ['The Email is required'];
    }

    if ((!!values.password || !!values.password_confirmation) && (values.password !== values.password_confirmation)) {
      errors.password = ['The Password Confirmation has to match the Password'];
    }

    return errors
  };

  const {firstname, lastname, email} = session.getData();
  const user = {
    firstname: firstname, lastname: lastname, email: email
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        defaultValues={user}
      >
        <Card>
          <CardContent>
            <Actions className={classes.actions} title={translate('app.user.labels.profile')} />
            <FormSectionCommon disabled={false} classes={classes} />
            <FormSectionPassword disabled={false} classes={classes} />
          </CardContent>

          <CardActions>
            <Button color="primary" type="submit" variant="contained">Save</Button>
          </CardActions>
        </Card>

        <Notification/>
      </Form>
    </div>
  );
};

UserProfile.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default UserProfile;
