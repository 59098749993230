import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box
} from '@mui/material';

import {useTranslate} from "ra-core";

import { makeStyles } from '@mui/styles';
import styles from "../styles";
import {Link} from "react-router-dom";
const useStyles = makeStyles(styles);

const clickable = [
  'connection',
  'transaction',
  'profile',
  'endpoint',
  'buyer',
];

const LineField = (props) => {
  const {relationId, relationType} = props;
  const classes = useStyles();
  const translate = useTranslate();

  let relationVal = relationId;
  if (clickable.indexOf(relationType) !== -1) {
    if (relationType === 'transaction') {
      relationVal = <Link to={{pathname: `/${relationType}/${relationId}/show`}}>{relationId}</Link>
    } else {
      relationVal = <Link to={{pathname: `/${relationType}/${relationId}`}}>{relationId}</Link>
    }
  }

  return (
    <TableRow>
      <TableCell className={classes.relationCell} width={'25%'}>{translate('app.document.labels.relation.' + relationType)}: {relationVal}</TableCell>
      <TableCell className={classes.relationCell} ></TableCell>
    </TableRow>
  );
};

const Relations = (props) => {
  const translate = useTranslate();

  const {record} = props;
  if (!record.relations || !record.relations.length) {
    return 'There is no one relation item';
  }

  return (
    <Box>
    <>
      <Typography variant="h5">
        {translate('app.document.labels.relations')}
      </Typography>
      <Table>
        <TableBody>
          {record.relations.map((relation, key) => <LineField relationId={relation.id} relationType={relation.type} key={relation.id} />)}
        </TableBody>
      </Table>
    </>
    </Box>
  );
};

export default Relations;
