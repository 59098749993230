import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslate} from "ra-core";
import {DownloadButton} from './downloads';

const Header = (props) => {

  const translate = useTranslate();
  const {record} = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="h4" gutterBottom>{translate('app.document.labels.type.' + record.type)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h5" gutterBottom>{record.number}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h5" gutterBottom align="right">{record.status} </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h5" gutterBottom align="right">
          <span>{new Date(record.created_at).toLocaleDateString()}</span>
        </Typography>

        <Typography variant="h6" gutterBottom align="right">
          <DownloadButton {...props} />
        </Typography>
      </Grid>
    </Grid>
  );
};

/**
 {record.billing_addresses && <CustomersSection name="billing_addresses" customer={record.billing_addresses}/>}
 {record.shipping_addresses && <CustomersSection name="shipping_addresses" customer={record.shipping_addresses}/>}
 {record.custom_addresses && <CustomersSection name="custom_addresses" customer={record.custom_addresses}/>}
 {record.customer && <CustomersSection name="customer" customer={record.customer}/>}
 */
export default Header;
