// -------------------- SOURCES AVAILABLE ------------------ //
export const SOURCE_STANDARD          = 'standard';
export const SOURCE_SHOPIFY_CLOUD     = 'shopifycloud';
export const SOURCE_BIGCOMMERCE_CLOUD = 'bigcommercecloud';
export const SOURCE_REST_API          = 'restapi';
export const SOURCE_OTHER             = 'other';
export const SOURCE_EMAIL             = 'email';
//export const SOURCE_MAGENTO2 = 'magento2';
//export const SOURCE_MAGENTO = 'magento';

export const SOURCES = [
  SOURCE_STANDARD,
  SOURCE_SHOPIFY_CLOUD,
  SOURCE_BIGCOMMERCE_CLOUD,
  SOURCE_REST_API,
  SOURCE_OTHER,
  SOURCE_EMAIL,
];

// -------------------- AUTHORIZATION METHODS ------------------ //
export const AUTHORIZATION_METHOD_DISABLED  = 'disabled';
export const AUTHORIZATION_METHOD_BASIC     = 'basic';
export const AUTHORIZATION_METHOD_API_KEY   = 'apikey';
export const AUTHORIZATION_METHOD_CONNECTOR = 'connector';
export const AUTHORIZATION_METHOD_OUTBOUND_TOKEN = 'outbound-token';

export const AUTHORIZATION_METHODS = [
  AUTHORIZATION_METHOD_DISABLED,
  AUTHORIZATION_METHOD_CONNECTOR,
  AUTHORIZATION_METHOD_API_KEY,
  AUTHORIZATION_METHOD_BASIC,
  AUTHORIZATION_METHOD_OUTBOUND_TOKEN,
];

// ---------------- REQUEST REGISTRY -------------- //
export const CUSTOM_SOURCES = [
  SOURCE_STANDARD,
  SOURCE_REST_API,
  SOURCE_OTHER,
];

export const SIMPLE_SOURCES = [
  SOURCE_EMAIL,
];

// ---------------- AUTHORIZATION REGISTRY -------------- //
export const AUTHORIZATION_METHODS_REGISTRY = {};

AUTHORIZATION_METHODS_REGISTRY[SOURCE_STANDARD] = [
  AUTHORIZATION_METHOD_DISABLED,
  AUTHORIZATION_METHOD_OUTBOUND_TOKEN,
];

AUTHORIZATION_METHODS_REGISTRY[SOURCE_REST_API] = [
  AUTHORIZATION_METHOD_DISABLED,
  AUTHORIZATION_METHOD_API_KEY,
  AUTHORIZATION_METHOD_BASIC,
];

AUTHORIZATION_METHODS_REGISTRY[SOURCE_OTHER] = [
  AUTHORIZATION_METHOD_DISABLED,
];

AUTHORIZATION_METHODS_REGISTRY[SOURCE_SHOPIFY_CLOUD] = [
  AUTHORIZATION_METHOD_CONNECTOR,
];

AUTHORIZATION_METHODS_REGISTRY[SOURCE_BIGCOMMERCE_CLOUD] = [
  AUTHORIZATION_METHOD_CONNECTOR,
];

// ---------------- HTTP REGISTRY -------------- //
export const HTTP_METHOD_POST   = 'post';
export const HTTP_METHOD_GET    = 'get';
export const HTTP_METHOD_PUT    = 'put';
export const HTTP_METHOD_DELETE = 'delete';
export const METHOD_EMAIL_SMTP  = 'smtp';

export const AVAILABLE_METHODS = [
  HTTP_METHOD_POST,
  HTTP_METHOD_GET,
  HTTP_METHOD_PUT,
  HTTP_METHOD_DELETE,
  METHOD_EMAIL_SMTP
];

export const HTTP_METHODS = AVAILABLE_METHODS;//@todo: get rid later
