import React from 'react';
import {
  TextInput,
  SelectInput,
  DateInput,
  TextField,
  downloadCSV,
  FunctionField
} from 'react-admin';

import * as jsonExport from "jsonexport/dist"
import CardContent from "@mui/material/CardContent";
import { makeStyles } from '@mui/styles';

import ListFilter from '../../../components/poc/ListWithLayout/components/ListFilter';
import ListWithLayout from '../../../components/poc/ListWithLayout'
import {StatusField} from '../../../components/poc/StatusField';

import {AVAILABLE_CLIENT_STATUSES, AVAILABLE_CLIENT_TYPES} from './../../client/consts';
import {MODE_AMOUNTS, MODE_TRANSACTIONS} from "../consts";
import {TYPE_TRANSFERRED_CART, TYPE_PURCHASE_ORDER, TYPE_INVOICE, TYPE_ASN} from "../../document/consts";

import {ClientBrief} from "./client-brief";
import config from "../../../app/etc/config";
import {Typography} from '@mui/material';

const ReportClientFilter = (props) => (
  <ListFilter {...props}>
    <TextInput source="id:in" label="ID"/>
    <SelectInput choices={AVAILABLE_CLIENT_STATUSES} source="status"/>
    <SelectInput choices={AVAILABLE_CLIENT_TYPES} source="type"/>
    <TextInput source="code"/>
    <TextInput source="name:like" label="Name"/>
    <TextInput source="email"/>
    <DateInput source="created_at"/>
    <DateInput source="updated_at"/>
  </ListFilter>
);

const ConnectionsFieldRender = ({ record }) => {
  let conn = (typeof record['statistic'] !== 'undefined' && typeof record['statistic']['connections_number'] !== 'undefined')
    ? record['statistic']['connections_number']
    : {
      estimated_usage_internal: 0,
      estimated_usage_external: 0
    };

  return (parseInt(conn.estimated_usage_internal) + parseInt(conn.estimated_usage_external));
};

export const ConnectionsField = (props) => {
  return (<FunctionField render={record => ConnectionsFieldRender({...props, record})}/>)
};

const StatisticSumFieldRender = ({ record, mode, documentType }) => {
  let parseNumber = (mode === MODE_AMOUNTS) ? parseFloat : parseInt;
  let documentsKey = mode === MODE_AMOUNTS ? 'documents_amount' : 'documents_number';
  let transactionsKey = mode === MODE_AMOUNTS ? 'transactions_amount' : 'transactions_number';

  let res = 0;
  if (typeof record['statistic'] !== 'undefined') {
    let statistic = record['statistic'];
    res = parseNumber(statistic[documentsKey][documentType]) + parseNumber(statistic[transactionsKey][documentType]);
  }

  if (mode === MODE_AMOUNTS) {
    return (res).toFixed(2) + '$';
  }

  return res;
}

export const StatisticSumField = (props) => {
  return (<FunctionField render={record => StatisticSumFieldRender({...props, record})}/>)
};

const exporter = records => {
  const recordsForExport = records.map(record => {
    return {
      'ID':     record.id,
      'Name':   record.name,
      'Status': record.status,
      'Type':   record.type,
      'Code':   record.code,
      'Platform': !!record.platform ? record.platform.source : config.platform.default,
      'Estimated Connections Usage': ConnectionsField({record: record}),

      '$TC': StatisticSumField({record: record, mode: MODE_AMOUNTS,  documentType: TYPE_TRANSFERRED_CART}),
      '$PO': StatisticSumField({record: record, mode: MODE_AMOUNTS,  documentType: TYPE_PURCHASE_ORDER}),
      '$INV': StatisticSumField({record: record, mode: MODE_AMOUNTS,  documentType: TYPE_INVOICE}),
      '$ASN': StatisticSumField({record: record, mode: MODE_AMOUNTS,  documentType: TYPE_ASN}),

      '#TC': StatisticSumField( {record: record, mode: MODE_TRANSACTIONS,  documentType: TYPE_TRANSFERRED_CART}),
      '#PO': StatisticSumField( {record: record, mode: MODE_TRANSACTIONS,  documentType: TYPE_PURCHASE_ORDER}),
      '#INV': StatisticSumField({record: record, mode: MODE_TRANSACTIONS,  documentType: TYPE_INVOICE}),
      '#ASN': StatisticSumField({record: record, mode: MODE_TRANSACTIONS,  documentType: TYPE_ASN}),
      '#ALL': StatisticSumField({record: record, mode: MODE_TRANSACTIONS,  documentType: 'all'}),
    };
  });

  jsonExport(recordsForExport, {
    headers: [
      'ID',
      'Name',
      'Status',
      'Type',
      'Code',
      'Platform',
      //
      'Estimated Connections Usage',
      '$TC', '$PO', '$INV', '$ASN',
      '#TC', '#PO', '#INV', '#ASN', '#ALL',
    ]
  }, (err, csv) => {
    downloadCSV(csv, 'report_clients'); // download as 'report_clients.csv` file
  });
};

export const HeaderContent = props => {
  const classes = useStyles();

  return (
      <CardContent className={classes.headerContent}>
        <Typography variant="h4">Report is based on the last Year Statistic.</Typography>
        <div className={classes.headerContentHint}><strong>$TC, $PO, $INV, $ASN: </strong><span>Estimated summary of all item amounts per document.</span></div>
        <div className={classes.headerContentHint}><strong>#TC, #PO, #INV, #ASN: </strong><span>Estimated number of transactions per document.</span></div>
      </CardContent>
  );
};

export const ReportClientGrid = props => {
  return (
    <ListWithLayout
      {...props}
      createButton={false}
      filters={<ReportClientFilter/>}
      resource="report_clients"
      perPage={config.pagination.grid}
      sort={{field: 'created_at', order: 'DESC'}}
      exporter={exporter}
      headerContent={<HeaderContent />}
      rowClick="expand"
      expand={<ClientBrief />}
    >
      <TextField    source="type"/>
      <StatusField  source="status"/>
      <TextField    source="name"/>

      <TextField source="platform.source" label="Platform" sortable={false} emptyText={config.platform.default}/>

      <ConnectionsField label="Estimated Connections Usage" sortable={false} />

      <StatisticSumField label="$TC"  sortable={false} mode={MODE_AMOUNTS} documentType={TYPE_TRANSFERRED_CART}    />
      <StatisticSumField label="$PO"  sortable={false} mode={MODE_AMOUNTS} documentType={TYPE_PURCHASE_ORDER}      />
      <StatisticSumField label="$INV" sortable={false} mode={MODE_AMOUNTS} documentType={TYPE_INVOICE}             />
      <StatisticSumField label="$ASN" sortable={false} mode={MODE_AMOUNTS} documentType={TYPE_ASN} />

      <StatisticSumField label="#TC"  sortable={false} mode={MODE_TRANSACTIONS} documentType={TYPE_TRANSFERRED_CART}    />
      <StatisticSumField label="#PO"  sortable={false} mode={MODE_TRANSACTIONS} documentType={TYPE_PURCHASE_ORDER}      />
      <StatisticSumField label="#INV" sortable={false} mode={MODE_TRANSACTIONS} documentType={TYPE_INVOICE}             />
      <StatisticSumField label="#ASN" sortable={false} mode={MODE_TRANSACTIONS} documentType={TYPE_ASN} />

      <StatisticSumField label="#ALL" sortable={false} mode={MODE_TRANSACTIONS} documentType="all" />
    </ListWithLayout>
  )
};

const useStyles = makeStyles({
  headerContent: {
    textAlign: 'left',
    color: 'gray',
    paddingBottom: 0,
    paddingTop: 0,
  },
  headerContentHint: {
    fontStyle: 'italic',
  }
});
