import React from 'react';
import PropTypes from 'prop-types';

import session from "../../../../app/session";
import {TopBarStyles} from "../../../../theme/skin/poc/styles";

const SessionSection = () => {
  const classes = TopBarStyles();
  const sessionLabel = session.isActingOnBehalfOf() ? 'Acting On Behalf Of: ' : 'Logged as:';

  return (
    <span className={classes.topBarSwitcherSession}>
      <span>
        <span>{sessionLabel} </span>
        <span className={classes.topBarSwitcherSessionUser}>{session.getUserEmail()}</span>
        <span> </span>
        <span className={classes.topBarSwitcherSessionRole}>{session.getRoleName()}</span>
        <span> of </span>
        <span className={classes.topBarSwitcherSessionClient}>{session.getClientName()}</span>
      </span>
    </span>
  );
};

const Session = props => {
  const classes = TopBarStyles();

  return (
    <div className={classes.topBarSwitcherContainer}>
      <SessionSection />
    </div>
  );
};

Session.propTypes = {
  className: PropTypes.string
};

export default Session;
