import React from "react";
import {
  AUTHORIZATION_METHOD_API_KEY,
  AUTHORIZATION_METHOD_BASIC,
  AUTHORIZATION_METHOD_CONNECTOR,
  AUTHORIZATION_METHOD_DISABLED,
  AUTHORIZATION_METHOD_OUTBOUND_TOKEN,
  AUTHORIZATION_METHODS_REGISTRY
} from "../../consts";

import MethodDisabledSection from "./method/disabled";
import MethodConnectorSection from "./method/connector";
import MethodBasicSection from "./method/basic";
import MethodApikeySection from "./method/api-key";
import MethodOutboundTokenSection from "./method/outbound-token";

export const skipHandling = (authorization, source) => {
  return (!authorization
    || !source
    || !authorization.method
    || typeof AUTHORIZATION_METHODS_REGISTRY[source] === 'undefined'
    || AUTHORIZATION_METHODS_REGISTRY[source].indexOf(authorization.method) === -1
  );
};

export default ({ex, formData}) => {
  const {authorization, source} = formData;

  if (skipHandling(authorization, source)) {
    return <></>;
  }

  switch (authorization.method) {
    case AUTHORIZATION_METHOD_DISABLED:
      return (
        <MethodDisabledSection ex={ex} formData={formData}/>
      );
    case AUTHORIZATION_METHOD_CONNECTOR:
      return (
        <MethodConnectorSection ex={ex} formData={formData}/>
      );
    case AUTHORIZATION_METHOD_BASIC:
      return (
        <MethodBasicSection ex={ex} formData={formData}/>
      );
    case AUTHORIZATION_METHOD_API_KEY:
      return (
        <MethodApikeySection ex={ex} formData={formData}/>
      );
    case AUTHORIZATION_METHOD_OUTBOUND_TOKEN:
      return (
        <MethodOutboundTokenSection ex={ex} formData={formData}/>
      );
    default:
      return (<></>);
  }
};
